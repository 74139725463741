import {
  GET_INS_TEAMS_LIST,
  GET_INS_TEAMS_LIST_SUCCESS,
  GET_INS_TEAMS_LIST_FAIL,

  GET_TEAMS_INS_LIST,
  GET_TEAMS_INS_LIST_SUCCESS,
  GET_TEAMS_INS_LIST_FAIL,

  TEAM_STAFF,
  TEAM_STAFF_SUCCESS,
  TEAM_STAFF_FAIL,

  ADD_TEAM,
  RENAME_TEAM,
  DELETS_TEAM,
  EDIT_TEAM,
  EDIT_TEAM_STATE,
  EDIT_TEAM_SUCCESS,
  EDIT_TEAM_FAIL,
  RESET_TEAM_SIMULATIONS,
  EPMTY_EDITING_DATA,
  MERGE_TEAM,

  TEAM_BYID,
  TEAM_BYID_SUCCESS,
  TEAM_BYID_FAIL,

  MESH_TYPE,
  JOBS_UPLOAD_SUCCESS,

  UPDATE_SENSOR_ID,
  UPDATE_PLAYER_STATUS,
  DELETE_PLAYERS,
  EDIT_PLAYER,
  EDIT_PLAYER_STATE,
  EDIT_PLAYER_SUCCESS,
  EDIT_PLAYER_FAIL,
  EPMTY_EDITING_PLAYERS_DATA,
  UPDATE_ALL_PLAYER_STATUS,

  GET_TEAM_STAT_SUMMARY,
  GET_TEAM_STAT_SUMMARY_SUCCESS,
  GET_TEAM_STAT_SUMMARY_FAIL,

  FETCH_TEAM_MEMBERS,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAIL,

  REMOVE_TEAM_MEMBERS,
  REMOVE_TEAM_MEMBERS_SUCC,
  REMOVE_TEAM_MEMBERS_FAIL,

  SET_TEAM_ANYLSIS_DATA,
  INSTANCE_TYPE,
  MESH_SUBTYPE,
  EXCLUDE_SIMULATIONS,

  SET_TEAM_MPS_DATA,

  GET_ALL_SIMULATIONS_MPS_SUCC,

  GET_TEAM_SIMULATIONS,
  GET_TEAM_SIMULATIONS_SUCCESS,
  GET_TEAM_SIMULATIONS_FAIL,
  GET_SIMS_LOG,
  SIMS_LOG_SUCCESS,
  SIMS_LOG_FAIL,


  GET_TEAMS_LIST,
  GET_TEAMS_LIST_SUCCESS,
  GET_TEAMS_LIST_FAIL,
  CHANGE_ADMIN_TO_PLAYER,
  DELETE_UNDEFINED_MEM_FAIL,
  DELETE_UNDEFINED_MEM_STATE,
  DELETE_UNDEFINED_MEM_LOADER,
  DELETE_TEAM_STAFF_STATE,
  DELETE_TEAM_STAFF_FAIL,
  DELETE_TEAM_STAFF_LOADER,
  DELETE_TEAM_STAFF_AFTER_SUCC,
  ML_TRAINING_RESULT_GET,
  ML_TRAINING_RESULT,
  ML_TRAINING_RESULT_FAIL,
  
  GET_TEAM_BRAIN_IMAGE,
  GET_TEAM_BRAIN_IMAGE_SUCCESS,
  GET_TEAM_BRAIN_IMAGE_FAIL,
  GET_ALL_SIMULATIONS_MPS,
  GET_ALL_SIMULATIONS_MPS_FAIL,

  //strain
  GET_ALL_PLAYER_ANALYSIS_DATA,
  GET_ALL_PLAYER_ANALYSIS_DATA_SUCCESS,
  GET_ALL_PLAYER_ANALYSIS_DATA_FAIL,
  // ml
  GET_ALL_PLAYER_ML_ANALYSIS_DATA,
  GET_ALL_PLAYER_ML_ANALYSIS_DATA_SUCCESS,
  GET_ALL_PLAYER_ML_ANALYSIS_DATA_FAIL,

  // pressure
  GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA,
  GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS,
  GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL,

  //TOP20
  FETCH_TEAM_TOP_30_DATA,
  FETCH_TEAM_TOP_30_DATA_SUCCESS,
  FETCH_TEAM_TOP_30_DATA_FAIL,

  // sensor devices
  GET_TEAM_SENSOR_DEVICES,
  GET_TEAM_SENSOR_DEVICES_REQUEST_SUCCESS,
  GET_TEAM_SENSOR_DEVICES_REQUEST_FAILED,

  UPDATE_LOADING_STATUS
} from "./actionTypes"

const INIT_STATE = {
  institutions: null,
  loading: false,
  error: {},
  teamSelectedGs: 0,
  teamSelectedRange: 'greater',
  teamSelectedMatrix: 'principal-max-strain',
  loadingTeam: false,
  insTeams: null,
  insTeamErr: null,
  insId: null,

  loadingTeamList: false,
  insTeamListErr: null,
  insTeamsList: null,

  loadingStaff: false,
  teamStaff: null,
  staffErr: null,

  addTeamName: null,
  renameTeamList: [],
  deleteTeamList: [],
  mergeTeamList: [],
  editingState: null,
  editingErr: null,
  editSuccess: null,

  teamById: false,
  teamByIdSucc: null,
  teamByIdErr: null,

  meshType: 'coarse',
  meshSubType: 'male',
  instanceType: 'production',

  excludeSimulations: true,

  jobUploadSucc: null,

  playerSensorIdList: [],
  playerStatusList: [],
  deletePlayerList: [],
  changeAdminToPlayerList:[],
  editPlayers: false,
  playersEditingState: null,
  playersEditErr: null,
  PlayerseditSuccess: null,

  getTeamStatsSummary: false,
  teamStatsSummary: null,
  playerData: null,
  getTeamStatsSummaryErr: null,

  getTeamMembers: false,
  teamMembers: null,
  getTeamMembersErr: null,

  removeTeamMembers: false,
  removeTeamMembersSucc: null,
  removeTeamMembersErr: null,

  teamAnylsisData: null,

  teamSimulations: null,
  teamSimulationError: null,
  teamSimulationLoading: false,
  fetchSimLogs: false,
  simsLogs: null,
  simsLogssErr: null,
  totalsimsLogs: null,

  getTeamList: false,
  TeamList: false,
  getTeamListErr: false,
  undefinedMemdeleteSuccess: null,
  undefinedMemdeleteErr: null,
  undefinedMemdeleteloader:false,
  teamstaffdeleteSuccess: null,
  teamstaffdeleteErr: null,
  teamstaffdeleteloader:false,
  teamstaffdeleteafterSucc:false,

  loadingMlresult: true,
  MLtrainingresult: null,
  loadingMlresultfail: false,

  getteamBrainImage: false,
  teamBrainImage: null,
  teamBrainImageErr: null,
  mpsSimulationsLoaded: false,
  mpsSimulations: null,
  mpsSimulationError: false,

  //TOP 20
  loadingTeamTop20MpsData: false,
  TeamTop20MpsData: null,
  TeamTop20MpsDataErr: false,

  //STARIN
  loadingOptimizeAllPlayerAnalysis: false,
  optimizeAllPlayerAnalysisData: null,
  optimizeAllPlayerAnalysisDataErr: false,

  //ML
  loadingOptimizeAllPlayerMlAnalysis: false,
  optimizeAllPlayerMlAnalysisData: null,
  optimizeAllPlayerMlAnalysisDataErr: false,

  //PRESSURE
  loadingOptimizeAllPlayerPressureAnalysis: false,
  optimizeAllPlayerPressureAnalysisData: null,
  optimizeAllPlayerPressureAnalysisDataErr: false,

  // SENSOR DEVICES
  loadingSensorDevices: false,
  sensorDevices: [],
  totalSensorDevices: 0,
  loadingSensorDevicesError: null
}

const checkExisitingVal = (newEvent, existValue) => {
  var newValue = [];
  // console.log('existValue ---', existValue, newEvent);
  for (var i = 0; i < existValue.length; i++) {
    if (existValue[i].user_id !== newEvent.user_id) newValue.push(existValue[i]);
  }
  newValue.push(newEvent)
  return newValue
}
const Institutions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TEAM_SENSOR_DEVICES:
      return {
        ...state,
        loadingSensorDevices: true,
        loadingSensorDevicesError: null,
        sensorDevices: [],
        totalSensorDevices: 0
      }
    case GET_TEAM_SENSOR_DEVICES_REQUEST_SUCCESS:
      return {
        ...state,
        loadingSensorDevices: false,
        loadingSensorDevicesError: null,
        sensorDevices: action.payload.items,
        totalSensorDevices: action.payload.total
      }
    case GET_TEAM_SENSOR_DEVICES_REQUEST_FAILED:
      return {
        ...state,
        loadingSensorDevices: false,
        loadingSensorDevicesError: action.payload,
        sensorDevices: [],
        totalSensorDevices: 0
      }
    // ml training results
    case ML_TRAINING_RESULT_GET:
      return {
        ...state,
        loadingMlresult: true,
        MLtrainingresult: null,
        loadingMlresultfail: false,
      }

    case ML_TRAINING_RESULT:
      return {
        ...state,
        loadingMlresult: false,
        MLtrainingresult: action.payload,
        loadingMlresultfail: false,
      }

    case ML_TRAINING_RESULT_FAIL:
      return {
        ...state,
        loadingMlresult: false,
        MLtrainingresult: null,
        loadingMlresultfail: action.payload,
      }
    //...
    /* case GET_ALL_SIMULATIONS_MPS_SUCC:
      {
        return {
          ...state,
          mpsSimulations: action.payload
        }
      } */
    // fetlch all teams ...
    //...
      case RESET_TEAM_SIMULATIONS:
        {
          return {
            ...state,
            teamSimulations: null,
            teamSimulationError: null,
            teamSimulationLoading: false
          }
        } 
      case GET_TEAM_SIMULATIONS:
        {
          return {
            ...state,
            teamSimulations: null,
            teamSimulationError: null,
            teamSimulationLoading: true
          }
        }  
      case GET_TEAM_SIMULATIONS_SUCCESS:
      {
        return {
          ...state,
          teamSimulations: action.payload,
          teamSimulationLoading: false,
          teamSimulationError: null
        }
      }
      case GET_TEAM_SIMULATIONS_FAIL:
      {
        return {
          ...state,
          teamSimulations: action.payload,
          teamSimulationLoading: false,
          teamSimulationError: null
        }
      }
    case GET_ALL_SIMULATIONS_MPS:
    {
      return {
        ...state,
        mpsSimulationsLoaded: false,
        mpsSimulations: null,
        mpsSimulationError: null
      }
    }
    case GET_ALL_SIMULATIONS_MPS_SUCC:
    {
      return {
        ...state,
        mpsSimulationsLoaded: true,
        mpsSimulations: action.payload,
        mpsSimulationError: null
      }
    }
    case GET_ALL_SIMULATIONS_MPS_FAIL:
    {
      return {
        ...state,
        mpsSimulationsLoaded: true,
        mpsSimulations: null,
        mpsSimulationError: action.payload
      }
    }
    // fetlch all teams ...
    case GET_TEAMS_LIST:
      return {
        ...state,
        getTeamList: true,
        TeamList: false,
        getTeamListErr: false,
      }

    case GET_TEAMS_LIST_SUCCESS:
      return {
        ...state,
        getTeamList: false,
        TeamList: action.payload,
        getTeamListErr: false,
      }

    case GET_TEAMS_LIST_FAIL:
      return {
        ...state,
        getTeamList: false,
        TeamList: false,
        getTeamListErr: action.payload,
      }
    //...
    // fetching simulation logs ...
    case GET_SIMS_LOG:
      return {
        ...state,
        fetchSimLogs: true,
        simsLogssErr: null,
      }

    case SIMS_LOG_SUCCESS:
      return {
        ...state,
        fetchSimLogs: false,
        simsLogs: action.payload.result,
        totalsimsLogs: action.payload.total,
        simsLogssErr: null,
      }

    case SIMS_LOG_FAIL:
      return {
        ...state,
        fetchSimLogs: false,
        simsLogs: null,
        totalsimsLogs: null,
        simsLogssErr: action.payload,
      }
    //...
    //Ins anylsis data
    case SET_TEAM_ANYLSIS_DATA:
      return {
        ...state,
        teamAnylsisData: action.payload
      }

    case SET_TEAM_MPS_DATA:
      return {
        ...state,
        teamMPSData: action.payload
      }
    // fetching staff...
    case REMOVE_TEAM_MEMBERS:
      return {
        ...state,
        removeTeamMembers: true,
        removeTeamMembersSucc: null,
        removeTeamMembersErr: null,
      }

    case REMOVE_TEAM_MEMBERS_SUCC:
      return {
        ...state,
        removeTeamMembers: false,
        removeTeamMembersSucc: action.payload,
        removeTeamMembersErr: null,
      }

    case REMOVE_TEAM_MEMBERS_FAIL:
      return {
        ...state,
        removeTeamMembers: false,
        removeTeamMembersSucc: null,
        removeTeamMembersErr: action.payload,
      }
    //...

    // fetching staff...
    case FETCH_TEAM_MEMBERS:
      return {
        ...state,
        getTeamMembers: true,
        teamMembers: null,
        getTeamMembersErr: null,
      }

    case FETCH_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        getTeamMembers: false,
        teamMembers: action.payload,
        getTeamMembersErr: null,
      }

    case FETCH_TEAM_MEMBERS_FAIL:
      return {
        ...state,
        getTeamMembers: false,
        teamMembers: null,
        getTeamMembersErr: action.payload,
      }
    //...
    //team stats
    case GET_TEAM_STAT_SUMMARY:
      return {
        ...state,
        getTeamStatsSummary: true,
        teamStatsSummary: null,
        playerData: null,
        getTeamStatsSummaryErr: null,
      }
    case GET_TEAM_STAT_SUMMARY_SUCCESS:
      return {
        ...state,
        getTeamStatsSummary: false,
        playerData: action.payload.playerProfile,
        teamStatsSummary: action.payload.statSummaryData,
        getTeamStatsSummaryErr: null,
      //   getTeamStatsSummary: false,
      //   //playerData: action.payload.playerProfile,
      //   playerData: summarydata.data.playerProfile,
      //  // teamStatsSummary: action.payload.statSummaryData,
      //   teamStatsSummary: summarydata.data.statSummaryData,
      //   getTeamStatsSummaryErr: null,
      }
    case GET_TEAM_STAT_SUMMARY_FAIL:
      return {
        ...state,
        getTeamStatsSummary: false,
        teamStatsSummary: null,
        playerData: null,
        getTeamStatsSummaryErr: action.payload,
      }
    //
    /* change admin to player*/
    
    case CHANGE_ADMIN_TO_PLAYER:
      // console.log('CHANGE_ADMIN_TO_PLAYER',action.payload)
      return {
       
        ...state,
        changeAdminToPlayerList: state.changeAdminToPlayerList.concat(action.payload)
      }
    /* end here*/
    case UPDATE_SENSOR_ID:
      return {
        ...state,
        playerSensorIdList: checkExisitingVal(action.payload, state.playerSensorIdList)
      }
    case UPDATE_PLAYER_STATUS:
      return {
        ...state,
        playerStatusList: state.playerStatusList.concat(action.payload)
      }
    case UPDATE_ALL_PLAYER_STATUS:
     
      return {
        ...state,
        playerStatusList: action.payload
      }
    case DELETE_PLAYERS:
      return {
        ...state,
        deletePlayerList: state.deletePlayerList.concat(action.payload)
      }
    // Edit players...
    case EDIT_PLAYER:
      return {
        ...state,
        editPlayers: true,
        playersEditingState: null,
        playersEditErr: null,
        PlayerseditSuccess: null,
      }

    case EDIT_PLAYER_STATE:
      return {
        ...state,
        playersEditingState: action.payload
      }
    case EDIT_PLAYER_SUCCESS:
      return {
        ...state,
        editPlayers: false,
        playersEditErr: null,
        playersEditingState: null,
        PlayerseditSuccess: action.payload,
      }
    case EDIT_PLAYER_FAIL:
      return {
        ...state,
        editPlayers: false,
        playersEditingState: null,
        playersEditErr: action.payload,
        PlayerseditSuccess: null,
      }
    case EPMTY_EDITING_PLAYERS_DATA:
      return {
        ...state,
        playerSensorIdList: [],
        playerStatusList: [],
        deletePlayerList: [],
        changeAdminToPlayerList:[],
        editPlayers: false,
        playersEditingState: null,
        playersEditErr: null,
        PlayerseditSuccess: null,
        removeTeamMembersSucc: null,
      }
    //...  
    // Mesh type ...
    case JOBS_UPLOAD_SUCCESS:
      return {
        ...state,
        jobUploadSucc: action.payload
      }
    // Mesh type ...
    case MESH_TYPE:
      return {
        ...state,
        meshType: action.payload
      }
    // INSTANCE_TYPE type ...
    case INSTANCE_TYPE:
      return {
        ...state,
        instanceType: action.payload
      }
    case MESH_SUBTYPE:
      return {
        ...state,
        meshSubType: action.payload
      }
    // EXCLUDE existing Simulation  ...
    case EXCLUDE_SIMULATIONS:
      // console.log('excludeSimulations reducer++++++++++++++++++++++', action.payload)
      return {
        ...state,
        excludeSimulations: action.payload === true ? false:true
       // excludeSimulations: action.payload
      }
    // crud operations ...
    case ADD_TEAM:
      return {
        ...state,
        addTeamName: action.payload
      }
    case RENAME_TEAM:
      return {
        ...state,
        renameTeamList: state.renameTeamList.concat(action.payload)
      }
    case DELETS_TEAM:
      return {
        ...state,
        deleteTeamList: state.deleteTeamList.concat(action.payload)
      }
    case MERGE_TEAM:
      return {
        ...state,
        mergeTeamList: state.mergeTeamList.concat(action.payload)
      }
    // Edit ...
    case EDIT_TEAM:
      return {
        ...state,
        loadingTeam: true,
        editSuccess: null
      }

    case EDIT_TEAM_STATE:
      return {
        ...state,
        editingState: action.payload
      }
    case EDIT_TEAM_FAIL:
      return {
        ...state,
        editingErr: action.payload,
        loadingTeam: false
      }
    case EDIT_TEAM_SUCCESS:
      return {
        ...state,
        editingErr: null,
        loadingTeam: false,
        editSuccess: action.payload
      }
    case EPMTY_EDITING_DATA:
      return {
        ...state,
        loadingTeam: false,
        addTeamName: null,
        renameTeamList: [],
        deleteTeamList: [],
        mergeTeamList: [],
        editingState: null,
        editingErr: null,
        editSuccess: null
      }
    //...  
    case GET_TEAMS_INS_LIST:
      return {
        ...state,
        loadingTeamList: true,
        insTeamListErr: null,
        insTeamsList: null,
      }
    case GET_TEAMS_INS_LIST_SUCCESS:
      return {
        ...state,
        insTeamsList: action.payload,
        loadingTeamList: false,
        insTeamListErr: null
      }
    case GET_TEAMS_INS_LIST_FAIL:
      return {
        ...state,
        insTeamListErr: action.payload,
        loadingTeamList: false
      }
    case GET_INS_TEAMS_LIST:
      return {
        ...state,
        loadingTeam: true,
        insTeamErr: null,
        insTeams: null,
        insId: action.payload
      }
    case GET_INS_TEAMS_LIST_SUCCESS:
      return {
        ...state,
        insTeams: action.payload,
        loadingTeam: false,
        insTeamErr: null
      }
    case GET_INS_TEAMS_LIST_FAIL:
      return {
        ...state,
        insTeamErr: action.payload,
        loadingTeam: false
      }
    // fetching staff...
    case TEAM_STAFF:
      return {
        ...state,
        loadingStaff: true,
        teamStaff: null,
        staffErr: null
      }

    case TEAM_STAFF_SUCCESS:
      return {
        ...state,
        loadingStaff: false,
        teamStaff: action.payload,
        staffErr: null
      }

    case TEAM_STAFF_FAIL:
      return {
        ...state,
        loadingStaff: false,
        teamStaff: null,
        staffErr: action.payload
      }
      /**deleting the undefined member */
      
      case DELETE_UNDEFINED_MEM_LOADER:
        return {
          ...state,
          undefinedMemdeleteloader:true
        }
      case DELETE_UNDEFINED_MEM_STATE:
      return {
        ...state,
        undefinedMemdeleteErr: null,
        undefinedMemdeleteloader:false,
        undefinedMemdeleteSuccess: action.payload,
      }
    case DELETE_UNDEFINED_MEM_FAIL:
      return {
        ...state,
        undefinedMemdeleteErr: action.payload,
        undefinedMemdeleteloader:false,
        undefinedMemdeleteSuccess: null,
      }

    // fetching single team details by id...
    case TEAM_BYID:
      return {
        ...state,
        teamById: true,
        teamByIdSucc: null,
        teamByIdErr: null,
      }

    case TEAM_BYID_SUCCESS:
      return {
        ...state,
        teamById: false,
        teamByIdSucc: action.payload,
        teamByIdErr: null,
      }

    case TEAM_BYID_FAIL:
      return {
        ...state,
        teamById: false,
        teamByIdSucc: null,
        teamByIdErr: action.payload,
      }
      /**deleting the team staff */
      
      case DELETE_TEAM_STAFF_LOADER:
        return {
          ...state,
          teamstaffdeleteloader:true,
          teamstaffdeleteafterSucc:false
        }
      case DELETE_TEAM_STAFF_STATE:
      return {
        ...state,
        teamstaffdeleteErr: null,
        teamstaffdeleteloader:false,
        teamstaffdeleteafterSucc:true,
        teamstaffdeleteSuccess: action.payload,
      }
    case DELETE_TEAM_STAFF_FAIL:
      return {
        ...state,
        teamstaffdeleteErr: action.payload,
        teamstaffdeleteloader:false,
        teamstaffdeleteafterSucc:false,
        teamstaffdeleteSuccess: null,
      }
      case DELETE_TEAM_STAFF_AFTER_SUCC:
        return {
          ...state,
          teamstaffdeleteafterSucc:false,
          teamstaffdeleteErr: null,
          teamstaffdeleteloader:false,
          teamstaffdeleteSuccess: null,
        }
    // fetching team brain image...
    case GET_TEAM_BRAIN_IMAGE:
      return {
        ...state,
        getteamBrainImage: true,
        teamBrainImage: null,
        teamBrainImageErr: null,
      }

    case GET_TEAM_BRAIN_IMAGE_SUCCESS:
      return {
        ...state,
        getteamBrainImage: false,
        teamBrainImage: action.payload,
        teamBrainImageErr: null
      }

    case GET_TEAM_BRAIN_IMAGE_FAIL:
      return {
        ...state,
        getteamBrainImage: false,
        teamBrainImage: null,
        teamBrainImageErr: action.payload,
      }
    // Update Loading Status
    case UPDATE_LOADING_STATUS:
      return {
        ...state,
        loadingOptimizeAllPlayerAnalysis: action.payload,
      }
    //starin
    case GET_ALL_PLAYER_ANALYSIS_DATA:
      return {
        ...state,
        loadingOptimizeAllPlayerAnalysis: true,
        optimizeAllPlayerAnalysisData: null,
        optimizeAllPlayerAnalysisDataErr: false
      }

    case GET_ALL_PLAYER_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        loadingOptimizeAllPlayerAnalysis: false,
        optimizeAllPlayerAnalysisData: action.payload,
        optimizeAllPlayerAnalysisDataErr: false
      }

    case GET_ALL_PLAYER_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        loadingOptimizeAllPlayerAnalysis: false,
        optimizeAllPlayerAnalysisData: null,
        optimizeAllPlayerAnalysisDataErr: action.payload
      }
    //ml
    case GET_ALL_PLAYER_ML_ANALYSIS_DATA:
      return {
        ...state,
        loadingOptimizeAllPlayerMlAnalysis: true,
        optimizeAllPlayerMlAnalysisData: null,
        optimizeAllPlayerMlAnalysisDataErr: false
      }

    case GET_ALL_PLAYER_ML_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        loadingOptimizeAllPlayerMlAnalysis: false,
        optimizeAllPlayerMlAnalysisData: action.payload,
        optimizeAllPlayerMlAnalysisDataErr: false
      }

    case GET_ALL_PLAYER_ML_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        loadingOptimizeAllPlayerMlAnalysis: false,
        optimizeAllPlayerMlAnalysisData: null,
        optimizeAllPlayerMlAnalysisDataErr: action.payload
      }
    //pressure
    case GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA:
      return {
        ...state,
        loadingOptimizeAllPlayerPressureAnalysis: true,
        optimizeAllPlayerPressureAnalysisData: null,
        optimizeAllPlayerPressureAnalysisDataErr: false
      }

    case GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        loadingOptimizeAllPlayerPressureAnalysis: false,
        optimizeAllPlayerPressureAnalysisData: action.payload,
        optimizeAllPlayerPressureAnalysisDataErr: false
      }

    case GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        loadingOptimizeAllPlayerPressureAnalysis: false,
        optimizeAllPlayerPressureAnalysisData: null,
        optimizeAllPlayerPressureAnalysisDataErr: action.payload
      }
    default:
      return state
  }
}

export default Institutions
