import {
  TEAMS_ORGS,
  MONTHLY_LOGS,
  MONTHLY_LOGS_LOADING,
  MONTHLY_LOGS_ERROR
} from "./actionTypes";

import axios from "axios";
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";

// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER,
});

export const setMonthly = () => ({
  type: MONTHLY_LOGS_LOADING,
});
export const setMonthlylogs = (logs) => ({
  type: MONTHLY_LOGS,
  payload: logs,
});
export const setTeamsorg = (logs) => ({
  type: TEAMS_ORGS,
  payload: logs,
});
export const setMonthlylogsError = (logs) => ({
  type: MONTHLY_LOGS_ERROR,
  payload: logs,
});
export const setTeamorgError = (logs) => ({
  type: MONTHLY_LOGS_ERROR,
  payload: logs,
});

export const getMonthlyLogs = () => {
  return (dispatch) => {
    dispatch(setMonthly());
    axios.get("/api/monthlyLogs")
      .then(function (response) {
         //console.log('setMonthlylogs::setMonthlylogs', JSON.stringify(response.data));
        dispatch(setMonthlylogs(response.data.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
         dispatch(signOutUser())
        } else {
          dispatch(setMonthlylogsError(JSON.stringify(error.response.data.message)));
        }
      });
  };
};
export const getTeamsorgs = () => {
  return (dispatch) => {
    dispatch(setMonthly());
    axios.get("/api/teamsorgs")
      .then(function (response) {
        //  console.log('teamsorgs::teamsorgs', JSON.stringify(response.data));
        dispatch(setTeamsorg(response.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
         dispatch(signOutUser())
        } else {
          dispatch(setTeamorgError(JSON.stringify(error.response.data.message)));
        }
      });
  };
};
