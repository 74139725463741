import {
  GET_TEAM_PLAYERS,
  GET_TEAM_PLAYERS_SUCCESS,
  GET_TEAM_PLAYERS_FAIL,

  GET_TEAM_MPS_VARIUS_TIME_SUCC,

  GET_PLAYER_DETAILS,
  GET_PLAYER_DETAILS_SUCCESS,
  GET_PLAYER_DETAILS_FAIL,

  FILTER_TEAM_PLAYERS,
  FILTER_TEAM_PLAYERS_SUCCESS,
  FILTER_TEAM_PLAYERS_FAIL,
  RESET_TEAM_PLAYERS,

  PLAYER_SIMULATION,
  PLAYER_SIMULATION_SUCCESS,
  PLAYER_SIMULATION_FAIL,

  PLAYER_ALL_SIMULATIONS_SUCCESS,

  SIMULATION_BY_EVENT_ID,
  SIMULATION_BY_EVENT_ID_SUCCESS,
  SIMULATION_BY_EVENT_ID_FAIL,

  GET_PLAYER_SUMMARY,
  GET_PLAYER_SUMMARY_SUCCESS,
  GET_PLAYER_SUMMARY_FAIL,

  GET_SIM_ACC_DATA,
  GET_SIM_ACC_DATA_SUCC,
  GET_SIM_ACC_DATA_FAIL,

  GET_PLAYER_ANYLSIS,
  GET_PLAYER_ANYLSIS_SUCC,
  GET_PLAYER_ANYLSIS_FAIL,

  GET_SIMULATION_VIDEOS,
  GET_SIMULATION_VIDEOS_SUCC,
  GET_SIMULATION_VIDEOS_FAIL,

  SET_SIDELINE_URL,

  GET_MPS_VARIUS_TIME,
  GET_MPS_VARIUS_TIME_SUCC,
  GET_MPS_VARIUS_TIME_FAIL,

  TRIM_VIDEOS,
  TRIM_VIDEOS_SUCC,
  TRIM_VIDEOS_FAIL,

  RESET_TRIM_STATE,
  SET_VIDEO_FPS,

  RESET_SIDELINE_VIDEO,
  RESET_SIDELINE_VIDEO_SUCC,
  RESET_SIDELINE_VIDEO_FAIL,

  MIRROR_SIDELINE_VIDEO,
  MIRROR_SIDELINE_VIDEO_SUCC,
  MIRROR_SIDELINE_VIDEO_FAIL,

  SET_PLAYER_MPS_VALUES,

  FILTER_PLAYER_SIMULATION,
  FILTER_PLAYER_SIMULATION_SUCCESS,
  FILTER_PLAYER_SIMULATION_FAIL,

  GET_PL_BY_TYPE,
  GET_PL_BY_TYPE_SUCCESS,
  GET_PL_BY_TYPE_FAIL,

  SET_PL_BY_TYPE,
  FAMILY_PLAYER_STAFF,
  FAMILY_PLAYER_STAFF_SUCCESS,
  FAMILY_PLAYER_STAFF_FAIL,
  SET_SELECTED_SPHERE_TYPE,
  MAX_VALUE_OF_BRAIN_EEFECT,
  PROCESS_ML_SUBMIT,
  ERROR_MESSAGE_ML_SUBMIT,
  SUCCESS_MESSAGE_ML_SUBMIT,
  RESET_ML_SUBMIT,

  //strain
  GET_PLAYER_ANALYSIS_DATA,
  GET_PLAYER_ANALYSIS_DATA_SUCCESS,
  GET_PLAYER_ANALYSIS_DATA_FAIL,

  // ml
  GET_PLAYER_ML_ANALYSIS_DATA,
  GET_PLAYER_ML_ANALYSIS_DATA_SUCCESS,
  GET_PLAYER_ML_ANALYSIS_DATA_FAIL,

  // pressure
  GET_PLAYER_PRESSURE_ANALYSIS_DATA,
  GET_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS,
  GET_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL,

  // player chart sims
  GET_PLAYER_ALL_SIMS_PL,
  GET_PLAYER_ALL_SIMS_PL_SUCCESS,
  GET_PLAYER_ALL_SIMS_PL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  selected_sphere_type: null,
  getteamPlayers: false,
  teamPlayers: null,
  teamPlayersErr: null,
  total_players: 0,

  getPlayersDetails: false,
  PlayersDetails: null,
  getPlayersDetailsErr: null,

  getPlayerSim: false,
  playerSimulations: null,
  playerSimFail: null,

  playerAllSimulations: null,

  getSimByEventId: false,
  singleSimulation: null,
  getSimByEventIdErr: null,

  getPlayerSummary: false,
  playerSummary: null,
  playerSummaryErr: null,

  getSimAccData: false,
  eventSimAccData: null,
  getSimAccErr: null,

  getPlayerAnylsis: false,
  playerAnylsisData: null,
  playerAnylsisErr: null,

  getSimVideos: false,
  motionVideo: null,
  simulationVideo: null,
  sidelineVideo: null,
  getSimVideosErr: null,

  getPlayerBrainMpsLoading: false,
  PlayerBrainMpsLoadingTime: null,
  getPlayerBrainMpsLoadingErr: null,

  trimVideo: false,
  trimedVideo: null,
  trimedVideoSucc: false,
  trimVideoErr: null,
  sidelineVideoFpsRate: null,

  resetVideo: false,
  resetVideoSucc: null,
  resetVideoErr: null,

  flipVideo: false,
  flipVideoSucc: null,
  flipVideoErr: null,

  playerMpsValues: null,
  total_simulations: 0,

  getmlTlPL: false,
  getmlTlPLSucc: null,
  getmlTlPLErr: null,
  maxValeOfBrainEffectStatus:false,
  maxValeOfBrainEffect:null,

  processingMlSubmit: false,
  errorMessageMlSubmit: false,
  successMessageMlSubmit: false,

  //STARIN
  loadingOptimizePlayerAnalysis: false,
  optimizePlayerAnalysisData: null,
  optimizePlayerAnalysisDataErr: false,

  //ML
  loadingOptimizePlayerMlAnalysis: false,
  optimizePlayerMlAnalysisData: null,
  optimizePlayerMlAnalysisDataErr: false,

  //PRESSURE
  loadingOptimizePlayerPressureAnalysis: false,
  optimizePlayerPressureAnalysisData: null,
  optimizePlayerPressureAnalysisDataErr: false,

  // get player dashboard sims chart
  loadingPlayerSimsPl: false,
  playerSimPlData: null,
  playerSimPlDataErr: false
}

const Institutions = (state = INIT_STATE, action) => {
  switch (action.type) {

    case SET_SELECTED_SPHERE_TYPE:
    {
      return {
        ...state,
        selected_sphere_type: action.payload
      }
    }
    case MAX_VALUE_OF_BRAIN_EEFECT:
      {
        return {
          ...state,
          maxValeOfBrainEffectStatus: true,
          maxValeOfBrainEffect:action.payload
        }
      }
    case SET_PL_BY_TYPE:
      return {
        ...state,
        getmlTlPLSucc: action.payload,
      }
    // ML toolkit pl...
    case GET_PL_BY_TYPE:
      return {
        ...state,
        getmlTlPL: true,
        getmlTlPLErr: null,
      }

    case GET_PL_BY_TYPE_SUCCESS:
      return {
        ...state,
        getmlTlPL: false,
        getmlTlPLSucc: action.payload,
        getmlTlPLErr: null,
      }

    case GET_PL_BY_TYPE_FAIL:
      return {
        ...state,
        getmlTlPL: false,
        getmlTlPLSucc: null,
        getmlTlPLErr: action.payload,
      }
    // filter players simulations...
    case FILTER_PLAYER_SIMULATION:
      return {
        ...state,
        getPlayerSim: true,
        playerSimulations: null,
        playerSimFail: null
      }

    case FILTER_PLAYER_SIMULATION_SUCCESS:
      return {
        ...state,
        getPlayerSim: false,
        playerSimulations: action.payload.simulations,
        playerSimFail: null
      }

    case FILTER_PLAYER_SIMULATION_FAIL:
      return {
        ...state,
        getPlayerSim: false,
        playerSimulations: null,
        playerSimFail: action.payload
      }
    //Flip video...
    case SET_PLAYER_MPS_VALUES:
      return {
        ...state,
        playerMpsValues: action.payload
      }
    //Flip video...
    case MIRROR_SIDELINE_VIDEO:
      return {
        ...state,
        flipVideo: true,
        flipVideoSucc: null,
        flipVideoErr: null
      }

    case MIRROR_SIDELINE_VIDEO_SUCC:
      return {
        ...state,
        flipVideo: false,
        flipVideoSucc: action.payload,
        sidelineVideo: action.payload.sideLineVideoUrl,
        flipVideoErr: null
      }

    case MIRROR_SIDELINE_VIDEO_FAIL:
      return {
        ...state,
        flipVideo: false,
        flipVideoSucc: null,
        flipVideoErr: action.payload
      }
    //..

    // reset video
    case RESET_SIDELINE_VIDEO:
      return {
        ...state,
        resetVideo: true,
        resetVideoSucc: false,
        flipVideoSucc: false,
        resetVideoErr: null
      }

    case RESET_SIDELINE_VIDEO_SUCC:
      return {
        ...state,
        resetVideo: false,
        resetVideoSucc: action.payload,
        sidelineVideoFpsRate: action.payload.fps_sideline_video.fps_rate ? parseFloat(action.payload.fps_sideline_video.original_fps_rate).toFixed(2) : null,
        sidelineVideo: action.payload.sideLineVideoUrl,
        resetVideoErr: null
      }

    case RESET_SIDELINE_VIDEO_FAIL:
      return {
        ...state,
        resetVideo: false,
        resetVideoSucc: false,
        resetVideoErr: action.payload
      }
    //...

    case SET_VIDEO_FPS:
      return {
        ...state,
        sidelineVideoFpsRate: action.payload,
      }
    case RESET_TRIM_STATE:
      return {
        ...state,
        trimVideo: false,
        trimedVideoSucc: false,
        resetVideoSucc: false,
      }
    //  ...
    case TRIM_VIDEOS:
      return {
        ...state,
        trimVideo: true,
        trimedVideo: null,
        trimedVideoSucc: false,
        trimVideoErr: null,
      }

    case TRIM_VIDEOS_SUCC:
      return {
        ...state,
        trimVideo: false,
        trimedVideo: action.payload.trimedVideoLink,
        sidelineVideo: action.payload.trimedVideoLink,
        sidelineVideoFpsRate: parseFloat(action.payload.fps_of_trim_video).toFixed(2),
        trimedVideoSucc: true,
        trimVideoErr: null,
      }

    case TRIM_VIDEOS_FAIL:
      return {
        ...state,
        trimVideo: false,
        trimedVideo: null,
        trimedVideoSucc: false,
        sidelineVideoFpsRate: null,
        trimVideoErr: action.payload,
      }

    //  ...
    case GET_MPS_VARIUS_TIME:
      return {
        ...state,
        getPlayerBrainMpsLoading: true,
        PlayerBrainMpsLoadingTime: null,
        getPlayerBrainMpsLoadingErr: null,
      }

    case GET_MPS_VARIUS_TIME_SUCC:
      return {
        ...state,
        getPlayerBrainMpsLoading: false,
        PlayerBrainMpsLoadingTime: action.payload.mpsTimeData,
        getPlayerBrainMpsLoadingErr: null,
      }

    case GET_MPS_VARIUS_TIME_FAIL:
      return {
        ...state,
        getPlayerBrainMpsLoading: false,
        PlayerBrainMpsLoadingTime: null,
        getPlayerBrainMpsLoadingErr: action.payload,
      }
    
    case RESET_TEAM_PLAYERS:
      return {
        ...state,
        teamPlayers: null,
        teamPlayersErr: null,
      }

    case GET_TEAM_MPS_VARIUS_TIME_SUCC:
      return {
        ...state,
        TeamBrainMpsLoadingTime: action.payload,
      }

    // SET SIDLIEN URL ...
    case SET_SIDELINE_URL:
      return {
        ...state,
        sidelineVideo: action.payload,
        // sidelineVideoFpsRate: null,

      }

    // Event simulation acceleration ...
    case GET_SIMULATION_VIDEOS:
      return {
        ...state,
        getSimVideos: true,
        motionVideo: null,
        simulationVideo: null,
        sidelineVideo: null,
        getSimVideosErr: null
      }

    case GET_SIMULATION_VIDEOS_SUCC:
      return {
        ...state,
        getSimVideos: false,
        motionVideo: action.payload.motionVideoUrl,
        simulationVideo: action.payload.simulationVideoUrl,
        sidelineVideo: action.payload.sideLineVideoUrl,
        sidelineVideoFpsRate: action.payload.fps_sideline_video ? parseFloat(action.payload.fps_sideline_video.fps_rate ? action.payload.fps_sideline_video.fps_rate : action.payload.fps_sideline_video.original_fps_rate).toFixed(2) : null,
        getSimVideosErr: null
      }

    case GET_SIMULATION_VIDEOS_FAIL:
      return {
        ...state,
        getSimVideos: false,
        motionVideo: null,
        simulationVideo: null,
        sidelineVideo: null,
        getSimVideosErr: action.payload
      }

    // Event simulation acceleration ...
    case GET_PLAYER_ANYLSIS:
      return {
        ...state,
        getPlayerAnylsis: true,
        playerAnylsisData: null,
        playerAnylsisErr: null
      }

    case GET_PLAYER_ANYLSIS_SUCC:
      return {
        ...state,
        getPlayerAnylsis: false,
        playerAnylsisData: action.payload,
        playerAnylsisErr: null
      }

    case GET_PLAYER_ANYLSIS_FAIL:
      return {
        ...state,
        getPlayerAnylsis: false,
        playerAnylsisData: null,
        playerAnylsisErr: action.payload
      }

    // Event simulation acceleration ...
    case GET_SIM_ACC_DATA:
      return {
        ...state,
        getSimAccData: true,
        eventSimAccData: null,
        getSimAccErr: null
      }

    case GET_SIM_ACC_DATA_SUCC:
      return {
        ...state,
        getSimAccData: false,
        eventSimAccData: action.payload,
        getSimAccErr: null
      }

    case GET_SIM_ACC_DATA_FAIL:
      return {
        ...state,
        getSimAccData: false,
        eventSimAccData: null,
        getSimAccErr: action.payload
      }

    // Single player summary details...
    case GET_PLAYER_SUMMARY:
      return {
        ...state,
        getPlayerSummary: true,
        playerSummary: null,
        playerSummaryErr: null
      }

    case GET_PLAYER_SUMMARY_SUCCESS:
      return {
        ...state,
        getPlayerSummary: false,
        playerSummary: action.payload,
        playerSummaryErr: null
      }

    case GET_PLAYER_SUMMARY_FAIL:
      return {
        ...state,
        getPlayerSummary: false,
        playerSummary: null,
        playerSummaryErr: action.payload
      }

    // players simulation by evnet id...
    case SIMULATION_BY_EVENT_ID:
      return {
        ...state,
        getSimByEventId: true,
        singleSimulation: null,
        getSimByEventIdErr: null
      }

    case SIMULATION_BY_EVENT_ID_SUCCESS:
      return {
        ...state,
        getSimByEventId: false,
        singleSimulation: action.payload.simulations,
        getSimByEventIdErr: null
      }

    case SIMULATION_BY_EVENT_ID_FAIL:
      return {
        ...state,
        getSimByEventId: false,
        singleSimulation: null,
        getSimByEventIdErr: action.payload
      }
    // players simulations...

    case PLAYER_ALL_SIMULATIONS_SUCCESS:
      return {
        ...state,
        playerAllSimulations: action.payload.simulations,
        //playerSimFail: null
      }

    case PLAYER_SIMULATION:
      return {
        ...state,
        getPlayerSim: true,
        playerSimulations: null,
        total_simulations: 0,
        playerSimFail: null
      }

    case PLAYER_SIMULATION_SUCCESS:
      return {
        ...state,
        getPlayerSim: false,
        total_simulations: action.payload.TotalSim,
        playerSimulations: action.payload.simulations,
        playerSimFail: null
      }

    case PLAYER_SIMULATION_FAIL:
      return {
        ...state,
        getPlayerSim: false,
        total_simulations: 0,
        playerSimulations: null,
        playerSimFail: action.payload
      }
    // filter players...
    case FILTER_TEAM_PLAYERS:
      return {
        ...state,
        getteamPlayers: true,
        teamPlayers: null,
        teamPlayersErr: null,
      }

    case FILTER_TEAM_PLAYERS_SUCCESS:
      return {
        ...state,
        getteamPlayers: false,
        teamPlayers: action.payload,
        teamPlayersErr: null
      }

    case FILTER_TEAM_PLAYERS_FAIL:
      return {
        ...state,
        getteamPlayers: false,
        teamPlayersErr: action.payload,
      }
    // fetching single team details by id...
    case GET_TEAM_PLAYERS:
      return {
        ...state,
        getteamPlayers: true,
        teamPlayers: null,
        teamPlayersErr: null,
        total_players: 0
      }

    case GET_TEAM_PLAYERS_SUCCESS:
      return {
        ...state,
        getteamPlayers: false,
        teamPlayers: action.payload.players,
        total_players: action.payload.total_Player[0],
        teamPlayersErr: null
      }

    case GET_TEAM_PLAYERS_FAIL:
      return {
        ...state,
        getteamPlayers: false,
        teamPlayers: null,
        teamPlayersErr: action.payload,
        total_players: 0
      }
    // player deatils reducer ...
    case GET_PLAYER_DETAILS:
      return {
        ...state,
        getPlayersDetails: true,
        PlayersDetails: null,
        getPlayersDetailsErr: null
      }

    case GET_PLAYER_DETAILS_SUCCESS:
      return {
        ...state,
        getPlayersDetails: false,
        PlayersDetails: action.payload,
        getPlayersDetailsErr: null
      }

    case GET_PLAYER_DETAILS_FAIL:
      return {
        ...state,
        getPlayersDetails: false,
        PlayersDetails: null,
        getPlayersDetailsErr: action.payload
      }
      // fetching staff...
    case FAMILY_PLAYER_STAFF:
      return {
        ...state,
        loadingStaff: true,
        familyPlayerStaff: null,
        staffErr: null
      }

    case FAMILY_PLAYER_STAFF_SUCCESS:
      return {
        ...state,
        loadingStaff: false,
        familyPlayerStaff: action.payload,
        staffErr: null
      }

    case FAMILY_PLAYER_STAFF_FAIL:
      return {
        ...state,
        loadingStaff: false,
        familyPlayerStaff: null,
        staffErr: action.payload
      }
    case PROCESS_ML_SUBMIT:
      return {
        ...state,
        processingMlSubmit: true,
        errorMessageMlSubmit: false,
        successMessageMlSubmit: false,
      }
    case ERROR_MESSAGE_ML_SUBMIT:
      return {
        ...state,
        processingMlSubmit: false,
        errorMessageMlSubmit: action.payload,
        successMessageMlSubmit: false,
      }
    
    case SUCCESS_MESSAGE_ML_SUBMIT:
      return {
        ...state,
        processingMlSubmit: false,
        errorMessageMlSubmit: false,
        successMessageMlSubmit: action.payload,
      }
    
    case RESET_ML_SUBMIT:
      return {
        ...state,
        processingMlSubmit: false,
        errorMessageMlSubmit: false,
        successMessageMlSubmit: false,
      }
    //starin
    case GET_PLAYER_ANALYSIS_DATA:
      return {
        ...state,
        loadingOptimizePlayerAnalysis: true,
        optimizePlayerAnalysisData: null,
        optimizePlayerAnalysisDataErr: false
      }

    case GET_PLAYER_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        loadingOptimizePlayerAnalysis: false,
        optimizePlayerAnalysisData: action.payload,
        optimizePlayerAnalysisDataErr: false
      }

    case GET_PLAYER_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        loadingOptimizePlayerAnalysis: false,
        optimizePlayerAnalysisData: null,
        optimizePlayerAnalysisDataErr: action.payload
      }
    //ml
    case GET_PLAYER_ML_ANALYSIS_DATA:
      return {
        ...state,
        loadingOptimizePlayerMlAnalysis: true,
        optimizePlayerMlAnalysisData: null,
        optimizePlayerMlAnalysisDataErr: false
      }

    case GET_PLAYER_ML_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        loadingOptimizePlayerMlAnalysis: false,
        optimizePlayerMlAnalysisData: action.payload,
        optimizePlayerMlAnalysisDataErr: false
      }

    case GET_PLAYER_ML_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        loadingOptimizePlayerMlAnalysis: false,
        optimizePlayerMlAnalysisData: null,
        optimizePlayerMlAnalysisDataErr: action.payload
      }
    //pressure
    case GET_PLAYER_PRESSURE_ANALYSIS_DATA:
      return {
        ...state,
        loadingOptimizePlayerPressureAnalysis: true,
        optimizePlayerPressureAnalysisData: null,
        optimizePlayerPressureAnalysisDataErr: false
      }

    case GET_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        loadingOptimizePlayerPressureAnalysis: false,
        optimizePlayerPressureAnalysisData: action.payload,
        optimizePlayerPressureAnalysisDataErr: false
      }

    case GET_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        loadingOptimizePlayerPressureAnalysis: false,
        optimizePlayerPressureAnalysisData: null,
        optimizePlayerPressureAnalysisDataErr: action.payload
      }
    
    case GET_PLAYER_ALL_SIMS_PL:
      return {
        ...state,
        loadingPlayerSimsPl: true,
        playerSimPlData: null,
        playerSimPlDataErr: false
      }
    
    case GET_PLAYER_ALL_SIMS_PL_SUCCESS:
      return {
        ...state,
        loadingPlayerSimsPl: false,
        playerSimPlData: action.payload,
        playerSimPlDataErr: false
      }

    case GET_PLAYER_ALL_SIMS_PL_FAIL:
      return {
        ...state,
        loadingPlayerSimsPl: false,
        playerSimPlData: null,
        playerSimPlDataErr: action.payload
      }

    default:
      return state
  }
}

export default Institutions
