import React, { Component } from "react"
import { connect } from "react-redux"
import {
  Container,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Card,
  CardTitle,
} from "reactstrap"
import { Link, withRouter, Redirect } from "react-router-dom"
import "././header.scss"
import { activeSidebarMenu, logout } from "../../../store/actions"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { People, ExclamationOctagon, BoxArrowRight } from 'react-bootstrap-icons';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      user: localStorage.getItem("authUser"),
      logoutClick: false
    }

  }
  handleClickOutside = event => {
    let menuClass = document.querySelector('.mobile-menu').classList.contains('active')
    let currentClickedClass = event.target.className;
    // console.log('class added', menuClass)
    // console.log('event.target.className', event.target.className)
    //console.log('event.target.className',event.target.attributes.getNamedItem('t').value )
    if (menuClass && currentClickedClass != "menuToggleBtn" && currentClickedClass != "mobile-menu active" && currentClickedClass != "nsf" && currentClickedClass != "nsf1" && currentClickedClass != "navbar" && currentClickedClass != "navbar-toggler" && currentClickedClass != "navbar-toggler-icon " && currentClickedClass != "menuToggleBtn") {

      this.props.activeSidebarMenu();
    }

  };
  componentDidMount() {
    document.body.classList.add('home-body')
    document.body.classList.remove('dash-body')
    document.addEventListener("click", this.handleClickOutside, false);

  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }
  logoutUser = () => {
    this.props.logout()
    this.props.activeSidebarMenu();
    this.props.history.push('/home')
    this.setState({ logoutClick: true })

  }
  menuRedirection(e) {

    var attribute = e.target.attributes.getNamedItem('rel').value;
    if (attribute == "signout") {
      this.props.logout();
      this.props.activeSidebarMenu();
      this.setState({ logoutClick: true })
    }
    else if (attribute == "/login") {
      this.props.activeSidebarMenu();
      document.querySelector('body').classList.remove("mobile-menu-overlay");
      this.props.history.push(attribute)
    }
    else {

      this.props.activeSidebarMenu();
      this.props.history.push(attribute)
    }


  }

  render() {
    const { user, isOpen, logoutClick } = this.state;
    const { islogout, location, activeSidebarMenu, activeSidebar, headClass } = this.props;
    if (this.props.activeSidebar) {
      document.querySelector('body').classList.add("mobile-menu-overlay");
    } else {
      document.querySelector('body').classList.remove("mobile-menu-overlay");
    }
    // if (islogout && logoutClick)
    //   return (
    //     <Redirect
    //       to={{ pathname: "/home" }}
    //     />
    //   )
    if (islogout) return window.location.reload();
    let newUser = JSON.parse(user);
    if (user) var userName = newUser.first_name.substring(0, 1) + ' ' + newUser.last_name.substring(0, 1)
    var fullName = '';
    if (user) {
      var userName = newUser.first_name.substring(0, 1) + ' ' + newUser.last_name.substring(0, 1);
      fullName = newUser.first_name + ' ' + newUser.last_name;
    }
    return (
      <React.Fragment>
        <div className={`site-header inner-header ${headClass}`} id="myHeader">
          <Container fluid>
            <div className="header-layout">
              <div className="site-logo">
                <Link to="/home">
                  <LazyLoadImage
                    alt={"logo"}
                    src={"/Evoshield-logo.png"} // use normal <img> attributes as props
                  />
                  {/* <img src="/BrainSimResearch.svg" alt="logo" /> */}
                </Link>
              </div>
              <div className="main-menu">
                <nav className="navbar navbar-expand-lg">
                  <div className="navbar-toggler" onClick={() => activeSidebarMenu()}>
                    <span className="navbar-toggler-icon " ><img src="/toggle.png" alt="toggle" className="menuToggleBtn" /></span>
                  </div>
                  {/* <Button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" onClick={() => activeSidebarMenu()}><img src="/toggle.png" alt="toggle" /></span>
                  </Button> */}
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item active">
                        <Link className="nav-link" to="/home">{" "}Home{" "}</Link>{" "}
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact">{" "}Contact Us{" "}</Link>{" "}
                      </li>
                      {!user ? (
                        <li className="nav-item">
                          <Link className="nav-link buy-menu" to="/buy-sensor">{" "}Shop{" "}</Link>
                          {/* <Link className="nav-link buy-menu" to="/subscription/plan">{" "}Buy Now{" "}</Link> */}
                        </li>
                      ) : (
                        <li className="nav-item">
                          <Link className="nav-link buy-menu" to="/shop">{" "}Shop{" "}</Link>
                          {/* <Link className="nav-link buy-menu" to="/subscription/plan">{" "}Buy Now{" "}</Link> */}
                        </li>
                      )}
                      <li className="nav-item">


                      </li>

                    </ul>
                    <ul className="navbar-nav right-menu">
                      {
                        user ?
                          <li className="nav-item"><Link className="nav-link btn" to="/user/dashboard">Go To Dashboard</Link></li>
                          :
                          <li className="nav-item"><Link className="nav-link btn" to="/login">Sign In</Link></li>
                      }
                      {
                        user &&
                        <li className="dropdown user user-menu">
                          <a className="nav-link user-pr-pop" href="#" >
                            <Dropdown isOpen={isOpen} toggle={() => this.setState({ isOpen: isOpen ? false : true })}>
                              <DropdownToggle
                                style={{
                                  'background': 'none',
                                  'border': 'none',
                                  'padding': 0,
                                  'margin': 0,
                                }}
                              >
                                <div className="rounded-circle profile-user-name">{userName}</div>
                                {/* <img src="/profile.png" className="rounded-circle " alt="User Image" /> */}
                              </DropdownToggle>
                              <DropdownMenu

                                modifiers={{
                                  setMaxHeight: {
                                    enabled: true,
                                    order: 890,
                                    fn: (data) => {
                                      return {
                                        ...data,
                                        styles: {
                                          ...data.styles,
                                          overflow: 'auto',
                                          'transform': 'translate3d(-100px, 50px, 0px)'
                                        },
                                      };
                                    },
                                  },
                                }}
                              >
                                <Card body
                                  style={{
                                    'border': 'none'
                                  }}
                                >
                                  <div>
                                    <div className='user-pf-pop-head'>

                                      <div className="rounded-circle profile-user-name-2">{userName}</div>
                                      <div style={{ display: 'inline-block' }} className="pl-pf-dt">
                                        {fullName}<br />
                                        {/* <p>{fullName}</p>
                                      <p>Admin</p> */}
                                      </div>
                                    </div>
                                    <div>
                                      <Link to={"/profile/" + newUser._id} className="user-pf-item"><People size={20} />Profile</Link>
                                      <Link to={"/contact"} className="user-pf-item"><ExclamationOctagon size={35} />Report a problem or request a feature</Link>
                                      <Link to={"#"} onClick={() => this.props.logout()} className="user-pf-item"><BoxArrowRight size={20} />Sign out</Link>
                                    </div>
                                  </div>
                                </Card>
                              </DropdownMenu>
                            </Dropdown>
                          </a>
                        </li>
                      }
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile menu */}
              <div className={activeSidebar ? "mobile-menu active" : "mobile-menu"}>
                <div className="nsf1">
                  <div className="nsf">
                    <Button variant="primary" className="close-btn" onClick={() => activeSidebarMenu()}>
                      <img src="/ic-cross.png" />
                      {/* <svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" /></svg> */}
                    </Button>
                    <span className="nsf1">nsf</span>
                  </div>
                  <ul className="nav">
                    <li className="nav-item active">
                      {/* <Link className="nav-link" to="/home">{" "}Home{" "}</Link>{" "} */}
                      {/* <Button variant="link" rel="/home" onClick={(e) => this.menuRedirection(e)}>{" "}Home{" "}</Button> */}
                      <Link className="nav-link home-menu" rel="/home" onClick={(e) => this.menuRedirection(e)}>{" "}Home{" "}</Link>{" "}
                    </li>

                    {/* <li className="nav-item">
                    <Link className="nav-link howitwork-menu" rel="/how-it-works"  onClick={(e) => this.menuRedirection(e)}>{" "}How It Works {" "}</Link>{" "}
                  </li>
                  <li className="nav-item ">
                    <Link className="nav-link validtion-menu" rel="/technical-details" onClick={(e) => this.menuRedirection(e)}>{" "}Technical Details{" "}</Link>{" "}
                  </li> */}
                    <li className="nav-item">
                      {/* <Button variant="link" rel="/contact" onClick={(e) => this.menuRedirection(e)}>{" "}Contact Us{" "}</Button> */}
                      <Link className="nav-link  contact-menu" rel="/contact" onClick={(e) => this.menuRedirection(e)}>{" "}Contact Us{" "}</Link>{" "}
                      {/* <Link className="nav-link" to="/contact">{" "}Contact Us{" "}</Link>{" "} */}
                    </li>
                    {
                      user && <li className="nav-item">
                      {/* <Link className="nav-link" to="/user/dashboard">{" "}Dashboard{" "}</Link>{" "} */}
                      {/* <Button variant="link" rel="/user/dashboard" onClick={(e) => this.menuRedirection(e)}>{" "}Dashboard{" "}</Button> */}
                      <Link className="nav-link dashboard-menu" rel="/user/dashboard" onClick={(e) => this.menuRedirection(e)}>{" "}Dashboard{" "}</Link>{" "}
                      </li>
                    }
                    {
                      user &&
                      <li className="nav-item">
                        {/* <Link className="nav-link" to={"/profile/" + newUser._id}>Profile</Link> */}
                        {/* <Button variant="link" rel={"/profile/" + newUser._id} onClick={(e) => this.menuRedirection(e)}>{" "}Profile{" "}</Button> */}
                        <Link className="nav-link profile-menu" rel={"/profile/" + newUser._id} onClick={(e) => this.menuRedirection(e)}>Profile</Link>
                      </li>
                    }
                    {!user ? (
                      <li className="nav-item">
                        <Link className="nav-link buy-menu" rel="/buy-sensor" onClick={(e) => this.menuRedirection(e)}>{" "}Shop{" "}</Link>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <Link className="nav-link buy-menu" rel="/shop" onClick={(e) => this.menuRedirection(e)}>{" "}Shop{" "}</Link>
                      </li>
                    )}
                    <li className="nav-item">
                      {
                        user ?
                          // <Link className="nav-link" onClick={this.logoutUser}> Sign Out </Link>
                          // <Button className="nav-link" variant="link"  onClick={this.logoutUser}>Sign Out</Button>
                          <Link to={"#"} className="nav-link sign-menu" rel="signout" onClick={(e) => this.menuRedirection(e)} >Sign out</Link>
                          :
                          // <Link className="nav-link" to="/login"> Sign In </Link>
                          // <Button className="nav-link" variant="link" rel="/login" onClick={(e) =>this.menuRedirection(e)}>Sign In</Button>
                          <Link to={"#"} className="nav-link sign-menu" rel="/login" onClick={(e) => this.menuRedirection(e)}>Sign In</Link>
                      }

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeSidebar: state.Layout.activeSidebar,
    islogout: state.Login.islogout,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    activeSidebarMenu: () => dispatch(activeSidebarMenu())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))

