import axios from "axios";
import _ from "lodash";
import {
  GET_INS_TEAMS_LIST,
  GET_INS_TEAMS_LIST_SUCCESS,
  GET_INS_TEAMS_LIST_FAIL,

  GET_TEAMS_INS_LIST,
  GET_TEAMS_INS_LIST_SUCCESS,
  GET_TEAMS_INS_LIST_FAIL,

  TEAM_STAFF,
  TEAM_STAFF_SUCCESS,
  TEAM_STAFF_FAIL,
  ADD_TEAM,
  RENAME_TEAM,
  DELETS_TEAM,
  EDIT_TEAM,
  EDIT_TEAM_STATE,
  EDIT_TEAM_SUCCESS,
  EDIT_TEAM_FAIL,
  RESET_TEAM_SIMULATIONS,
  EPMTY_EDITING_DATA,
  MERGE_TEAM,
  TEAM_BYID,
  TEAM_BYID_SUCCESS,
  TEAM_BYID_FAIL,
  MESH_TYPE,
  JOBS_UPLOAD_SUCCESS,
  UPDATE_SENSOR_ID,
  UPDATE_PLAYER_STATUS,
  DELETE_PLAYERS,
  EDIT_PLAYER,
  EDIT_PLAYER_STATE,
  EDIT_PLAYER_SUCCESS,
  EDIT_PLAYER_FAIL,
  EPMTY_EDITING_PLAYERS_DATA,
  UPDATE_ALL_PLAYER_STATUS,
  GET_TEAM_STAT_SUMMARY,
  GET_TEAM_STAT_SUMMARY_SUCCESS,
  GET_TEAM_STAT_SUMMARY_FAIL,
  FETCH_TEAM_MEMBERS,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAIL,
  REMOVE_TEAM_MEMBERS,
  REMOVE_TEAM_MEMBERS_SUCC,
  REMOVE_TEAM_MEMBERS_FAIL,
  SET_TEAM_ANYLSIS_DATA,
  INSTANCE_TYPE,
  MESH_SUBTYPE,
  EXCLUDE_SIMULATIONS,
  SET_TEAM_MPS_DATA,
  GET_TEAM_SIMULATIONS,
  GET_TEAM_SIMULATIONS_SUCCESS,
  GET_TEAM_SIMULATIONS_FAIL,
  GET_SIMS_LOG,
  SIMS_LOG_SUCCESS,
  SIMS_LOG_FAIL,
  GET_TEAMS_LIST,
  GET_TEAMS_LIST_SUCCESS,
  GET_TEAMS_LIST_FAIL,
  CHANGE_ADMIN_TO_PLAYER,
  DELETE_UNDEFINED_MEM_FAIL,
  DELETE_UNDEFINED_MEM_STATE,
  DELETE_UNDEFINED_MEM_LOADER,
  DELETE_TEAM_STAFF_STATE,
  DELETE_TEAM_STAFF_FAIL,
  DELETE_TEAM_STAFF_LOADER,
  DELETE_TEAM_STAFF_AFTER_SUCC,
  ML_TRAINING_RESULT_GET,
  ML_TRAINING_RESULT,
  ML_TRAINING_RESULT_FAIL,
  GET_ALL_SIMULATIONS_MPS_SUCC,
  GET_ALL_SIMULATIONS_MPS,
  GET_ALL_SIMULATIONS_MPS_FAIL,
  GET_TEAM_BRAIN_IMAGE,
  GET_TEAM_BRAIN_IMAGE_SUCCESS,
  GET_TEAM_BRAIN_IMAGE_FAIL,

  //top 20
  FETCH_TEAM_TOP_30_DATA,
  FETCH_TEAM_TOP_30_DATA_SUCCESS,
  FETCH_TEAM_TOP_30_DATA_FAIL,

  //STRAIN
  GET_ALL_PLAYER_ANALYSIS_DATA,
  GET_ALL_PLAYER_ANALYSIS_DATA_SUCCESS,
  GET_ALL_PLAYER_ANALYSIS_DATA_FAIL,
  //ML
  GET_ALL_PLAYER_ML_ANALYSIS_DATA,
  GET_ALL_PLAYER_ML_ANALYSIS_DATA_SUCCESS,
  GET_ALL_PLAYER_ML_ANALYSIS_DATA_FAIL,

  //PRESSURE
  GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA,
  GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS,
  GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL,

  GET_TEAM_SENSOR_DEVICES,
  GET_TEAM_SENSOR_DEVICES_REQUEST_SUCCESS,
  GET_TEAM_SENSOR_DEVICES_REQUEST_FAILED,

  UPDATE_LOADING_STATUS
} from "./actionTypes";
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";
import summaryData from "./optimize_data/champ_data.json";
import compressedData from "./optimize_data/champ_data_compressed.json"
import { GET_TEAM_PLAYERS_SUCCESS } from "../players/actionTypes";
//fetch org teams
export const fetchOrgTeams = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .patch("/team/organziatons/teams", body)
      .then(function (response) {
        resolve(response.data.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const fetchMltraningRes = () => ({
  type: ML_TRAINING_RESULT_GET,
});

const fetchMltraningResSuccess = (items) => ({
  type: ML_TRAINING_RESULT,
  payload: items,
});

const fetchMltraningResFail = (error) => ({
  type: ML_TRAINING_RESULT_FAIL,
  payload: error,
});

export const resetTeamSimulations = () => ({
  type: RESET_TEAM_SIMULATIONS
})
export const fetchMLTraniningResults = (body) => {
  return async (dispatch) => {
    dispatch(fetchMltraningRes());
    axios
      .patch("/team/result/ml-traning", body)
      .then(function (response) {
        dispatch(fetchMltraningResSuccess(response.data.data));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(fetchMltraningResFail(JSON.stringify(error.response.data)));
        }
      });
  };
};
// fetch all teams
// Delete team members
const fetchallTeams = () => ({
  type: GET_TEAMS_LIST,
});

const fetchallTeamsSuccess = (items) => ({
  type: GET_TEAMS_LIST_SUCCESS,
  payload: items,
});

const fetchallTeamsFail = (error) => ({
  type: GET_TEAMS_LIST_FAIL,
  payload: error,
});

export const fetchAllTeamsData = (body) => {
  return async (dispatch) => {
    dispatch(fetchallTeams());
    axios
      .patch("/team/all/list", body)
      .then(function (response) {
        dispatch(fetchallTeamsSuccess(response.data.data));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(fetchallTeamsFail(JSON.stringify(error.response.data)));
        }
      });
  };
};

// fetch sims logs

// Delete team members
const fetchSimLogs = () => ({
  type: GET_SIMS_LOG,
});

const fetchSimLogsSuccess = (items) => ({
  type: SIMS_LOG_SUCCESS,
  payload: items,
});

const fetchSimLogsFail = (error) => ({
  type: SIMS_LOG_FAIL,
  payload: error,
});

export const fethTeamSimLogs = (body) => {
  return async (dispatch) => {
    dispatch(fetchSimLogs());
    axios
      .patch("/team/get/simlogs", body)
      .then(function (response) {
        dispatch(fetchSimLogsSuccess(response.data.data));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(fetchSimLogsFail(JSON.stringify(error.response.data)));
        }
      });
  };
};

export const setTeamAnylsisData = (items) => ({
  type: SET_TEAM_ANYLSIS_DATA,
  payload: items,
});

export const setTeamMpsData = (items) => ({
  type: SET_TEAM_MPS_DATA,
  payload: items,
});

// Delete team members
const removeTeamMembers = () => ({
  type: REMOVE_TEAM_MEMBERS,
});

const removeTeamMembersSuccess = (items) => ({
  type: REMOVE_TEAM_MEMBERS_SUCC,
  payload: items,
});

const removeTeamMembersFail = (error) => ({
  type: REMOVE_TEAM_MEMBERS_FAIL,
  payload: error,
});

export const editTeamPlayers = (items) => {
  return async (dispatch) => {
    dispatch(removeTeamMembers());
    try {
      await removePlayersFromTeam(items.deletePlayerList);
      dispatch(removeTeamMembersSuccess("Players removed Successfully"));
    } catch (error) {
      dispatch(removeTeamMembersFail(JSON.stringify(error)));
    }
  };
};

const removePlayersFromTeam = (players) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/player/remove/teamplayer", {
        players,
        headers: { Authorization: "***" },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
export const getAllSimulationsMps = () => ({
  type: GET_ALL_SIMULATIONS_MPS,
  payload: null,
})

const getAllSimulationsMpsSuccess = (items) => ({
  type: GET_ALL_SIMULATIONS_MPS_SUCC,
  payload: items,
})

const getAllSimulationsMpsFailed = (message) => ({
  type: GET_ALL_SIMULATIONS_MPS_FAIL,
  payload: message,
})

export const fetchTeamSimulationsForMps = (body) => {
  return (dispatch) => {
    const { teamPlayers } = body;
    if (!teamPlayers) return true
    dispatch(getAllSimulationsMps())
    const filterPlayers = _.flattenDeep(
      teamPlayers.map((el) => el.players.map((el) => el._id))
    ).filter((el) => el);
    
    axios
      .patch("/player/teamSimulationsForMps", {
        teamPlayers: filterPlayers,
        shortBy: "created_at",
        shortByOrder: -1
      })
      .then((response) => response.data.data)
      .then((response) => {
        const { simulations } = response;
        const simulationsData = simulations || [];

        if (simulationsData) {
          dispatch(getAllSimulationsMpsSuccess(simulationsData));
        }
      })
      .catch((e) => {
        dispatch(getAllSimulationsMpsFailed(e.message))
      });

    // const fetches = teamPlayers.filter((el) => el.users && el.users.length > 0)
    //   .map((el) => {
    //     return axios.patch("/player/teamSimulationsForMps", {
    //       user_id: el.users[0]._id,
    //       shortBy: "created_at",
    //       shortByOrder: -1,
    //       itemPerPage: 10000000000
    //     })
    //     .then((response) => response.data.data)
    //     .then((response) => {
    //       const { simulations } = response
    //       return simulations || []
    //     })
    //     .catch((e) => {
    //       return null
    //     })
    //   })

    // Promise.all(fetches).then((data) => {
    //   const simulationsData = _.flattenDeep(data.reduce((el, ac) => [...el, ...ac]))
    //   dispatch(getAllSimulationsMpsSuccess(simulationsData));
    // });
  };
};

export const getTeamSimulations = () => ({
  type: GET_TEAM_SIMULATIONS,
  payload: []
})

export const getTeamSimulationsSuccess = items => ({
  type: GET_TEAM_SIMULATIONS_SUCCESS,
  payload: items,
})

const getTeamSimulationsFail = message => ({
  type: GET_TEAM_SIMULATIONS_FAIL,
  payload: message,
})

// export const fetchTeamSimulations = (body) => {
//   return dispatch => {

//   const {teamPlayers} = body;
//   var data = [];
//   var fetches = [];
//   for(let i = 0; i< teamPlayers.length;i++)
//   {
//     if(teamPlayers[i].users.length > 0)
//     {

//       const req = { user_id: teamPlayers[i].users[0]._id, shortBy: "created_at",
//       shortByOrder: -1, itemPerPage: 10000000000 };

//       var fetche = axios.patch("/player/simulations", req)
//       .then(function (response) {
//         data.push(response.data.data);
//       })
//       .catch(function (error) {
//         if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
//           dispatch(signOutUser())
//         } else {
//           //dispatch(getBrainMpsTimeFail(JSON.stringify(error.response.data)));
//         }
//       });

//       fetches.push(fetche);

//     }
//   }

//   Promise.all(fetches).then(function() {
//     dispatch(getTeamSimulationsSuccess(data));
//   });

//   }

// }

export const fetchTeamSimulations = (body) => {
  return dispatch => {
    dispatch(getTeamSimulations())
    const { teamPlayers } = body;
    if (!teamPlayers) return true
    const filterPlayers = _.flattenDeep(
      teamPlayers.map((el) => el.players.map((el) => el._id))
    ).filter((el) => el);
    
    axios.patch("/player/simulations-by-player-id", {
      teamPlayers: filterPlayers,
      shortBy: "created_at",
      shortByOrder: -1
    })
    .then((response) => response.data.data)
    .then((response) => {
      const { simulations } = response
      dispatch (getTeamSimulationsSuccess(simulations || []))
    })
    .catch((e) => {
      dispatch(getTeamSimulationsFail(e.message))
    })
  }
}

/**
 * Fetching  TEAM MEMBERS...
 */
const getTeamMembers = () => ({
  type: FETCH_TEAM_MEMBERS,
});

const getTeamMembersSuccess = (items) => ({
  type: FETCH_TEAM_MEMBERS_SUCCESS,
  payload: items,
});

const getTeamMembersfFail = (err) => ({
  type: FETCH_TEAM_MEMBERS_FAIL,
  payload: err,
});

export const fetchTeamMembers = (body) => {
  return (dispatch) => {
    dispatch(getTeamMembers());

    axios
      .patch("/team/get/members", body)
      .then(function (response) {
        dispatch(getTeamMembersSuccess(response.data.data));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(
            getTeamMembersfFail(JSON.stringify(error.response.data.message))
          );
        }
      });
  };
};
/* TEAM STATE SUMMARY DATA */

// GET ORG INS. LIST
export const getTeamSTatsSummary = (ins_id) => ({
  type: GET_TEAM_STAT_SUMMARY,
  payload: ins_id,
});

const getTeamSTatsSummarySuccess = (items) => ({
  type: GET_TEAM_STAT_SUMMARY_SUCCESS,
  payload: items,
});

const getTeamSTatsSummaryFail = (error) => ({
  type: GET_TEAM_STAT_SUMMARY_FAIL,
  payload: error,
});

export const fetchTeamStatsSummaryData = (body) => {
  return (dispatch) => {
    //dispatch(getTeamSTatsSummarySuccess(null));
    dispatch(getTeamSTatsSummary());
    const { isTest } = body;
    const testing = isTest || false;
    if (body.team_id === "6217d373603af234209f4909" && testing) {
      dispatch(getTeamSTatsSummarySuccess(summaryData));
    } else {
      axios
        .patch("/team/stats/summary", body)
        .then(function (response) {
          dispatch(getTeamSTatsSummarySuccess(response.data.data));
        })
        .catch(function (error) {
          //if (error.response === undefined) return dispatch(getTeamSTatsSummaryFail('Network Error.'))
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === "Invalid Token Input"
          ) {
            dispatch(signOutUser());
          } else {
            dispatch(
              getTeamSTatsSummaryFail(
                JSON.stringify(error.response.data.message)
              )
            );
          }
        });
    }
  };
};

const getTeamPlayersSuccessAction = (items) => ({
  type: GET_TEAM_PLAYERS_SUCCESS,
  payload: items,
});

const postParseSummary = (
  summaryData,
  playerData,
  gs,
  selectedRange,
  selectedMetric
) => {
  // let frontal_Lobe = [];
  let minimumPS = [[], [], [], [], [], [], []];
  let maximumPS = [[], [], [], [], [], [], []];
  let csdm_15 = [[], [], [], [], [], [], []];
  let csdm_5 = [[], [], [], [], [], [], []];
  let csdm_10 = [[], [], [], [], [], [], []];
  let MPS_95_DATA = [];
  let MPS_95_LIN_DATA = [];
  let MAX_LINEAR_EXLARATION = [];
  let MAX_ANGULAR_EXLARATION = [];
  let MPS_95_VEL_DATA = [];
  let MAX_ANGULAR_VEL_EXLARATION = [];
  let P_CSDM_15 = [];
  let P_MAX_S_POSITIONS = []; //for principal-max-strain-position
  let P_MIN_S_POSITIONS = []; // for principal-min-strain-poistion
  let TeamMpsEventValues = [];
  const pushMetricData = (insult) => {
    return new Promise((resolve, reject) => {
      if (insult["principal-max-strain"]) {
        TeamMpsEventValues.push({
          region: insult["principal-max-strain"]["brain-region"],
          value: insult["principal-max-strain"].value,
          event_id: insult["event_id"],
        });
        switch (insult["principal-max-strain"]["brain-region"]) {
          case "msc":
            maximumPS[6] = [
              {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...maximumPS[6],
            ];
            break;
          case "stem":
            maximumPS[5] = [
              {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...maximumPS[5],
            ];
            break;
          case "cerebellum":
            maximumPS[4] = [
              {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...maximumPS[4],
            ];
            break;
          case "temporal":
            maximumPS[3] = [
              {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...maximumPS[3],
            ];
            break;
          case "occipital":
            maximumPS[2] = [
              {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...maximumPS[2],
            ];
            break;
          case "parietal":
            maximumPS[1] = [
              {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...maximumPS[1],
            ];
            break;
          case "frontal":
            maximumPS[0] = [
              {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...maximumPS[0],
            ];
            break;
          default:
            break;
        }
      }
      if (insult["principal-min-strain"]) {
        switch (insult["principal-min-strain"]["brain-region"]) {
          case "msc":
            minimumPS[6] = [
              {
                x: insult["principal-min-strain"]["location"][0],
                y: insult["principal-min-strain"]["location"][1],
                z: insult["principal-min-strain"]["location"][2],
                value: insult["principal-min-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...minimumPS[6],
            ];
            break;
          case "stem":
            minimumPS[5] = [
              {
                x: insult["principal-min-strain"]["location"][0],
                y: insult["principal-min-strain"]["location"][1],
                z: insult["principal-min-strain"]["location"][2],
                value: insult["principal-min-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...minimumPS[5],
            ];
            break;
          case "cerebellum":
            minimumPS[4] = [
              {
                x: insult["principal-min-strain"]["location"][0],
                y: insult["principal-min-strain"]["location"][1],
                z: insult["principal-min-strain"]["location"][2],
                value: insult["principal-min-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...minimumPS[4],
            ];
            break;
          case "temporal":
            minimumPS[3] = [
              {
                x: insult["principal-min-strain"]["location"][0],
                y: insult["principal-min-strain"]["location"][1],
                z: insult["principal-min-strain"]["location"][2],
                value: insult["principal-min-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...minimumPS[3],
            ];
            break;
          case "occipital":
            minimumPS[2] = [
              {
                x: insult["principal-min-strain"]["location"][0],
                y: insult["principal-min-strain"]["location"][1],
                z: insult["principal-min-strain"]["location"][2],
                value: insult["principal-min-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...minimumPS[2],
            ];
            break;
          case "parietal":
            minimumPS[1] = [
              {
                x: insult["principal-min-strain"]["location"][0],
                y: insult["principal-min-strain"]["location"][1],
                z: insult["principal-min-strain"]["location"][2],
                value: insult["principal-min-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...minimumPS[1],
            ];
            break;
          case "frontal":
            minimumPS[0] = [
              {
                x: insult["principal-min-strain"]["location"][0],
                y: insult["principal-min-strain"]["location"][1],
                z: insult["principal-min-strain"]["location"][2],
                value: insult["principal-min-strain"]["value"],
                event_id: insult["event_id"],
              },
              ...minimumPS[0],
            ];
            break;
          default:
            break;
        }
      }
      if (insult["CSDM-15"]) {
        switch (insult["CSDM-15"]["brain-region"]) {
          case "msc":
            csdm_15[6] = [
              {
                x: insult["CSDM-15"]["location"][0],
                y: insult["CSDM-15"]["location"][1],
                z: insult["CSDM-15"]["location"][2],
                value: insult["CSDM-15"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_15[6],
            ];
            break;
          case "stem":
            csdm_15[5] = [
              {
                x: insult["CSDM-15"]["location"][0],
                y: insult["CSDM-15"]["location"][1],
                z: insult["CSDM-15"]["location"][2],
                value: insult["CSDM-15"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_15[5],
            ];
            break;
          case "cerebellum":
            csdm_15[4] = [
              {
                x: insult["CSDM-15"]["location"][0],
                y: insult["CSDM-15"]["location"][1],
                z: insult["CSDM-15"]["location"][2],
                value: insult["CSDM-15"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_15[4],
            ];
            break;
          case "temporal":
            csdm_15[3] = [
              {
                x: insult["CSDM-15"]["location"][0],
                y: insult["CSDM-15"]["location"][1],
                z: insult["CSDM-15"]["location"][2],
                value: insult["CSDM-15"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_15[3],
            ];
            break;
          case "occipital":
            csdm_15[2] = [
              {
                x: insult["CSDM-15"]["location"][0],
                y: insult["CSDM-15"]["location"][1],
                z: insult["CSDM-15"]["location"][2],
                value: insult["CSDM-15"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_15[2],
            ];
            break;
          case "parietal":
            csdm_15[1] = [
              {
                x: insult["CSDM-15"]["location"][0],
                y: insult["CSDM-15"]["location"][1],
                z: insult["CSDM-15"]["location"][2],
                value: insult["CSDM-15"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_15[1],
            ];
            break;
          case "frontal":
            csdm_15[0] = [
              {
                x: insult["CSDM-15"]["location"][0],
                y: insult["CSDM-15"]["location"][1],
                z: insult["CSDM-15"]["location"][2],
                value: insult["CSDM-15"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_15[0],
            ];
            break;
          default:
            break;
        }
      }
      if (insult["CSDM-5"]) {
        switch (insult["CSDM-5"]["brain-region"]) {
          case "msc":
            csdm_5[6] = [
              {
                x: insult["CSDM-5"]["location"][0],
                y: insult["CSDM-5"]["location"][1],
                z: insult["CSDM-5"]["location"][2],
                value: insult["CSDM-5"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_5[6],
            ];
            break;
          case "stem":
            csdm_5[5] = [
              {
                x: insult["CSDM-5"]["location"][0],
                y: insult["CSDM-5"]["location"][1],
                z: insult["CSDM-5"]["location"][2],
                value: insult["CSDM-5"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_5[5],
            ];
            break;
          case "cerebellum":
            csdm_5[4] = [
              {
                x: insult["CSDM-5"]["location"][0],
                y: insult["CSDM-5"]["location"][1],
                z: insult["CSDM-5"]["location"][2],
                value: insult["CSDM-5"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_5[4],
            ];
            break;
          case "temporal":
            csdm_5[3] = [
              {
                x: insult["CSDM-5"]["location"][0],
                y: insult["CSDM-5"]["location"][1],
                z: insult["CSDM-5"]["location"][2],
                value: insult["CSDM-5"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_5[3],
            ];
            break;
          case "occipital":
            csdm_5[2] = [
              {
                x: insult["CSDM-5"]["location"][0],
                y: insult["CSDM-5"]["location"][1],
                z: insult["CSDM-5"]["location"][2],
                value: insult["CSDM-5"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_5[2],
            ];
            break;
          case "parietal":
            csdm_5[1] = [
              {
                x: insult["CSDM-5"]["location"][0],
                y: insult["CSDM-5"]["location"][1],
                z: insult["CSDM-5"]["location"][2],
                value: insult["CSDM-5"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_5[1],
            ];
            break;
          case "frontal":
            csdm_5[0] = [
              {
                x: insult["CSDM-5"]["location"][0],
                y: insult["CSDM-5"]["location"][1],
                z: insult["CSDM-5"]["location"][2],
                value: insult["CSDM-5"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_5[0],
            ];
            break;
          default:
            break;
        }
      }
      if (insult["CSDM-10"]) {
        switch (insult["CSDM-10"]["brain-region"]) {
          case "msc":
            csdm_10[6] = [
              {
                x: insult["CSDM-10"]["location"][0],
                y: insult["CSDM-10"]["location"][1],
                z: insult["CSDM-10"]["location"][2],
                value: insult["CSDM-10"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_10[6],
            ];
            break;
          case "stem":
            csdm_10[5] = [
              {
                x: insult["CSDM-10"]["location"][0],
                y: insult["CSDM-10"]["location"][1],
                z: insult["CSDM-10"]["location"][2],
                value: insult["CSDM-10"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_10[5],
            ];
            break;
          case "cerebellum":
            csdm_10[4] = [
              {
                x: insult["CSDM-10"]["location"][0],
                y: insult["CSDM-10"]["location"][1],
                z: insult["CSDM-10"]["location"][2],
                value: insult["CSDM-10"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_10[4],
            ];
            break;
          case "temporal":
            csdm_10[3] = [
              {
                x: insult["CSDM-10"]["location"][0],
                y: insult["CSDM-10"]["location"][1],
                z: insult["CSDM-10"]["location"][2],
                value: insult["CSDM-10"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_10[3],
            ];
            break;
          case "occipital":
            csdm_10[2] = [
              {
                x: insult["CSDM-10"]["location"][0],
                y: insult["CSDM-10"]["location"][1],
                z: insult["CSDM-10"]["location"][2],
                value: insult["CSDM-10"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_10[2],
            ];
            break;
          case "parietal":
            csdm_10[1] = [
              {
                x: insult["CSDM-10"]["location"][0],
                y: insult["CSDM-10"]["location"][1],
                z: insult["CSDM-10"]["location"][2],
                value: insult["CSDM-10"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_10[1],
            ];
            break;
          case "frontal":
            csdm_10[0] = [
              {
                x: insult["CSDM-10"]["location"][0],
                y: insult["CSDM-10"]["location"][1],
                z: insult["CSDM-10"]["location"][2],
                value: insult["CSDM-10"]["value"],
                event_id: insult["event_id"],
              },
              ...csdm_10[0],
            ];
            break;
          default:
            break;
        }
      }
      resolve(null);
    });
  };
  summaryData.map((summaryData, summaryIndex) => {
    const { summaryOutPut } = summaryData;
    const { Insults } = summaryOutPut;
    if (!Insults) return;
    const profileData = playerData[summaryIndex];
    Insults.map(async (summary_data) => {
      if (summary_data["MPS-95"]) {
        if (
          summary_data["MPS-95"]["value"] &&
          summary_data["max-angular-acc-rads2"]
        ) {
          MPS_95_DATA.push(summary_data["MPS-95"]["value"]);
          MAX_ANGULAR_EXLARATION.push({
            val: summary_data["max-angular-acc-rads2"],
            event_id: summary_data["event_id"],
          });
        }
        if (
          summary_data["MPS-95"]["value"] &&
          summary_data["max-angular-vel-rads"]
        ) {
          MPS_95_VEL_DATA.push(summary_data["MPS-95"]["value"]);
          MAX_ANGULAR_VEL_EXLARATION.push({
            val: summary_data["max-angular-vel-rads"],
            event_id: summary_data["event_id"],
          });
        }
        if (
          summary_data["MPS-95"]["value"] &&
          summary_data["max-linear-acc-g"]
        ) {
          MPS_95_LIN_DATA.push(summary_data["MPS-95"]["value"]);
          MAX_LINEAR_EXLARATION.push({
            val: summary_data["max-linear-acc-g"],
            event_id: summary_data["event_id"],
          });
        }
      }
      if (summary_data["principal-max-strain"]) {
        if (summary_data["principal-max-strain"]["value"]) {
          P_MAX_S_POSITIONS.push({
            [profileData.player.position]:
              summary_data["principal-max-strain"]["value"],
          });
          // S_MAX_S_POSITIONS.push({ [sport]: summary_data['principal-max-strain']['value'] });
        }
      }

      if (summary_data["principal-min-strain"]) {
        if (summary_data["principal-min-strain"]["value"]) {
          P_MIN_S_POSITIONS.push({
            [profileData.player.position]:
              summary_data["principal-min-strain"]["value"],
          });
          // S_MIN_S_POSITIONS.push({ [sport]: summary_data['principal-min-strain']['value'] });
        }
      }

      if (summary_data["CSDM-15"]) {
        if (summary_data["CSDM-15"]["value"]) {
          P_CSDM_15.push({
            [profileData.player.position]: summary_data["CSDM-15"]["value"],
          });
          // S_CSDM_15.push({ [sport]: summary_data['CSDM-15']['value'] });
        }
      }
      // push mertix
      if (selectedRange === "less") {
        if (selectedMetric === "resultant-Angular-acceleration") {
          if (summary_data["max-angular-acc-rads2"] <= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "resultant-linear-acceleration") {
          if (summary_data["max-linear-acc-g"] <= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "principal-max-strain") {
          if (
            summary_data["principal-max-strain"] &&
            summary_data["principal-max-strain"].value <= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "principal-min-strain") {
          if (
            summary_data["principal-min-strain"] &&
            summary_data["principal-min-strain"].value <= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "csdm-max") {
          if (
            summary_data["csdm-max"] &&
            summary_data["csdm-max"].value <= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "axonal-strain-max") {
          if (
            summary_data["axonal-strain-max"] &&
            summary_data["axonal-strain-max"].value <= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "masXsr-15-max") {
          if (
            summary_data["masXsr-15-max"] &&
            summary_data["masXsr-15-max"].value <= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "CSDM-5") {
          if (summary_data["CSDM-5"] && summary_data["CSDM-5"].value <= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "CSDM-10") {
          if (summary_data["CSDM-10"] && summary_data["CSDM-10"].value <= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "CSDM-15") {
          if (summary_data["CSDM-15"] && summary_data["CSDM-15"].value <= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "MPS-95") {
          if (summary_data["MPS-95"] && summary_data["MPS-95"].value <= gs) {
            await pushMetricData(summary_data);
          }
        }

        //**
        //Fetch value is greater then.....
      } else {
        if (selectedMetric === "resultant-Angular-acceleration") {
          if (summary_data["max-angular-acc-rads2"] >= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "resultant-linear-acceleration") {
          if (summary_data["max-linear-acc-g"] >= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "principal-max-strain") {
          if (
            summary_data["principal-max-strain"] &&
            summary_data["principal-max-strain"].value >= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "principal-min-strain") {
          if (
            summary_data["principal-min-strain"] &&
            summary_data["principal-min-strain"].value >= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "csdm-max") {
          if (
            summary_data["csdm-max"] &&
            summary_data["csdm-max"].value >= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "axonal-strain-max") {
          if (
            summary_data["axonal-strain-max"] &&
            summary_data["axonal-strain-max"].value >= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "masXsr-15-max") {
          if (
            summary_data["masXsr-15-max"] &&
            summary_data["masXsr-15-max"].value >= gs
          ) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "CSDM-5") {
          if (summary_data["CSDM-5"] && summary_data["CSDM-5"].value >= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "CSDM-10") {
          if (summary_data["CSDM-10"] && summary_data["CSDM-10"].value >= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "MPS-95") {
          if (summary_data["MPS-95"] && summary_data["MPS-95"].value >= gs) {
            await pushMetricData(summary_data);
          }
        } else if (selectedMetric === "CSDM-15") {
          if (summary_data["CSDM-15"] && summary_data["CSDM-15"].value >= gs) {
            await pushMetricData(summary_data);
          }
        }
      }
    });
  });
  return {
    minimumPS,
    maximumPS,
    csdm_15,
    csdm_5,
    csdm_10,
    MPS_95_DATA,
    MPS_95_LIN_DATA,
    MAX_LINEAR_EXLARATION,
    MAX_ANGULAR_EXLARATION,
    MPS_95_VEL_DATA,
    MAX_ANGULAR_VEL_EXLARATION,
    P_CSDM_15,
    P_MAX_S_POSITIONS,
    P_MIN_S_POSITIONS,
    TeamMpsEventValues,
  };
};

const parseCompressData = () => {
  let minimumPS = [[], [], [], [], [], [], []];
  let maximumPS = [[], [], [], [], [], [], []];
  let csdm_15 = [[], [], [], [], [], [], []];
  let csdm_5 = [[], [], [], [], [], [], []];
  let csdm_10 = [[], [], [], [], [], [], []];
  let MPS_95_DATA = [];
  let MPS_95_LIN_DATA = [];
  let MAX_LINEAR_EXLARATION = [];
  let MAX_ANGULAR_EXLARATION = [];
  let MPS_95_VEL_DATA = [];
  let MAX_ANGULAR_VEL_EXLARATION = [];
  let P_CSDM_15 = [];
  let P_MAX_S_POSITIONS = []; //for principal-max-strain-position
  let P_MIN_S_POSITIONS = []; // for principal-min-strain-poistion
  let TeamMpsEventValues = [];
  const pushMetricData = (region, insult) => {
    return new Promise((resolve, reject) => {
      if (insult["principal-max-strain"]) {
        TeamMpsEventValues = [...TeamMpsEventValues, ...insult["principal-max-strain"].value.map((el, index) => {
          return {
            region: region,
            value: el,
            event_id: insult['event_id'][index]
          }
        })]
        switch (region) {
          case "msc":
            maximumPS[6] = [...insult["principal-max-strain"]["location"].map((el, index) => {
                return {
                  x: el[0],
                  y: el[1],
                  z: el[2],
                  value: insult["principal-max-strain"]["value"][index],
                  event_id: insult["event_id"][index],
                }
              }),
              ...maximumPS[6],
            ];
            /* {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              }, */
            break;
          case "stem":
            maximumPS[5] = [...insult["principal-max-strain"]["location"].map((el, index) => {
                return {
                  x: el[0],
                  y: el[1],
                  z: el[2],
                  value: insult["principal-max-strain"]["value"][index],
                  event_id: insult["event_id"][index],
                }
              }),
              ...maximumPS[5],
            ];
            /* {
              x: insult["principal-max-strain"]["location"][0],
              y: insult["principal-max-strain"]["location"][1],
              z: insult["principal-max-strain"]["location"][2],
              value: insult["principal-max-strain"]["value"],
              event_id: insult["event_id"],
            } */
            break;
          case "cerebellum":
            maximumPS[4] = [...insult["principal-max-strain"]["location"].map((el, index) => {
                return {
                  x: el[0],
                  y: el[1],
                  z: el[2],
                  value: insult["principal-max-strain"]["value"][index],
                  event_id: insult["event_id"][index],
                }
              }),
              ...maximumPS[4],
            ];
            /* {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              } */
            break;
          case "temporal":
            maximumPS[3] = [...insult["principal-max-strain"]["location"].map((el, index) => {
                return {
                  x: el[0],
                  y: el[1],
                  z: el[2],
                  value: insult["principal-max-strain"]["value"][index],
                  event_id: insult["event_id"][index],
                }
              }),
              ...maximumPS[3],
            ];
            /* {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              } */
            break;
          case "occipital":
            maximumPS[2] = [...insult["principal-max-strain"]["location"].map((el, index) => {
                return {
                  x: el[0],
                  y: el[1],
                  z: el[2],
                  value: insult["principal-max-strain"]["value"][index],
                  event_id: insult["event_id"][index],
                }
              }),
              ...maximumPS[2],
            ];
            /* {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              } */
            break;
          case "parietal":
            maximumPS[1] = [...insult["principal-max-strain"]["location"].map((el, index) => {
                return {
                  x: el[0],
                  y: el[1],
                  z: el[2],
                  value: insult["principal-max-strain"]["value"][index],
                  event_id: insult["event_id"][index],
                }
              }),
              ...maximumPS[1],
            ];
            /* {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              } */
            break;
          case "frontal":
            maximumPS[0] = [...insult["principal-max-strain"]["location"].map((el, index) => {
                return {
                  x: el[0],
                  y: el[1],
                  z: el[2],
                  value: insult["principal-max-strain"]["value"][index],
                  event_id: insult["event_id"][index],
                }
              }),
              ...maximumPS[0],
            ];
            /* {
                x: insult["principal-max-strain"]["location"][0],
                y: insult["principal-max-strain"]["location"][1],
                z: insult["principal-max-strain"]["location"][2],
                value: insult["principal-max-strain"]["value"],
                event_id: insult["event_id"],
              } */
            break;
          default:
            break;
        }
      }
      resolve(null);
    });
  };
  Object.keys(compressedData).map((regions) => {
    if (
      compressedData[regions]["MPS-95-value"] &&
      compressedData[regions]["max-angular-acc-rads2"]
    ) {
      MPS_95_DATA = [...MPS_95_DATA, ...compressedData[regions]["MPS-95-value"]];
      MAX_ANGULAR_EXLARATION = [...MAX_ANGULAR_EXLARATION, ...compressedData[regions]["max-angular-acc-rads2"].map((el, index) => {
        return {
          val: el,
          event_id: compressedData[regions]["event_id"][index],
        }
      })]
    }
    if (
      compressedData[regions]["MPS-95-value"] &&
      compressedData[regions]["max-linear-acc-g"]
    ) {
      MPS_95_LIN_DATA = [...MPS_95_LIN_DATA, ...compressedData[regions]["MPS-95-value"]];
      MAX_LINEAR_EXLARATION = [...MAX_ANGULAR_EXLARATION, ...compressedData[regions]["max-linear-acc-g"].map((el, index) => {
        return {
          val: el,
          event_id: compressedData[regions]["event_id"][index],
        }
      })]
    }
    if (compressedData[regions]["principal-max-strain"]) {
      if (compressedData[regions]["principal-max-strain"]["value"]) {
        P_MAX_S_POSITIONS = [...P_MAX_S_POSITIONS, ...compressedData[regions]["principal-max-strain"]["value"].map((el) => {
          const playerObject = {}
          playerObject['unknown'] = el
          return playerObject
        })];
        // S_MAX_S_POSITIONS.push({ [sport]: compressedData[regions]['principal-max-strain']['value'] });
      }
    }

    if (compressedData[regions]["principal-min-strain"]) {
      if (compressedData[regions]["principal-min-strain"]["value"]) {
        P_MIN_S_POSITIONS = [...P_MIN_S_POSITIONS, ...compressedData[regions]["principal-min-strain"]["value"].map((el) => {
          const playerObject = {}
          playerObject['unknown'] = el
          return playerObject
        })];
        // S_MIN_S_POSITIONS.push({ [sport]: compressedData[regions]['principal-min-strain']['value'] });
      }
    }

    if (compressedData[regions]["CSDM-15-value"]) {
      P_CSDM_15 = [...P_CSDM_15, ...compressedData[regions]["CSDM-15-value"].map((el) => {
        const playerObject = {}
        playerObject['unknown'] = el
        return playerObject
      })];
    }
    pushMetricData(regions, compressedData[regions])
  })
  return {
    minimumPS,
    maximumPS,
    csdm_15,
    csdm_5,
    csdm_10,
    MPS_95_DATA,
    MPS_95_LIN_DATA,
    MAX_LINEAR_EXLARATION,
    MAX_ANGULAR_EXLARATION,
    MPS_95_VEL_DATA,
    MAX_ANGULAR_VEL_EXLARATION,
    P_CSDM_15,
    P_MAX_S_POSITIONS,
    P_MIN_S_POSITIONS,
    TeamMpsEventValues,
  }
}

export const fetchTeamStatsSummaryDataAction = ({ team_id }) => {
  return (dispatch, getState) => {
    // dispatch(getTeamSTatsSummary())
    const { Teams } = getState();
    Promise.all([
      axios({
        url: "/team/stats/summary",
        method: "PATCH",
        data: {
          team_id,
        },
      }).then((res) => res.data.data),
      axios({
        url: "/team/details/byId",
        method: "PATCH",
        data: {
          team_id,
        },
      }).then((res) => res.data.data),
      axios({
        url: "/player/fetchListByTeamOptim",
        method: "PATCH",
        data: {
          team_id,
        },
      }).then((res) => res.data.data),
    ])
      .then((result) => {
        const [summaryData, team_details, teamplayers] = result;
        dispatch(teamByIdSuccess(team_details));
        dispatch(getTeamPlayersSuccessAction(teamplayers));
        const { players } = teamplayers;
        const teamSimulation = _.flattenDeep(
          players.map((innerEl) => {
            return innerEl.sensor_details.map((el) => {
              return {
                ...el,
                users: innerEl.users,
              };
            });
          })
        ).filter((el) => el);
        dispatch(getTeamSimulationsSuccess(teamSimulation));
        dispatch(getTeamSTatsSummarySuccess(summaryData));
        const {
          MPS_95_DATA,
          MAX_ANGULAR_EXLARATION,
          MPS_95_VEL_DATA,
          MPS_95_LIN_DATA,
          MAX_LINEAR_EXLARATION,
          MAX_ANGULAR_VEL_EXLARATION,
          P_CSDM_15,
          P_MAX_S_POSITIONS,
          P_MIN_S_POSITIONS,
          minimumPS,
          maximumPS,
          csdm_15,
          csdm_5,
          csdm_10
        } =postParseSummary(
          summaryData?.statSummaryData,
          summaryData?.playerProfile,
          Teams.teamSelectedGs,
          Teams.teamSelectedRange,
          Teams.teamSelectedMatrix
        );
        /* parseCompressData() */
        // console.log('-----anaylisus-----')
        /* console.log({
          MPS_95_DATA,
          MAX_ANGULAR_EXLARATION,
          MPS_95_VEL_DATA,
          MPS_95_LIN_DATA,
          MAX_LINEAR_EXLARATION,
          MAX_ANGULAR_VEL_EXLARATION,
          P_CSDM_15,
          P_MAX_S_POSITIONS,
          P_MIN_S_POSITIONS,
          minimumPS,
          maximumPS,
          csdm_15,
          csdm_5,
          csdm_10
        }) */
        // console.log('-----analysis------')
        dispatch(setTeamAnylsisData({
          MPS_95_DATA,
          MAX_ANGULAR_EXLARATION,
          MPS_95_VEL_DATA,
          MPS_95_LIN_DATA,
          MAX_LINEAR_EXLARATION,
          MAX_ANGULAR_VEL_EXLARATION,
          P_CSDM_15,
          P_MAX_S_POSITIONS,
          P_MIN_S_POSITIONS,
          minimumPS,
          maximumPS,
          csdm_15,
          csdm_5,
          csdm_10
        }))
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

/* change admin to player*/
export const changeAdminToPlayer = (item) => ({
  type: CHANGE_ADMIN_TO_PLAYER,
  payload: item,
});
/* end */
/* Edit functionality for players */

// Delete Players...
export const deletePlayer = (item) => ({
  type: DELETE_PLAYERS,
  payload: item,
});

// status Players...
export const updateStatusPlayer = (item) => ({
  type: UPDATE_PLAYER_STATUS,
  payload: item,
});

// status All Players...
export const updateStatusAllPlayer = (item) => ({
  type: UPDATE_ALL_PLAYER_STATUS,
  payload: item,
});

// sensor id Players...
export const updatePlayerSensorId = (item) => ({
  type: UPDATE_SENSOR_ID,
  payload: item,
});

// edit -
const editPayers = () => ({
  type: EDIT_PLAYER,
});

const editPayersState = (state) => ({
  type: EDIT_PLAYER_STATE,
  payload: state,
});

const editPayersFail = (msg) => ({
  type: EDIT_PLAYER_FAIL,
  payload: msg,
});

const editPayersSucc = (msg) => ({
  type: EDIT_PLAYER_SUCCESS,
  payload: msg,
});

// Reseting all edit state ...
export const emptyPayersEditingState = () => ({
  type: EPMTY_EDITING_PLAYERS_DATA,
});

// Make edit api calls ...
export const editPlayersData = (items) => {
  return async (dispatch) => {
    dispatch(editPayers());

    try {
      //.team status id
      if (items.playerStatusList && items.playerStatusList.length > 0) {
        dispatch(editPayersState("Updating players team status..."));
        await updatedPlayerStatus(items.playerStatusList);
        dispatch(editPayersState("Players status updated"));
      }
      //.sensor id
      if (items.playerSensorIdList && items.playerSensorIdList.length > 0) {
        dispatch(editPayersState("Updating players sensor IDs..."));
        await updateSensorIds(items.playerSensorIdList);
        dispatch(editPayersState("Sensor IDs updated"));
      }
      //.delete
      if (items.deletePlayerList && items.deletePlayerList.length > 0) {
        dispatch(editPayersState("Deleting players..."));
        await deletePlayersTodb(items.deletePlayerList);
        dispatch(editPayersState("Players Deleted successfully"));
      }
      if (
        items.changeAdminToPlayerList &&
        items.changeAdminToPlayerList.length > 0
      ) {
        dispatch(editPayersState("changing admin to players..."));
        await changeAdminToPlayerTodb(items.changeAdminToPlayerList);
        dispatch(editPayersState("Admin change to player successfully"));
      }
      return dispatch(editPayersSucc("Successfully edited players."));
    } catch (error) {
      if (error.response === undefined)
        return dispatch(editPayersFail("Network Error."));
      if (
        error.response.data &&
        error.response.data.message === "Invalid Token Input"
      ) {
        return dispatch(signOutUser());
      }
      return dispatch(editPayersFail(JSON.stringify(error.response)));
    }
  };
};
// Make call to convert admin to player ...
export const editAdminToPlayersData = (items) => {
  return async (dispatch) => {
    dispatch(editPayers());
    try {
      if (
        items.changeAdminToPlayerList &&
        items.changeAdminToPlayerList.length > 0
      ) {
        dispatch(editPayersState("changing admin to players..."));
        await changeAdminToPlayerTodb(items.changeAdminToPlayerList);
        dispatch(editPayersState("Admin change to player successfully"));
      }
      return dispatch(editPayersSucc("Admin change to player successfully"));
    } catch (error) {
      if (error.response === undefined)
        return dispatch(editPayersFail("Network Error."));
      if (
        error.response.data &&
        error.response.data.message === "Invalid Token Input"
      ) {
        return dispatch(signOutUser());
      }
      return dispatch(editPayersFail(JSON.stringify(error.response)));
    }
  };
};

const updateSensorIds = (players) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/player/update/sensorId", { players })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

const updatedPlayerStatus = (players) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/player/update/status", { players })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

const deletePlayersTodb = (players) => {
  return new Promise((resolve, reject) => {
    axios
      .delete("/player/deletes", {
        data: { players },
        headers: { Authorization: "***" },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
const changeAdminToPlayerTodb = (user_id) => {
  return new Promise((resolve, reject) => {
    axios
      .patch("/admin/changetoplayer", { user_id })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

/**
 * Edit functionality start here..
 * @returns
 */

// add ...
export const addTeam = (ins) => ({
  type: ADD_TEAM,
  payload: ins,
});
// Rename ...
export const renameTeam = (item) => ({
  type: RENAME_TEAM,
  payload: item,
});
// Delete ...
export const deleteTeam = (item) => ({
  type: DELETS_TEAM,
  payload: item,
});
//merge ...
export const margeTeam = (item) => ({
  type: MERGE_TEAM,
  payload: item,
});

export const updateMeshSubType = (type) => ({
  type: MESH_SUBTYPE,
  payload: type
})

// edit -
const editTeam = () => ({
  type: EDIT_TEAM,
});

const editState = (state) => ({
  type: EDIT_TEAM_STATE,
  payload: state,
});

const editTeamFail = (msg) => ({
  type: EDIT_TEAM_FAIL,
  payload: msg,
});

const editTeamSuccess = (msg) => ({
  type: EDIT_TEAM_SUCCESS,
  payload: msg,
});

// Reseting all edit state ...

export const emptyTeamEditingState = () => ({
  type: EPMTY_EDITING_DATA,
});

// Make edit api calls ...
export const editTeams = (items) => {
  return async (dispatch) => {
    dispatch(editTeam());
    try {
      //.add
      /* if (items.addTeamName) {
        dispatch(editState("Adding Team..."));
        await addTeamTodb(
          items.addTeamName,
          items.institution_id,
          items.organization_id
        );
        dispatch(editState("Team added successfully."));
      } */
      if (items.addList && items.addList.length > 0) {
        dispatch(editState('Adding Team...'));
        const addPromises = items.addList.map((el) => addTeamTodb(el, items.institution_id, items.organization_id))
        await Promise.all(addPromises)
        dispatch(editState('Team added successfully.'));
      }
      //.rename
      if (items.renameTeamList && items.renameTeamList.length > 0) {
        dispatch(editState("Updating Teams name..."));
        await renameTeamTodb(items.renameTeamList);
        dispatch(editState("Institutions Teams updated."));
      }

      if (items.deleteTeamList && items.deleteTeamList.length > 0) {
        dispatch(editState("Deleteing Teams..."));
        await deleteTeamTodb(items.deleteTeamList);
        dispatch(editState("Teams Deleted."));
      }
      // // // merge
      if (items.mergeTeamList && items.mergeTeamList.length) {
        dispatch(editState("merging Teams..."));
        await deleteTeamTodb(items.mergeTeamList);
        dispatch(editState("Teams merged."));
      }
      dispatch(editTeamSuccess("Successfully edited Teams."));
    } catch (error) {
      if (error.response === undefined)
        dispatch(editTeamFail("Network Error."));
      if (
        error.response.data &&
        error.response.data.message === "Invalid Token Input"
      ) {
        dispatch(signOutUser());
      }
      // dispatch(editOrgFail(err.response));
    }
  };
};

const deleteTeamTodb = (teams) => {
  return new Promise((resolve, reject) => {
    axios
      .delete("/team/delete", {
        data: { teams },
        headers: { Authorization: "***" },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

const renameTeamTodb = (teams) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/team/rename", { teams })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const addTeamTodb = (name, institution_id, organization_id) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/team/add", {
        name: name,
        institution_id,
        organization_id,
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

// GET ORG INS. LIST
const getInsTeamsList = (ins_id) => ({
  type: GET_INS_TEAMS_LIST,
  payload: ins_id,
});

const getInsTeamsListSuccess = (items) => ({
  type: GET_INS_TEAMS_LIST_SUCCESS,
  payload: items,
});

const getInsTeamsListFail = (error) => ({
  type: GET_INS_TEAMS_LIST_FAIL,
  payload: error,
});

const getTeamsByInsList = (ins_id) => ({
  type: GET_TEAMS_INS_LIST,
  payload: ins_id,
});

const getTeamsByInsListSuccess = (items) => ({
  type: GET_TEAMS_INS_LIST_SUCCESS,
  payload: items,
});

const getTeamsByInsListFail = (error) => ({
  type: GET_TEAMS_INS_LIST_FAIL,
  payload: error,
});

export const getInstitutionsTeams = (ins_id) => {
  return dispatch => {
    dispatch(getInsTeamsList(ins_id));
    axios.patch("/team/getInsTeams", { ins_id })
      .then(function (response) {
        dispatch(getInsTeamsListSuccess(response.data.data || []));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getInsTeamsListFail(JSON.stringify(error.response.data.message)));
        }
      });
  };
};
export const getTeamsByIns = (ins_id) => {
  return dispatch => {
    dispatch(getTeamsByInsList(ins_id));
    axios.patch("/team/getTeamsByInsId", { ins_id })
      .then(function (response) {
        dispatch(getTeamsByInsListSuccess(response.data.data || []));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getTeamsByInsListFail(JSON.stringify(error.response.data.message)));
        }
      });
  };
};

/**
 * Fetching Single team details by id...
 */
const teamById = () => ({
  type: TEAM_BYID,
});

const teamByIdSuccess = (items) => ({
  type: TEAM_BYID_SUCCESS,
  payload: items,
});

const teamByIdFail = (err) => ({
  type: TEAM_BYID_FAIL,
  payload: err,
});

export const getTeamDeatilsById = (body) => {
  return (dispatch) => {
    dispatch(teamById());
    axios
      .patch("/team/details/byId", body)
      .then(function (response) {
        dispatch(teamByIdSuccess(response.data.data));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(teamByIdFail(JSON.stringify(error.response.data.message)));
        }
      });
  };
};
export const getSingleTeam = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .patch("/team/single/Teams", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
/* delete undefined admin memeber or player**/
const deleteUndefinedMemState = (msg) => ({
  type: DELETE_UNDEFINED_MEM_STATE,
  payload: msg,
});

const deleteUndefinedMemloader = () => ({
  type: DELETE_UNDEFINED_MEM_LOADER,
});

const deleteUndefinedMemFail = (msg) => ({
  type: DELETE_UNDEFINED_MEM_FAIL,
  payload: msg,
});
export const deleteUndefinedMemberData = (items) => {
  return async (dispatch) => {
    dispatch(deleteUndefinedMemloader());
    try {
      if (
        items.deleteUndefinedMember &&
        items.deleteUndefinedMember.length > 0
      ) {
        dispatch(deleteUndefinedMemState("Undefined member deleting..."));
        await deleteUndefinedMemberFromdb(items.deleteUndefinedMember);
        dispatch(
          deleteUndefinedMemState("Undefined member deleted successfully")
        );
      }
    } catch (error) {
      if (error.response === undefined)
        return dispatch(deleteUndefinedMemFail("Network Error."));
      if (
        error.response.data &&
        error.response.data.message === "Invalid Token Input"
      ) {
        return dispatch(signOutUser());
      }
      return dispatch(deleteUndefinedMemFail(JSON.stringify(error.response)));
    }
  };
};
const deleteUndefinedMemberFromdb = (user_id) => {
  return new Promise((resolve, reject) => {
    axios
      .patch("/admin/deleteUndefinedMember", { user_id })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

/**
 * Fetching organization staff...
 */
const teamStaff = (state) => ({
  type: TEAM_STAFF,
});

const teamStaffSuccess = (items) => ({
  type: TEAM_STAFF_SUCCESS,
  payload: items,
});

const teamStaffFail = (err) => ({
  type: TEAM_STAFF_FAIL,
  payload: err,
});

export const getTeamStaffList = (body) => {
  return (dispatch) => {
    dispatch(teamStaff());

    axios
      .patch("/team/getStaffList", body)
      .then(function (response) {
        dispatch(teamStaffSuccess(response.data.data));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(teamStaffFail(JSON.stringify(error.response.data.message)));
        }
      });
  };
};
export const getMLplatformfiles = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/team/getMLplatformfiles", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          reject("Invalid Token");
        } else {
          reject("Somthing wrong");
        }
      });
  });
};
export const TeamStateScatterChart = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/team/TeamStateScatterChart", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          reject("Invalid Token");
        } else {
          reject("Somthing wrong");
        }
      });
  });
};
export const TeamStateScatterChart_v1 = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/team/TeamStateScatterChart_v1", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          reject("Invalid Token");
        } else {
          reject("Somthing wrong");
        }
      });
  });
};

export const getMLstatus = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/team/getMLstatus", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          reject("Invalid Token");
        } else {
          reject("Somthing wrong");
        }
      });
  });
};

export const getteamplayerspositions = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/team/getteamplayerspositions", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data.message === "Invalid Token Input"
        ) {
          reject("Invalid Token");
        } else {
          reject("Somthing wrong");
        }
      });
  });
};

// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER,
});

/**
 * Update team jobs status ...
 */
export const updateTeamJobsStatus = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/team/updateStatus", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        alert("Failed to upload! Somthing went wrong, please try again.");
      });
  });
};

/**
 * Calling upload jobs api ...
 */
export const uploadSingleJobFile = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/team/upload/singlJob", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const uploadSingleJobFile_v2 = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_simUploadApiEndpoint, body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const uploadSingleJobFile_v3 = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/team/upload/singlb3Job", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}

export const updateMeshType = (type) => ({
  type: MESH_TYPE,
  payload: type,
});

export const updateInstanceType = (type) => ({
  type: INSTANCE_TYPE,
  payload: type,
});

export const excludeExistingSimulation = (type) => ({
  type: EXCLUDE_SIMULATIONS,
  payload: type,
});

export const jobUploadSuccess = (msg) => {
  return (dispatch) => {
    dispatch(UploadSuccess(msg));
    setTimeout(() => dispatch(UploadSuccess(null)), 8000);
  };
};

export const UploadSuccess = (msg) => ({
  type: JOBS_UPLOAD_SUCCESS,
  payload: msg,
});
/* TEAM STAFF DELETE**/
const deleteTeamStaffState = (msg) => ({
  type: DELETE_TEAM_STAFF_STATE,
  payload: msg,
});

const deleteTeamStaffloader = () => ({
  type: DELETE_TEAM_STAFF_LOADER,
});
const deleteTeamStaffAfterSucc = () => ({
  type: DELETE_TEAM_STAFF_AFTER_SUCC,
});

const deleteTeamStaffFail = (msg) => ({
  type: DELETE_TEAM_STAFF_FAIL,
  payload: msg,
});
export const deleteTeamStaffData = (items) => {
  return async (dispatch) => {
    dispatch(deleteTeamStaffloader());
    try {
      if (items.deleteTeamStaff && items.deleteTeamStaff.length > 0) {
        dispatch(deleteTeamStaffState("Team staff deleting..."));
        await deleteTeamStaffFromdb(items.deleteTeamStaff);
        dispatch(deleteTeamStaffState("Team staff deleted successfully"));
        setTimeout(() => dispatch(deleteTeamStaffAfterSucc()), 1000);
      }
    } catch (error) {
      if (error.response === undefined)
        return dispatch(deleteTeamStaffFail("Network Error."));
      if (
        error.response.data &&
        error.response.data.message === "Invalid Token Input"
      ) {
        return dispatch(signOutUser());
      }

      return dispatch(deleteTeamStaffFail(JSON.stringify(error.response)));
    }
  };
};
const deleteTeamStaffFromdb = (user_id) => {
  return new Promise((resolve, reject) => {
    axios
      .patch("/team/deleteTeamStaff", { user_id })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
// GET TEAM BRAIN IMAGE
const getTeamImage = () => ({
  type: GET_TEAM_BRAIN_IMAGE,
});

const getTeamImageSuccess = (items) => ({
  type: GET_TEAM_BRAIN_IMAGE_SUCCESS,
  payload: items,
});

const getTeamImageFail = (error) => ({
  type: GET_TEAM_BRAIN_IMAGE_FAIL,
  payload: error,
});

export const getBrainImage = (team_id) => {
  return (dispatch) => {
    dispatch(getTeamImage());

    axios
      .get(`/team/getTeamBrainImage/${team_id}`)
      .then(function (response) {
        dispatch(getTeamImageSuccess(response.data.data.brainImages));
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(getTeamImageFail(JSON.stringify(error.response.data)));
        }
      });
  };
};


// update pressure & strain dashboard
export const  updateLoadingPressureStatus = (payload) => ({
  type: UPDATE_LOADING_STATUS,
  payload 
})
// new analysis data for strain============
const getAllPlayerAnalysisData = () => ({
  type: GET_ALL_PLAYER_ANALYSIS_DATA
})

const getAllPlayerAnalysisDataSuccess = (payload) => ({
  type: GET_ALL_PLAYER_ANALYSIS_DATA_SUCCESS,
  payload
})

const getAllPlayerAnalysisDataFail = (payload) => ({
  type: GET_ALL_PLAYER_ANALYSIS_DATA_FAIL,
  payload
})
export const fetchTeamTop20Events = (data) => {
  let id = data.id;
  let pressure_dashboard = data.pressure_dashboard ? data.pressure_dashboard : false;
  return dispatch => {
    //dispatch(fetchTeamTop20EventsData())
    dispatch(getAllPlayerAnalysisData())
    
    axios.get(`/team/getTeamMpsData/${id}/${data.type}/${pressure_dashboard}`)
      .then((response) => response.data.data)
      .then((response) => {
        // dispatch(fetchTeamTop20EventsSuccess(response));
        dispatch(getAllPlayerAnalysisDataSuccess(response));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getAllPlayerAnalysisDataFail(JSON.stringify(error.response.data)));
        }
      });
  }
}
export const fetchAllPlayerOptimizeAnalysis = (data) => {
  let id = data.id;
  let pressure_dashboard = data.pressure_dashboard ? data.pressure_dashboard : false;
  return dispatch => {
    dispatch(getAllPlayerAnalysisData())
    axios.get(`/team/allsummary-preview/${id}/${data.type}/${pressure_dashboard}`)
      .then((response) => response.data.data)
      .then((response) => {
        dispatch(getAllPlayerAnalysisDataSuccess(response));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getAllPlayerAnalysisDataFail(JSON.stringify(error.response.data)));
        }
      });
  }
}

// new analysis data for ml
const getAllPlayerMlAnalysisData = () => ({
  type: GET_ALL_PLAYER_ML_ANALYSIS_DATA
})

const getAllPlayerMlAnalysisDataSuccess = (payload) => ({
  type: GET_ALL_PLAYER_ML_ANALYSIS_DATA_SUCCESS,
  payload
})

const getAllPlayerMlAnalysisDataFail = (payload) => ({
  type: GET_ALL_PLAYER_ML_ANALYSIS_DATA_FAIL,
  payload
})

export const fetchAllPlayerOptimizeMlAnalysis = (data) => {
  let id = data.id;
  let pressure_dashboard = data.pressure_dashboard ? data.pressure_dashboard : false;
  return dispatch => {
    dispatch(getAllPlayerMlAnalysisData())
    axios.get(`/team/allMlsummary-preview/${id}/${data.type}/${pressure_dashboard}`)
      .then((response) => response.data.data)
      .then((response) => {
        dispatch(getAllPlayerMlAnalysisDataSuccess(response));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getAllPlayerMlAnalysisDataFail(JSON.stringify(error.response.data)));
        }
      });
  }
}
//
// new analysis data for pressure
const getAllPlayerPressureAnalysisData = () => ({
  type: GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA
})

const getAllPlayerPressureAnalysisDataSuccess = (payload) => ({
  type: GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS,
  payload
})

const getAllPlayerPressureAnalysisDataFail = (payload) => ({
  type: GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL,
  payload
})

export const fetchAllPlayerOptimizePressureAnalysis = (data) => {
  let id = data.id;
  let pressure_dashboard = data.pressure_dashboard ? data.pressure_dashboard : false;
  return dispatch => {
    dispatch(getAllPlayerPressureAnalysisData())
    axios.get(`/team/allpressuresummary-preview/${id}/${data.type}/${pressure_dashboard}`)
      .then((response) => response.data.data)
      .then((response) => {
        dispatch(getAllPlayerPressureAnalysisDataSuccess(response));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getAllPlayerPressureAnalysisDataFail(JSON.stringify(error.response.data)));
        }
      });
  }
}

const getTeamSensorDevices = () => ({
  type: GET_TEAM_SENSOR_DEVICES
})

const getTeamSensorDeviceSuccess = (data) => ({
  type: GET_TEAM_SENSOR_DEVICES_REQUEST_SUCCESS,
  payload: data
})

const getTeamSensorDeviceFailed = (issue) => ({
  type: GET_TEAM_SENSOR_DEVICES_REQUEST_FAILED,
  payload: issue
})

export const fetchTeamSensorDevices = (body) => {
  return dispatch => {
    dispatch(getTeamSensorDevices());
    let requestUrl = '/team/sensor-devices?'
    const params = new URLSearchParams(body);
    requestUrl += params.toString();
    // console.log('Query string : ', requestUrl)
    axios.get(requestUrl)
      .then((response) => response.data.data)
      .then((response) => {
        dispatch(getTeamSensorDeviceSuccess(response))
      })
      .catch((e) => {
        const { response } = e
        if (response){
          const { data } = response
          if (data) {
            const { message } = data
            if (message === "Invalid Token Input") {
              dispatch(signOutUser())
            } else {
              dispatch(getTeamSensorDeviceFailed(message))
            }
          }
        } else {
          dispatch(getTeamSensorDeviceFailed('Something went wrong.'))
        }
      })
  }
}

/*END HERE**/

//end ...
