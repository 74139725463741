import React, {
  Component,
  createRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import DetailsCards from "./DetailsCards";
import StepFormItems from "./StepFormItems";
import ColorCustomizer from "./ColorCustomizer";
import CheckoutPop from "../../../components/PopUp/checkout.js";
import StripeCardPopup from "../../../components/PopUp/stripeCard";
import "./index.scss";

import MapChart from "./MapChart.js";

const first_name_regex = /^[A-Za-z]+(?:[-'][A-Za-z]+)*$/;
const last_name_regex = /^[A-Za-z]+(?:[-' ][A-Za-z]+)*$/;

const shippigFormValidation = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Please enter valid first name.")
    .max(50, "Please enter valid first name.")
    .matches(first_name_regex, "Please enter valid first name.")
    .required("This field is required."),
  last_name: Yup.string()
    .matches(last_name_regex, "Please enter valid last name.")
    .min(2, "Please enter valid last name.")
    .max(50, "Please enter valid last name.")
    .required("This field is required."),
  email_address: Yup.string()
    .email("Please email a valid email.")
    .required("This field is required."),
  address_line_1: Yup.string().required("This field is required."),
  address_line_2: Yup.string(), // Optional field
  state_province_region: Yup.string().required("This field is required."),
  zip_postal_code: Yup.string()
    .matches(/^[A-Za-z0-9\s\-]+$/, "Invalid ZIP/Postal Code")
    .required("This field is required."),
  country: Yup.string().required("This field is required."),
});

const address_selection = [
  {
    title: "Option 1",
    description: "Travel to one of our digital teeth scanning partners.",
    value: "visit",
  },
  {
    title: "Option 2",
    description:
      "Get a in-home teeth digital scan by one of our trained professionals. We will travel to you.",
    value: "on-site",
  },
  {
    title: "Option 3",
    description: "Get a at-home teeth impression kit mailed to you.",
    value: "impression-kit",
  },
];

const ShippingAddressForm = forwardRef((props, ref) => {
  const formikRef = createRef();

  const {
    handleFormSubmit = (values) => true,
    perisistValue = {
      first_name: "",
      last_name: "",
      email_address: "",
      address_line_1: "",
      address_line_2: "",
      state_province_region: "",
      zip_postal_code: "",
      country: "",
      delivery_address: "",
    },
    onInputChange = (v) => true,
  } = props;

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      formikRef.current.submitForm();
    },
  }));

  return (
    <Row className="step_last">
      <Col xs={12}>
        <h3 className="mb-5">Shipping Address</h3>
      </Col>
      <Col>
        <Formik
          initialValues={perisistValue}
          // validate={handleFormValidations}
          validationSchema={shippigFormValidation}
          onSubmit={handleFormSubmit}
          innerRef={formikRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup>
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder="First Name"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        onInputChange({
                          field_name: name,
                          value,
                        });
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      invalid={errors.first_name && touched.first_name}
                      value={values.first_name}
                    />
                    {errors.first_name && touched.first_name ? (
                      <FormFeedback>{errors.first_name}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup>
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      invalid={errors.last_name && touched.last_name}
                    />
                    {errors.last_name && touched.last_name ? (
                      <FormFeedback>{errors.last_name}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>Email Address</Label>
                    <Input
                      type="email"
                      name="email_address"
                      id="email_address"
                      placeholder="Email Address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email_address}
                      invalid={errors.email_address && touched.email_address}
                    />
                    {errors.email_address && touched.email_address ? (
                      <FormFeedback>{errors.email_address}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>Address Line 1</Label>
                    <Input
                      type="text"
                      name="address_line_1"
                      id="address_line_1"
                      placeholder="Address Line 1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address_line_1}
                      invalid={errors.address_line_1 && touched.address_line_1}
                    />
                    {errors.address_line_1 && touched.address_line_1 ? (
                      <FormFeedback>{errors.address_line_1}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>Address Line 2</Label>
                    <Input
                      type="text"
                      name="address_line_2"
                      id="address_line_2"
                      placeholder="Address Line 2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address_line_2}
                      invalid={errors.address_line_2 && touched.address_line_2}
                    />
                    {errors.address_line_2 && touched.address_line_2 ? (
                      <FormFeedback>{errors.address_line_2}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Label>State / Province / Region</Label>
                    <Input
                      type="text"
                      name="state_province_region"
                      id="state_province_region"
                      placeholder="State / Province / Region"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state_province_region}
                      invalid={
                        errors.state_province_region &&
                        touched.state_province_region
                      }
                    />
                    {errors.state_province_region &&
                    touched.state_province_region ? (
                      <FormFeedback>
                        {errors.state_province_region}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Label>Zip / Postal Code</Label>
                    <Input
                      type="text"
                      name="zip_postal_code"
                      id="zip_postal_code"
                      placeholder="Zip / Postal Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zip_postal_code}
                      invalid={
                        errors.zip_postal_code && touched.zip_postal_code
                      }
                    />
                    {errors.zip_postal_code && touched.zip_postal_code ? (
                      <FormFeedback>{errors.zip_postal_code}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Label>Country</Label>
                    <Input
                      type="text"
                      name="country"
                      id="country"
                      placeholder="Country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                      invalid={errors.country && touched.country}
                    />
                    {errors.country && touched.country ? (
                      <FormFeedback>{errors.country}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                {Object.keys(values).indexOf("delivery_address") > -1 &&
                  values.delivery_address &&
                  values.delivery_address !== "" && (
                    <Col xs={12}>
                      <FormGroup>
                        <Label>Dental Office Location</Label>
                        <p>{values.delivery_address}</p>
                      </FormGroup>
                    </Col>
                  )}
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
});

const MapLocationSelection = ({ onSetAddress = () => true }) => (
  <Row className="location_container">
    <Col>
      <div className="map_location" style={{ width: "100%", height: "450px" }}>
        <MapChart setAddress={onSetAddress} />
      </div>
    </Col>
  </Row>
);

class BuyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: -1, // 2
      baseColor: "#6fb7dd",
      baseColorName: "light blue",
      delivery_mode: "visit",
      validation: {
        step_0: true,
        step_1: true,
        step_2: true,
        step_3: true,
      },
      shipping_address_details: {
        first_name: "",
        last_name: "",
        email_address: "",
        address_line_1: "",
        address_line_2: "",
        state_province_region: "",
        zip_postal_code: "",
        country: "",
        delivery_address: "",
      },
      price: 24.99,
      showCheckoutModal: false,
      verifyCoupon: false,
      verifyCouponError: null,
      couponDetails: {},
      showCardModal: false,
      subscriptionProcessing: false,
      subscriptionError: null,
      product_id: "prod_QI8W6JJTlCJAAq",
      tooltip_content: "",
    };
    this.shippingFormRef = createRef();
    this.checkoutModelRef = React.createRef();

    this.setTooltipContent = this.setTooltipContent.bind(this);
  }

  multiformSteps = (delivery_mode) => {
    // console.log("delivery_mode ::: ", delivery_mode);
    let steps_information_dynamic = [
      {
        title: "Step 1",
        description: "Design your custom mouthguard.",
        selection_description: false,
      },
      {
        title: "Step 2",
        description:
          "Visit one of our dental scan locations. In-home consultation is also available in some locations.",
        selection_description:
          "Okay great now you have selected your design, we need to get your teeth impression. There are three options, please select one.",
      },
      {
        title: "Step 3",
        description: "Visit one of our dental scan locations",
        selection_description: false,
        hidden: true,
      },
      {
        displayTitle: "Step 3",
        title: "Step 4",
        description:
          "Wait for your custom mouthguard to be delivered and enjoy!",
        selection_description: false,
      },
    ];

    if (delivery_mode === "impression-kit") {
      steps_information_dynamic = [
        {
          title: "Step 1",
          description: "Design your custom mouthguard.",
          selection_description: false,
        },
        {
          title: "Step 2",
          description:
            "Visit one of our dental scan locations. In-home consultation is also available in some locations.",
          selection_description:
            "Okay great now you have selected your design, we need to get your teeth impression. There are three options, please select one.",
        },
        {
          title: "Step 3",
          description:
            "Wait for your custom mouthguard to be delivered and enjoy!",
          selection_description: false,
        },
      ];
    }
    return steps_information_dynamic;
  };

  setAddress = (addr) => {
    this.setState({
      shipping_address_details: {
        first_name: "",
        last_name: "",
        email_address: "",
        address_line_1: "",
        address_line_2: "",
        state_province_region: "",
        zip_postal_code: "",
        delivery_address: addr,
      },
    }, () => {
      this.jumpToNextStep();
    });
  };
  setTooltipContent(value) {
    this.setState({ tooltip_content: value });
  }

  onSelectColor(value) {
    /* console.log("here ---- : ", {
      baseColor: value.baseColor,
      baseColorName: value.baseColorName,
    }); */
    this.setState({
      baseColor: value.baseColor,
      baseColorName: value.baseColorName,
      validation: { ...this.state.validation, step_0: true },
    });
  }

  jumpToNextStep() {
    if (this.state.steps === 2 && this.state.delivery_mode === "visit" && this.state.shipping_address_details.delivery_address === '') {
      alert('Please select a office location for scan.')
      return false;
    }
    if (this.state.steps === 1 && this.state.delivery_mode === "on-site") {
      alert(
        "In-home scanning is not currently available in your area. Sorry. Please select a different option."
      );
      return false;
    }
    const steps_information = this.multiformSteps(this.state.delivery_mode);
    const validationPassed =
      this.state.steps > -1
        ? this.state.validation[`step_${this.state.steps}`]
        : true;
    if (validationPassed && this.state.steps !== steps_information.length - 1) {
      this.setState({
        steps: this.state.steps + 1,
      });
    }
    if (validationPassed && this.state.steps === steps_information.length - 1) {
      // console.log("--hello=-==");
      this.shippingFormRef.current.submitForm();
    }
  }
  jumpToPreviousStep() {
    this.setState({
      steps: this.state.steps - 1,
    });
  }
  // triggerValidation(e) {
  //     e.preventDefault()
  //     this.shippingFormRef.current.submitForm()
  // }

  applyDiscountHandler = (value) => {
    this.setState({
      verifyCoupon: true,
      verifyCouponError: null,
      couponDetails: {},
    });
    axios
      .get(`/api/verifycoupon/${value}`)
      .then((res) => res.data.data)
      .then((res) => {
        const { amount_off, percent_off, name, valid, id } = res;
        this.setState({
          couponDetails: { amount_off, percent_off, name, valid, id },
          verifyCoupon: false,
        });
      })
      .catch((e) => {
        const { data } = e.response || {};
        const { message } = data || {};
        this.setState({
          verifyCoupon: false,
          verifyCouponError:
            message && typeof message.message !== "undefined" && message.message
              ? message.message
              : e.message,
          couponDetails: {},
          couponCode: null,
        });
      });
  };

  handleFormSubmit(values) {
    this.setState({
      shipping_address_details: Object.assign(
        {},
        this.state.shipping_address_details,
        values
      ),
      showCheckoutModal: true,
    });
  }

  handleStripePaymentMethodSuccess = (values) => {
    // console.log("values ::: ", values);
    this.setState({
      subscriptionProcessing: true,
      subscriptionError: null,
    });
    const { stripe, paymentMethod, card } = values;
    const couponDetailsKey = Object.keys(this.state.couponDetails);
    const apiPostData = {
      shipping_address_details: this.state.shipping_address_details,
      payment_method: paymentMethod.id,
      product_id: this.state.product_id,
      coupon:
        this.state.couponDetails &&
        couponDetailsKey.length > 0 &&
        couponDetailsKey.indexOf("id") > -1 &&
        this.state.couponDetails.id
          ? this.state.couponDetails.id
          : null,
    };
  };

  handleShippingValueChange({ field_name, value }) {
    const previousShippingValue = Object.assign(
      {},
      this.state.shipping_address_details
    );
    previousShippingValue[field_name] = value;
    this.setState({
      shipping_address_details: previousShippingValue,
    });
  }

  handleShippingMode(value) {
    this.setState({
      delivery_mode: value,
      shipping_address_details: {
        ...this.state.shipping_address_details,
        delivery_address: "",
      },
    });
  }

  render() {
    let steps_information = this.multiformSteps(this.state.delivery_mode);
    const {
      steps,
      validation,
      baseColor,
      baseColorName,
      shipping_address_details,
      price,
      showCheckoutModal,
      verifyCoupon,
      couponDetails,
      verifyCouponError,
      modalCardShow,
      subscriptionProcessing,
    } = this.state;
    return (
      <React.Fragment>
        <Header headClass="header-sticky" />
        <div className="buy-evoshield">
          <Container>
            {/* <Row>
                            <Col>
                                <Button onClick={(e) => this.triggerValidation(e)}>Validate</Button>
                            </Col>
                        </Row> */}
            {steps < 0 && (
              <>
                <Row className="product-info align-items-center">
                  <Col
                    className="product-name text-center text-sm-left"
                    xs="12"
                    sm="6"
                  >
                    <h3>EvoShield SMG</h3>
                    <h4>Know the impact, Protect the knowledge.</h4>
                  </Col>
                  <Col
                    className="product-cost text-center text-sm-right"
                    xs="12"
                    sm="6"
                  >
                    <h3>$24.99/month*</h3>
                    <p>*6 month commitment required upfront</p>
                  </Col>
                </Row>
                <Row className="plan-step-information">
                  {steps_information.map((el) => (
                    <DetailsCards
                      title={el.title}
                      description={el.description}
                      hidden={
                        (Object.keys(el).indexOf("hidden") > -1 && el.hidden) ||
                        false
                      }
                      displayTitle={
                        Object.keys(el).indexOf("displayTitle") > -1 &&
                        el.displayTitle
                          ? el.displayTitle
                          : false
                      }
                    />
                  ))}
                </Row>
                <Row>
                  <Col className="text-center my-5">
                    <Button
                      onClick={() => this.jumpToNextStep()}
                      className="buy-now-button"
                    >
                      Buy Now
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {steps >= 0 && (
              <StepFormItems
                onNextClick={() => this.jumpToNextStep()}
                onPreviousClick={() => this.jumpToPreviousStep()}
                nextActive={validation[`step_${steps}`]}
                finishText="Proceed to checkout"
                isFinish={
                  this.state.delivery_mode === "visit"
                    ? steps === 3
                    : steps === 2
                }
              >
                <Row className="product-info align-items-center">
                  <Col className="product-name text-center">
                    <h3>Evoshield SMG</h3>
                  </Col>
                </Row>
                <Row>
                  <Col className="step-information text-center">
                    <h3>
                      {steps_information[steps].title} :{" "}
                      {steps_information[steps].selection_description
                        ? steps_information[steps].selection_description
                        : steps_information[steps].description}
                    </h3>
                  </Col>
                </Row>
                {steps === 0 && (
                  <ColorCustomizer
                    baseC={baseColor}
                    baseCname={baseColorName}
                    onSelectColor={(v) => this.onSelectColor(v)}
                  />
                )}
                {steps === 1 && (
                  <Row className="plan-step-information">
                    {address_selection.map((el) => (
                      <DetailsCards
                        title={el.title}
                        description={el.description}
                        card_value={el.value}
                        onCardClick={(value) => this.handleShippingMode(value)}
                        isSelected={el.value === this.state.delivery_mode}
                      />
                    ))}
                  </Row>
                )}
                {steps === 2 && this.state.delivery_mode === "visit" && (
                  <MapLocationSelection onSetAddress={this.setAddress} />
                )}
                {((steps === 3 && this.state.delivery_mode === "visit") ||
                  (steps === 2 &&
                    this.state.delivery_mode === "impression-kit")) && (
                  <ShippingAddressForm
                    ref={this.shippingFormRef}
                    handleFormSubmit={(formValues) =>
                      this.handleFormSubmit(formValues)
                    }
                    perisistValue={shipping_address_details}
                    onInputChange={(details) =>
                      this.handleShippingValueChange(details)
                    }
                  />
                )}
              </StepFormItems>
            )}
          </Container>
        </div>
        <Footer />
        <CheckoutPop
          price={price}
          show={showCheckoutModal}
          onHide={() =>
            this.setState({
              showCheckoutModal: false,
            })
          }
          onCard={(value) =>
            this.setState({
              showCheckoutModal: false,
              showCardModal: true,
              couponCode: value.couponCode,
            })
          }
          ref={this.checkoutModelRef}
          applyDiscountHandler={this.applyDiscountHandler}
          verifyCoupon={verifyCoupon}
          couponDetails={couponDetails}
          verifyCouponError={verifyCouponError}
        />
        <StripeCardPopup
          show={modalCardShow}
          onHide={() => {
            this.setState({
              modalCardShow: false,
              couponDetails: {},
            });
            this.checkoutModelRef.current.resetCoupon();
          }}
          subscriptionProcessing={subscriptionProcessing}
          couponDetails={couponDetails}
          price={parseFloat(price)}
          handleStripePaymentMethodSuccess={
            this.handleStripePaymentMethodSuccess
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuyPage));
