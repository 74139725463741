import React, { Component } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  CircleMarker,
} from "react-leaflet";
import MapTooltip from "./MapTooltip";
import "leaflet/dist/leaflet.css";


//fixing icon 404
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25,41],
     iconAnchor: [12,41]
});

L.Marker.prototype.options.icon = DefaultIcon;




const markers = [
  {
    name: "University Dental Care",
    address: "112 W Foster Ave State College, PA 16801",
    coordinates: [40.7919, -77.8589],
  },
];

export class MapChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMarker: null,
      selected_address: "",
    };
  }

  setSelectedAddress = (addr) =>
  {
    this.props.setAddress(addr);
  }
  render () {

    return (
      <MapContainer
        center={[40.8097343, -97.5556199]}
        zoom={4}
        minZoom={3}
        zoomSnap={0.5}
        updateWhenZooming={false}
        updateWhenIdle={true}
        preferCanvas={true}
        //minZoom={this.state.minZoom}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {markers.map((marker, idx) => (
          <Marker
            key={`mountain-${idx}`}
            eventHandlers={{
              click: () => {
                this.setState({ activeMarker: marker });
              },
            }}
            position={marker.coordinates}
          ></Marker>
        ))}
        {this.state.activeMarker && (
          <Popup
            position={this.state.activeMarker.coordinates}
            eventHandlers={{
              remove: () => {
                this.setState({ activeMarker: null });
              },
            }}
          >
            <MapTooltip
              marker={this.state.activeMarker}
              setSelectedAddress={this.setSelectedAddress}
            />
          </Popup>
        )}
      </MapContainer>
    );
  }
}

export default MapChart;
