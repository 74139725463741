 
export const SET_SELECTED_SPHERE_TYPE = "SET_SELECTED_SPHERE_TYPE"

/* ALL LIST */
export const GET_TEAM_PLAYERS = "GET_TEAM_PLAYERS"
export const GET_TEAM_PLAYERS_SUCCESS = "GET_TEAM_PLAYERS_SUCCESS"
export const GET_TEAM_PLAYERS_FAIL = "GET_TEAM_PLAYERS_FAIL"

/* GET PLAYER DETAILS */
export const GET_PLAYER_DETAILS = "GET_PLAYER_DETAILS"
export const GET_PLAYER_DETAILS_SUCCESS = "GET_PLAYER_DETAILS_SUCCESS"
export const GET_PLAYER_DETAILS_FAIL = "GET_PLAYER_DETAILS_FAIL"

/* FILTERED PLAYER LIST */
export const FILTER_TEAM_PLAYERS = "FILTER_TEAM_PLAYERS"
export const FILTER_TEAM_PLAYERS_SUCCESS = "FILTER_TEAM_PLAYERS_SUCCESS"
export const FILTER_TEAM_PLAYERS_FAIL = "FILTER_TEAM_PLAYERS_FAIL"

/* ALL LIST */
export const PLAYER_SIMULATION = "PLAYER_SIMULATION"
export const PLAYER_SIMULATION_SUCCESS = "PLAYER_SIMULATION_SUCCESS"
export const PLAYER_SIMULATION_FAIL = "PLAYER_SIMULATION_FAIL"

export const PLAYER_ALL_SIMULATIONS_SUCCESS = "PLAYER_ALL_SIMULATIONS_SUCCESS"


/* ALL LIST */
export const SIMULATION_BY_EVENT_ID = "SIMULATION_BY_EVENT_ID"
export const SIMULATION_BY_EVENT_ID_SUCCESS = "SIMULATION_BY_EVENT_ID_SUCCESS"
export const SIMULATION_BY_EVENT_ID_FAIL = "SIMULATION_BY_EVENT_ID_FAIL"

/* SINGLE PLAYER SUMMARY DATA */
export const GET_PLAYER_SUMMARY = "GET_PLAYER_SUMMARY"
export const GET_PLAYER_SUMMARY_SUCCESS = "GET_PLAYER_SUMMARY_SUCCESS"
export const GET_PLAYER_SUMMARY_FAIL = "GET_PLAYER_SUMMARY_FAIL"

/* SINGLE PLAYER SUMMARY DATA */
export const GET_SIM_ACC_DATA = "GET_SIM_ACC_DATA"
export const GET_SIM_ACC_DATA_SUCC = "GET_SIM_ACC_DATA_SUCC"
export const GET_SIM_ACC_DATA_FAIL = "GET_SIM_ACC_DATA_FAIL"

/* PLAYER ANYLASSIS DATA */
export const GET_PLAYER_ANYLSIS = "GET_PLAYER_ANYLSIS"
export const GET_PLAYER_ANYLSIS_SUCC = "GET_PLAYER_ANYLSIS_SUCC"
export const GET_PLAYER_ANYLSIS_FAIL = "GET_PLAYER_ANYLSIS_FAIL"

/* PLAYER ANYLASSIS DATA */
export const GET_SIMULATION_VIDEOS = "GET_SIMULATION_VIDEOS"
export const GET_SIMULATION_VIDEOS_SUCC = "GET_SIMULATION_VIDEOS_SUCC"
export const GET_SIMULATION_VIDEOS_FAIL = "GET_SIMULATION_VIDEOS_FAIL"

export const SET_SIDELINE_URL = "SET_SIDELINE_URL"

/* BRAIN LOADING OVER TIME  */
export const GET_MPS_VARIUS_TIME = "GET_MPS_VARIUS_TIME"
export const GET_MPS_VARIUS_TIME_SUCC = "GET_MPS_VARIUS_TIME_SUCC"
export const GET_MPS_VARIUS_TIME_FAIL = "GET_MPS_VARIUS_TIME_FAIL"

export const GET_TEAM_MPS_VARIUS_TIME_SUCC = "GET_TEAM_MPS_VARIUS_TIME_SUCC"

/* TRIM VIDEOS  */
export const TRIM_VIDEOS = "TRIM_VIDEOS"
export const TRIM_VIDEOS_SUCC = "TRIM_VIDEOS_SUCC"  
export const TRIM_VIDEOS_FAIL = "TRIM_VIDEOS_FAIL"
export const RESET_TRIM_STATE = "RESET_TRIM_STATE"
export const SET_VIDEO_FPS = "SET_VIDEO_FPS"

/* BRAIN LOADING OVER TIME  */
export const RESET_SIDELINE_VIDEO = "RESET_SIDELINE_VIDEO"
export const RESET_SIDELINE_VIDEO_SUCC = "RESET_SIDELINE_VIDEO_SUCC"
export const RESET_SIDELINE_VIDEO_FAIL = "RESET_SIDELINE_VIDEO_FAIL"

/* FLIP SIDELINE VIDEO */
export const MIRROR_SIDELINE_VIDEO = "MIRROR_SIDELINE_VIDEO"
export const MIRROR_SIDELINE_VIDEO_SUCC = "MIRROR_SIDELINE_VIDEO_SUCC"
export const MIRROR_SIDELINE_VIDEO_FAIL = "MIRROR_SIDELINE_VIDEO_FAIL"

export const SET_PLAYER_MPS_VALUES = "SET_PLAYER_MPS_VALUES"

/* ALL LIST */
export const FILTER_PLAYER_SIMULATION = "FILTER_PLAYER_SIMULATION"
export const FILTER_PLAYER_SIMULATION_SUCCESS = "FILTER_PLAYER_SIMULATION_SUCCESS"
export const FILTER_PLAYER_SIMULATION_FAIL = "FILTER_PLAYER_SIMULATION_FAIL"

/* ALL LIST */
export const GET_PL_BY_TYPE = "GET_PL_BY_TYPE"
export const GET_PL_BY_TYPE_SUCCESS = "GET_PL_BY_TYPE_SUCCESS"
export const GET_PL_BY_TYPE_FAIL = "GET_PL_BY_TYPE_FAIL"

export const SET_PL_BY_TYPE = "SET_PL_BY_TYPE"

export const FAMILY_PLAYER_STAFF = "FAMILY_PLAYER_STAFF"
export const FAMILY_PLAYER_STAFF_SUCCESS = "FAMILY_PLAYER_STAFF_SUCCESS"
export const FAMILY_PLAYER_STAFF_FAIL = "FAMILY_PLAYER_STAFF_FAIL"
export const MAX_VALUE_OF_BRAIN_EEFECT = "MAX_VALUE_OF_BRAIN_EEFECT"

export const PROCESS_ML_SUBMIT = "PROCESS_ML_SUBMIT"
export const ERROR_MESSAGE_ML_SUBMIT = "ERROR_MESSAGE_ML_SUBMIT"
export const SUCCESS_MESSAGE_ML_SUBMIT = "SUCCESS_MESSAGE_ML_SUBMIT"
export const RESET_ML_SUBMIT = "RESET_ML_SUBMIT"

export const RESET_TEAM_PLAYERS = "RESET_TEAM_PLAYERS"
//STRAIN
export const GET_PLAYER_ANALYSIS_DATA = "GET_PLAYER_ANALYSIS_DATA"
export const GET_PLAYER_ANALYSIS_DATA_SUCCESS = "GET_PLAYER_ANALYSIS_DATA_SUCCESS"
export const GET_PLAYER_ANALYSIS_DATA_FAIL = "GET_PLAYER_ANALYSIS_DATA_FAIL"

//ML
export const GET_PLAYER_ML_ANALYSIS_DATA = "GET_PLAYER_ML_ANALYSIS_DATA"
export const GET_PLAYER_ML_ANALYSIS_DATA_SUCCESS = "GET_PLAYER_ML_ANALYSIS_DATA_SUCCESS"
export const GET_PLAYER_ML_ANALYSIS_DATA_FAIL = "GET_PLAYER_ML_ANALYSIS_DATA_FAIL"

//PRESSURE 
export const GET_PLAYER_PRESSURE_ANALYSIS_DATA = "GET_PLAYER_PRESSURE_ANALYSIS_DATA"
export const GET_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS = "GET_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS"
export const GET_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL = "GET_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL"

export const GET_PLAYER_ALL_SIMS_PL = "GET_PLAYER_ALL_SIMS_PL"
export const GET_PLAYER_ALL_SIMS_PL_SUCCESS = "GET_PLAYER_ALL_SIMS_PL_SUCCESS"
export const GET_PLAYER_ALL_SIMS_PL_FAIL = "GET_PLAYER_ALL_SIMS_PL_FAIL"