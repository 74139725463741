import React, { Component } from "react"
import { Col, Card } from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

// import BrainView from '../../../components/Brain/BrainView'
import BrainCompnent from '../../Dash/DashPage'
import { setInsAnylsisData, setInsMpsData } from '../../../store/actions'

class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: localStorage.getItem("authUser"),
            filteringData: true,
            minimumPS: [[], [], [], [], [], [], []],
            maximumPS: [[], [], [], [], [], [], []],
            csdm_15: [[], [], [], [], [], [], []],
            csdm_5: [[], [], [], [], [], [], []],
            csdm_10: [[], [], [], [], [], [], []]
        }
    }
    componentDidMount() {
        const { InsStatsSummary } = this.props;
        if (InsStatsSummary) {
            this.filterSummaryData();
        } else {
            this.setState({ filteringData: false })
        }
    }

    filterSummaryData = () => {
        let acceleration_data_list = [];
        // let frontal_Lobe = [];
        let minimumPS = [[], [], [], [], [], [], []];
        let maximumPS = [[], [], [], [], [], [], []];
        let csdm_15 = [[], [], [], [], [], [], []];
        let csdm_5 = [[], [], [], [], [], [], []];
        let csdm_10 = [[], [], [], [], [], [], []];
        let the = this;
        let MPS_95_DATA = [];
        let MAX_ANGULAR_EXLARATION = [];
        let MPS_95_VEL_DATA = [];
        let MAX_ANGULAR_VEL_EXLARATION = [];
        let MPS_95_LIN_DATA = [];
        let MAX_LINEAR_EXLARATION = [];
        let P_CSDM_15 = [];
        let P_MAX_S_POSITIONS = []; //for principal-max-strain-position
        let P_MIN_S_POSITIONS = []; // for principal-min-strain-poistion
        const { InsStatsSummary, playerData, selectedMetric, selectedRange, selectedgs } = this.props;
        var summarData = InsStatsSummary;
        let gs = selectedgs;
        // console.log('summarData ==============================\n', summarData)
        if (!summarData || summarData.length < 1) return null //if data is null

        let InsMpsEventValues = [];
        /**
       * function for push data of summary inside variables ...
       */
        const pushdata = (summary_data, profileData) => {
            // console.log('profileData ----', profileData)
            if (summary_data['MPS-95']) {
                if (summary_data['MPS-95']['value'] && summary_data['max-angular-acc-rads2']) {
                    MPS_95_DATA.push(summary_data['MPS-95']['value']);
                    MAX_ANGULAR_EXLARATION.push({ val: summary_data['max-angular-acc-rads2'], event_id: summary_data['event_id'], dataOf: profileData.team });
                }
                if (summary_data['MPS-95']['value'] && summary_data['max-angular-vel-rads']) {
                    MPS_95_VEL_DATA.push(summary_data['MPS-95']['value']);
                    MAX_ANGULAR_VEL_EXLARATION.push({ val: summary_data['max-angular-vel-rads'], event_id: summary_data['event_id'], dataOf: profileData.team });
                }
                if (summary_data['MPS-95']['value'] && summary_data['max-linear-acc-g']) {
                    MPS_95_LIN_DATA.push(summary_data['MPS-95']['value']);
                    MAX_LINEAR_EXLARATION.push({ val: summary_data['max-linear-acc-g'], event_id: summary_data['event_id'] });
                }
            }
            return null
        }

        //
        const pushPostionData = (summary_data, profileData) => {
            if (summary_data['principal-max-strain']) {
                if (summary_data['principal-max-strain']['value']) {
                    P_MAX_S_POSITIONS.push({ [profileData.player.position]: summary_data['principal-max-strain']['value'] });
                    // S_MAX_S_POSITIONS.push({ [sport]: summary_data['principal-max-strain']['value'] });
                }
            }

            if (summary_data['principal-min-strain']) {
                if (summary_data['principal-min-strain']['value']) {
                    P_MIN_S_POSITIONS.push({ [profileData.player.position]: summary_data['principal-min-strain']['value'] });
                    // S_MIN_S_POSITIONS.push({ [sport]: summary_data['principal-min-strain']['value'] });
                }
            }

            if (summary_data['CSDM-15']) {
                if (summary_data['CSDM-15']['value']) {
                    P_CSDM_15.push({ [profileData.player.position]: summary_data['CSDM-15']['value'] });
                    // S_CSDM_15.push({ [sport]: summary_data['CSDM-15']['value'] });

                }
            }
        }

        const pushMetricData = (insult) => {
            return new Promise((resolve, reject) => {
                if (insult["principal-max-strain"]) {
                    InsMpsEventValues.push({ region: insult["principal-max-strain"]["brain-region"], value: insult['principal-max-strain'].value, event_id: insult["event_id"] });
                    switch (insult["principal-max-strain"]["brain-region"]) {
                        case "msc":
                            maximumPS[6] = [
                                {
                                    x: insult["principal-max-strain"]["location"][0],
                                    y: insult["principal-max-strain"]["location"][1],
                                    z: insult["principal-max-strain"]["location"][2],
                                    value: insult["principal-max-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...maximumPS[6],
                            ];
                            break;
                        case "stem":
                            maximumPS[5] = [
                                {
                                    x: insult["principal-max-strain"]["location"][0],
                                    y: insult["principal-max-strain"]["location"][1],
                                    z: insult["principal-max-strain"]["location"][2],
                                    value: insult["principal-max-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...maximumPS[5],
                            ];
                            break;
                        case "cerebellum":
                            maximumPS[4] = [
                                {
                                    x: insult["principal-max-strain"]["location"][0],
                                    y: insult["principal-max-strain"]["location"][1],
                                    z: insult["principal-max-strain"]["location"][2],
                                    value: insult["principal-max-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...maximumPS[4],
                            ];
                            break;
                        case "temporal":
                            maximumPS[3] = [
                                {
                                    x: insult["principal-max-strain"]["location"][0],
                                    y: insult["principal-max-strain"]["location"][1],
                                    z: insult["principal-max-strain"]["location"][2],
                                    value: insult["principal-max-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...maximumPS[3],
                            ];
                            break;
                        case "occipital":
                            maximumPS[2] = [
                                {
                                    x: insult["principal-max-strain"]["location"][0],
                                    y: insult["principal-max-strain"]["location"][1],
                                    z: insult["principal-max-strain"]["location"][2],
                                    value: insult["principal-max-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...maximumPS[2],
                            ];
                            break;
                        case "parietal":
                            maximumPS[1] = [
                                {
                                    x: insult["principal-max-strain"]["location"][0],
                                    y: insult["principal-max-strain"]["location"][1],
                                    z: insult["principal-max-strain"]["location"][2],
                                    value: insult["principal-max-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...maximumPS[1],
                            ];
                            break;
                        case "frontal":
                            maximumPS[0] = [
                                {
                                    x: insult["principal-max-strain"]["location"][0],
                                    y: insult["principal-max-strain"]["location"][1],
                                    z: insult["principal-max-strain"]["location"][2],
                                    value: insult["principal-max-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...maximumPS[0],
                            ];
                            break;
                        default:
                            break;
                    }
                }
                if (insult["principal-min-strain"]) {
                    switch (insult["principal-min-strain"]["brain-region"]) {
                        case "msc":
                            minimumPS[6] = [
                                {
                                    x: insult["principal-min-strain"]["location"][0],
                                    y: insult["principal-min-strain"]["location"][1],
                                    z: insult["principal-min-strain"]["location"][2],
                                    value: insult["principal-min-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...minimumPS[6],
                            ];
                            break;
                        case "stem":
                            minimumPS[5] = [
                                {
                                    x: insult["principal-min-strain"]["location"][0],
                                    y: insult["principal-min-strain"]["location"][1],
                                    z: insult["principal-min-strain"]["location"][2],
                                    value: insult["principal-min-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...minimumPS[5],
                            ];
                            break;
                        case "cerebellum":
                            minimumPS[4] = [
                                {
                                    x: insult["principal-min-strain"]["location"][0],
                                    y: insult["principal-min-strain"]["location"][1],
                                    z: insult["principal-min-strain"]["location"][2],
                                    value: insult["principal-min-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...minimumPS[4],
                            ];
                            break;
                        case "temporal":
                            minimumPS[3] = [
                                {
                                    x: insult["principal-min-strain"]["location"][0],
                                    y: insult["principal-min-strain"]["location"][1],
                                    z: insult["principal-min-strain"]["location"][2],
                                    value: insult["principal-min-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...minimumPS[3],
                            ];
                            break;
                        case "occipital":
                            minimumPS[2] = [
                                {
                                    x: insult["principal-min-strain"]["location"][0],
                                    y: insult["principal-min-strain"]["location"][1],
                                    z: insult["principal-min-strain"]["location"][2],
                                    value: insult["principal-min-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...minimumPS[2],
                            ];
                            break;
                        case "parietal":
                            minimumPS[1] = [
                                {
                                    x: insult["principal-min-strain"]["location"][0],
                                    y: insult["principal-min-strain"]["location"][1],
                                    z: insult["principal-min-strain"]["location"][2],
                                    value: insult["principal-min-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...minimumPS[1],
                            ];
                            break;
                        case "frontal":
                            minimumPS[0] = [
                                {
                                    x: insult["principal-min-strain"]["location"][0],
                                    y: insult["principal-min-strain"]["location"][1],
                                    z: insult["principal-min-strain"]["location"][2],
                                    value: insult["principal-min-strain"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...minimumPS[0],
                            ];
                            break;
                        default:
                            break;
                    }
                }
                if (insult["CSDM-15"]) {
                    switch (insult["CSDM-15"]["brain-region"]) {
                        case "msc":
                            csdm_15[6] = [
                                {
                                    x: insult["CSDM-15"]["location"][0],
                                    y: insult["CSDM-15"]["location"][1],
                                    z: insult["CSDM-15"]["location"][2],
                                    value: insult["CSDM-15"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_15[6],
                            ];
                            break;
                        case "stem":
                            csdm_15[5] = [
                                {
                                    x: insult["CSDM-15"]["location"][0],
                                    y: insult["CSDM-15"]["location"][1],
                                    z: insult["CSDM-15"]["location"][2],
                                    value: insult["CSDM-15"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_15[5],
                            ];
                            break;
                        case "cerebellum":
                            csdm_15[4] = [
                                {
                                    x: insult["CSDM-15"]["location"][0],
                                    y: insult["CSDM-15"]["location"][1],
                                    z: insult["CSDM-15"]["location"][2],
                                    value: insult["CSDM-15"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_15[4],
                            ];
                            break;
                        case "temporal":
                            csdm_15[3] = [
                                {
                                    x: insult["CSDM-15"]["location"][0],
                                    y: insult["CSDM-15"]["location"][1],
                                    z: insult["CSDM-15"]["location"][2],
                                    value: insult["CSDM-15"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_15[3],
                            ];
                            break;
                        case "occipital":
                            csdm_15[2] = [
                                {
                                    x: insult["CSDM-15"]["location"][0],
                                    y: insult["CSDM-15"]["location"][1],
                                    z: insult["CSDM-15"]["location"][2],
                                    value: insult["CSDM-15"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_15[2],
                            ];
                            break;
                        case "parietal":
                            csdm_15[1] = [
                                {
                                    x: insult["CSDM-15"]["location"][0],
                                    y: insult["CSDM-15"]["location"][1],
                                    z: insult["CSDM-15"]["location"][2],
                                    value: insult["CSDM-15"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_15[1],
                            ];
                            break;
                        case "frontal":
                            csdm_15[0] = [
                                {
                                    x: insult["CSDM-15"]["location"][0],
                                    y: insult["CSDM-15"]["location"][1],
                                    z: insult["CSDM-15"]["location"][2],
                                    value: insult["CSDM-15"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_15[0],
                            ];
                            break;
                        default:
                            break;
                    }
                }
                if (insult["CSDM-5"]) {
                    switch (insult["CSDM-5"]["brain-region"]) {
                        case "msc":
                            csdm_5[6] = [
                                {
                                    x: insult["CSDM-5"]["location"][0],
                                    y: insult["CSDM-5"]["location"][1],
                                    z: insult["CSDM-5"]["location"][2],
                                    value: insult["CSDM-5"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_5[6],
                            ];
                            break;
                        case "stem":
                            csdm_5[5] = [
                                {
                                    x: insult["CSDM-5"]["location"][0],
                                    y: insult["CSDM-5"]["location"][1],
                                    z: insult["CSDM-5"]["location"][2],
                                    value: insult["CSDM-5"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_5[5],
                            ];
                            break;
                        case "cerebellum":
                            csdm_5[4] = [
                                {
                                    x: insult["CSDM-5"]["location"][0],
                                    y: insult["CSDM-5"]["location"][1],
                                    z: insult["CSDM-5"]["location"][2],
                                    value: insult["CSDM-5"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_5[4],
                            ];
                            break;
                        case "temporal":
                            csdm_5[3] = [
                                {
                                    x: insult["CSDM-5"]["location"][0],
                                    y: insult["CSDM-5"]["location"][1],
                                    z: insult["CSDM-5"]["location"][2],
                                    value: insult["CSDM-5"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_5[3],
                            ];
                            break;
                        case "occipital":
                            csdm_5[2] = [
                                {
                                    x: insult["CSDM-5"]["location"][0],
                                    y: insult["CSDM-5"]["location"][1],
                                    z: insult["CSDM-5"]["location"][2],
                                    value: insult["CSDM-5"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_5[2],
                            ];
                            break;
                        case "parietal":
                            csdm_5[1] = [
                                {
                                    x: insult["CSDM-5"]["location"][0],
                                    y: insult["CSDM-5"]["location"][1],
                                    z: insult["CSDM-5"]["location"][2],
                                    value: insult["CSDM-5"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_5[1],
                            ];
                            break;
                        case "frontal":
                            csdm_5[0] = [
                                {
                                    x: insult["CSDM-5"]["location"][0],
                                    y: insult["CSDM-5"]["location"][1],
                                    z: insult["CSDM-5"]["location"][2],
                                    value: insult["CSDM-5"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_5[0],
                            ];
                            break;
                        default:
                            break;
                    }
                }
                if (insult["CSDM-10"]) {
                    switch (insult["CSDM-10"]["brain-region"]) {
                        case "msc":
                            csdm_10[6] = [
                                {
                                    x: insult["CSDM-10"]["location"][0],
                                    y: insult["CSDM-10"]["location"][1],
                                    z: insult["CSDM-10"]["location"][2],
                                    value: insult["CSDM-10"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_10[6],
                            ];
                            break;
                        case "stem":
                            csdm_10[5] = [
                                {
                                    x: insult["CSDM-10"]["location"][0],
                                    y: insult["CSDM-10"]["location"][1],
                                    z: insult["CSDM-10"]["location"][2],
                                    value: insult["CSDM-10"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_10[5],
                            ];
                            break;
                        case "cerebellum":
                            csdm_10[4] = [
                                {
                                    x: insult["CSDM-10"]["location"][0],
                                    y: insult["CSDM-10"]["location"][1],
                                    z: insult["CSDM-10"]["location"][2],
                                    value: insult["CSDM-10"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_10[4],
                            ];
                            break;
                        case "temporal":
                            csdm_10[3] = [
                                {
                                    x: insult["CSDM-10"]["location"][0],
                                    y: insult["CSDM-10"]["location"][1],
                                    z: insult["CSDM-10"]["location"][2],
                                    value: insult["CSDM-10"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_10[3],
                            ];
                            break;
                        case "occipital":
                            csdm_10[2] = [
                                {
                                    x: insult["CSDM-10"]["location"][0],
                                    y: insult["CSDM-10"]["location"][1],
                                    z: insult["CSDM-10"]["location"][2],
                                    value: insult["CSDM-10"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_10[2],
                            ];
                            break;
                        case "parietal":
                            csdm_10[1] = [
                                {
                                    x: insult["CSDM-10"]["location"][0],
                                    y: insult["CSDM-10"]["location"][1],
                                    z: insult["CSDM-10"]["location"][2],
                                    value: insult["CSDM-10"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_10[1],
                            ];
                            break;
                        case "frontal":
                            csdm_10[0] = [
                                {
                                    x: insult["CSDM-10"]["location"][0],
                                    y: insult["CSDM-10"]["location"][1],
                                    z: insult["CSDM-10"]["location"][2],
                                    value: insult["CSDM-10"]["value"],
                                    event_id: insult["event_id"]
                                },
                                ...csdm_10[0],
                            ];
                            break;
                        default:
                            break;
                    }
                }
                resolve(null);
            })
        }

        const fetchInsultes = (simulation, profileData) => {
            return new Promise((resolve, reject) => {
                simulation.Insults.forEach(async function (insult, index) {
                    // console.log('summary_data -------------', insult);
                    pushdata(insult, profileData); //
                    pushPostionData(insult, profileData); //
                    if (selectedRange === 'less') {
                        if (selectedMetric === 'resultant-Angular-acceleration') {
                            if (insult['max-angular-acc-rads2'] <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'resultant-linear-acceleration') {
                            if (insult['max-linear-acc-g'] <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'principal-max-strain') {
                            if (insult['principal-max-strain'] && insult['principal-max-strain'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'principal-min-strain') {
                            if (insult['principal-min-strain'] && insult['principal-min-strain'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'csdm-max') {
                            if (insult['csdm-max'] && insult['csdm-max'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'axonal-strain-max') {
                            if (insult['axonal-strain-max'] && insult['axonal-strain-max'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'masXsr-15-max') {
                            if (insult['masXsr-15-max'] && insult['masXsr-15-max'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'CSDM-5') {
                            if (insult['CSDM-5'] && insult['CSDM-5'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'CSDM-10') {
                            if (insult['CSDM-10'] && insult['CSDM-10'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'CSDM-15') {
                            if (insult['CSDM-15'] && insult['CSDM-15'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'MPS-95') {
                            if (insult['MPS-95'] && insult['MPS-95'].value <= gs) {
                                await pushMetricData(insult);
                            }
                        }

                        //** 
                        //Fetch value is greater then.....   
                    } else {
                        if (selectedMetric === 'resultant-Angular-acceleration') {
                            if (insult['max-angular-acc-rads2'] >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'resultant-linear-acceleration') {
                            if (insult['max-linear-acc-g'] >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'principal-max-strain') {
                            if (insult['principal-max-strain'] && insult['principal-max-strain'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'principal-min-strain') {
                            if (insult['principal-min-strain'] && insult['principal-min-strain'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'csdm-max') {
                            if (insult['csdm-max'] && insult['csdm-max'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'axonal-strain-max') {
                            if (insult['axonal-strain-max'] && insult['axonal-strain-max'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'masXsr-15-max') {
                            if (insult['masXsr-15-max'] && insult['masXsr-15-max'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'CSDM-5') {
                            if (insult['CSDM-5'] && insult['CSDM-5'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'CSDM-10') {
                            if (insult['CSDM-10'] && insult['CSDM-10'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'MPS-95') {
                            if (insult['MPS-95'] && insult['MPS-95'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        } else if (selectedMetric === 'CSDM-15') {
                            if (insult['CSDM-15'] && insult['CSDM-15'].value >= gs) {
                                await pushMetricData(insult);
                            }
                        }
                    }
                    if (simulation.Insults.length === index + 1) {
                        resolve(null)
                    }
                })

            })
        }

        summarData.map(async (simulation, index) => {
            // console.log('playerData ------------ 2', playerData)
            // return null;
            await fetchInsultes(simulation.summaryOutPut, playerData[index]);
            if (summarData.length === index + 1) {
                the.props.setInsAnylsisData({
                    MPS_95_DATA,
                    MAX_ANGULAR_EXLARATION,
                    MPS_95_VEL_DATA,
                    MAX_ANGULAR_VEL_EXLARATION,
                    MPS_95_LIN_DATA,
                    MAX_LINEAR_EXLARATION,
                    P_CSDM_15,
                    P_MAX_S_POSITIONS,
                    P_MIN_S_POSITIONS
                })
                // console.log('MPS_95_DATA ----', MPS_95_DATA, '\nMAX_ANGULAR_EXLARATION -----', MAX_ANGULAR_EXLARATION)
                this.props.setInsMpsData(InsMpsEventValues);
                the.setState({
                    filteringData: false,
                    minimumPS: minimumPS,
                    maximumPS: maximumPS,
                    csdm_15: csdm_15,
                    csdm_5: csdm_5,
                    csdm_10: csdm_10
                })
            }
        })
    }

    componentWillReceiveProps(props) {
        const { selectedMetric, selectedRange, selectedgs, isFilterMetric } = this.props;
        if (isFilterMetric) {
            this.filterSummaryData();
        }
        // console.log('isFilterMetric', selectedMetric, selectedRange, selectedgs, isFilterMetric)
    }
    render() {
        const { getPlayerSummary, playerSummary } = this.props;
        const { filteringData, brainRegions } = this.state;
        return (
            <React.Fragment>
                {!filteringData ?
                    <BrainCompnent
                        data_minimumPS={this.state.minimumPS}
                        data_maximumPS={this.state.maximumPS}
                        data_csdm_15={this.state.csdm_15}
                        data_csdm_5={this.state.csdm_5}
                        data_csdm_10={this.state.csdm_10}
                        set_filtered_event_ids = {this.props.set_filtered_event_ids}
                    />
                    : null
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {

        getInsStatsSummary: state.Institutions.getInsStatsSummary,
        InsStatsSummary: state.Institutions.InsStatsSummary,
        playerData: state.Institutions.playerData,
        getInsStatsSummaryErr: state.Institutions.getInsStatsSummaryErr,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInsAnylsisData: (items) => dispatch(setInsAnylsisData(items)),
        setInsMpsData: (items) => dispatch(setInsMpsData(items)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(index));
