import React, { Component } from "react";

export default class MapTooltip extends Component {
  render() {
    let marker = this.props.marker;
    return marker ? (
      <div className="map-tooltip">
        <b>{marker.name}</b><br />
        <p>{marker.address}</p>
        <b style={{color: "blue", cursor: "pointer"}} onClick={() => {
            this.props.setSelectedAddress(marker.address)
            }}> Select This Location</b>
        <br></br>
      </div>
    ) : (
      ""
    );
  }
}