import React, { useState, useEffect } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCardComponent from "../Stripe/StripeCardComponent";
const paymentMode = process.env.REACT_APP_AppMode === 'prod' ? process.env.REACT_APP_StripePubKey : process.env.REACT_APP_StripeDevPubKey ;
const stripePromise = loadStripe(paymentMode);
/*
Props: price and discount
Methods:
    handleStripePaymentMethodFailed: Payment methods error handler
    handleStripePaymentMethodSuccess: Payment methods success handler
*/

const StripeCardPopup = (props) => {
  const [error, setError] = useState(null);
  useEffect(() => {
    if (
      typeof props.subscriptionProcessing !== "undefined" &&
      props.subscriptionProcessing === true
    ) {
      setError(null);
    }
    return () => true;
  }, [props]);
  const { price, couponDetails } = props;
  const priceValue = price ? parseFloat(price) : 0;
  const couponExists =
    couponDetails && Object.keys(couponDetails).length > 0 ? true : false;
  let discountValue =
    couponExists &&
    Object.keys(couponDetails).indexOf("amount_off") > -1 &&
    couponDetails.amount_off
      ? parseFloat(couponDetails.amount_off)
      : null;
  if (!discountValue) {
    const percent_off =
      couponExists &&
      Object.keys(couponDetails).indexOf("percent_off") > -1 &&
      couponDetails.percent_off
        ? parseFloat(couponDetails.percent_off)
        : 0;
    discountValue =
      percent_off > 0 ? parseFloat((priceValue * percent_off) / 100) : 0;
  }
  const totalValue = (parseFloat(priceValue) - discountValue).toFixed(2);
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="checkout-model"
        onHide={() => true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Card Details
          </Modal.Title>
          <Button
            onClick={() => {
              setError(null);
              props.onHide("card");
            }}
            disabled={
              props.subscriptionProcessing
                ? props.subscriptionProcessing
                : false
            }
          >
            <svg
              height="329pt"
              viewBox="0 0 329.26933 329"
              width="329pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: "25px",
          }}
        >
          <Elements stripe={stripePromise}>
            <StripeCardComponent
              handlePaymentMethodFailed={(value) => {
                setError(value.error);
                if (
                  typeof props.handleStripePaymentMethodFailed === "function"
                ) {
                  props.handleStripePaymentMethodFailed(value);
                }
              }}
              handlePaymentMethodSuccess={(value) => {
                if (
                  typeof props.handleStripePaymentMethodSuccess === "function"
                ) {
                  props.handleStripePaymentMethodSuccess(value);
                }
              }}
              subscriptionProcessing={props.subscriptionProcessing || false}
              price={totalValue}
              disableOrg={props.disableOrganisation}
              level={props.level}
              buttonText={props.buttonText}
            />
          </Elements>
          {/* Card body */}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

StripeCardPopup.defaultProps = {
  disableOrganisation: false,
  buttonText: 'Authorize Payment'
}

export default StripeCardPopup;
