import React from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import EvoshieldFreshMint from "../../../assets/image/evoshield_fresh_fit.jpg";
import "./index.scss";
import { Image } from "react-bootstrap";

const ProductInformation = ({
  product_image_url,
  product_name,
  product_price,
}) => {
  return (
    <div className="product-info pb-3">
      {product_image_url && (
        <Col xs={12} className="px-0">
          <Image src={product_image_url} className="shop_product-image" />
        </Col>
      )}

      {product_name && (
        <Col xs={12} className="my-3">
          <h4 className="mb-0">{product_name}</h4>
        </Col>
      )}
      {product_price && (
        <Col xs={12}>
          <span>{product_price}</span>
        </Col>
      )}
    </div>
  );
};

const ShopPage = () => {
  return (
    <React.Fragment>
      <Header headClass="header-sticky" />
      <div className="shop-evoshield">
        <Container>
          <Row className="page-title">
            <Col className="text-center">
              <h3>Shop</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <ProductInformation
                  product_image_url={EvoshieldFreshMint}
                  product_name="Fresh Fit Spray - MINT FLAVOUR"
                  product_price="$200"
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default ShopPage;
