import {
  GET_DATA_SECURITY,
  GET_DATA_SECURITY_SUCCESS,
  GET_DATA_SECURITY_FAIL,

  GET_SENSOR,
  GET_SENSOR_SUCCESS,
  GET_SENSOR_FAIL,

  GET_SENSOR_INFORMATION,
  GET_SENSOR_INFORMATION_SUCCESS,
  GET_SENSOR_INFORMATION_FAILED,

  GET_SENSOR_EVENTS,
  GET_SENSOR_EVENTS_SUCCESS,
  GET_SENSOR_EVENTS_FAILED
} from "./actionTypes"

const INIT_STATE = {
  sensors: null,
  loading: false,

  dataSecurity: null,
  dataSecurityLoading: false,
  dataSecurityError: false,

  userProfile: null,
  error: false,

  sensorInfoLoading: false,
  sensorInfo: null,
  sensorInfoError: false,

  sensorEventsLoading: false,
  sensorEvents: [],
  sensorEventsTotal: 0,
  sensorEventsError: false
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SENSOR_INFORMATION:
      return {
        ...state,
        sensorInfoLoading: true,
        sensorInfo: null,
        sensorInfoError: false,
      }

    case GET_SENSOR_INFORMATION_SUCCESS:
      return {
        ...state,
        sensorInfoLoading: false,
        sensorInfo: action.payload,
        sensorInfoError: false,
      }
    
    case GET_SENSOR_INFORMATION_FAILED:
      return {
        ...state,
        sensorInfoLoading: false,
        sensorInfo: null,
        sensorInfoError: action.payload,
      }
    
    case GET_SENSOR_EVENTS:
      return {
        ...state,
        sensorEventsLoading: true,
        sensorEvents: [],
        sensorEventsTotal: 0,
        sensorEventsError: false
      }

    case GET_SENSOR_EVENTS_SUCCESS:
      return {
        ...state,
        sensorEventsLoading: false,
        sensorEvents: action.payload.items,
        sensorEventsTotal: action.payload.total,
        sensorEventsError: false
      }

    case GET_SENSOR_EVENTS_FAILED:
      return {
        ...state,
        sensorEventsLoading: false,
        sensorEvents: [],
        sensorEventsTotal: 0,
        sensorEventsError: action.paylod
      }

    case GET_SENSOR:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_SENSOR_SUCCESS:
      return {
        ...state,
        sensors: action.payload,
        loading: false,
        error: null
      }

    case GET_SENSOR_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      
    case GET_DATA_SECURITY:
      return {
        ...state,
        dataSecurityLoading: true,
        dataSecurityError: null
      }

    case GET_DATA_SECURITY_SUCCESS:
      return {
        ...state,
        dataSecurity: action.payload,
        dataSecurityLoading: false,
        dataSecurityError: null
      }

    case GET_DATA_SECURITY_FAIL:
      return {
        ...state,
        dataSecurityError: action.payload,
        dataSecurityLoading: false
      }

    default:
      return state
  }
}

export default contacts
