import axios from 'axios';
import {
  REGISTERING_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,

  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATIONS_FAILURE,

  GET_TEAMS,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,

  INVITE_USER_DETAILS,
  INVITE_USER_DETAILS_SUCCESS,
  INVITE_USER_DETAILS_FAILURE,

  GET_INS,
  GET_INS_SUCCESS,
  GET_INS_FAILURE,
  PLAYER_DETAILS_FROM_TOKEN,
  PLAYER_DETAILS_FROM_TOKEN_SUCCESS,
  PLAYER_DETAILS_FROM_TOKEN_FAILURE,
} from "./actionTypes"
import { history } from '../../../helpers/historyHelper'

// organzation list action ....

/* Creating new subscriber */
export const addNewSubscriber = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/auth/subscriber/new", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}

const getOrg = () => {
  return {
    type: GET_ORGANIZATIONS,
    payload: {},
  }
}

const getOrgSuccess = orgList => {
  return {
    type: GET_ORGANIZATIONS_SUCCESS,
    payload: orgList,
  }
}

const getOrgfailure = err => {
  return {
    type: GET_ORGANIZATIONS_FAILURE,
    payload: err,
  }
}

export const getAllOrganization = () => {
  return (dispatch) => {
    dispatch(getOrg());
    axios.patch("/auth/getAllOrg")
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(getOrgSuccess(response.data.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        dispatch(getOrgfailure(JSON.stringify(error.response.data.message)));
      });
  }
}

// Team list action ...

const getTeams = () => {
  return {
    type: GET_TEAMS,
    payload: {},
  }
}

const getTeamsSuccess = teamList => {
  return {
    type: GET_TEAMS_SUCCESS,
    payload: teamList,
  }
}

const getTeamsfailure = err => {
  return {
    type: GET_TEAMS_FAILURE,
    payload: err,
  }
}

export const getInsTeams = (body) => {
  return (dispatch) => {
    dispatch(getTeams());
    axios.patch("/auth/getInsTeams", body)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(getTeamsSuccess(response.data.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        dispatch(getTeamsfailure(JSON.stringify(error.response.data.message)));
      });
  }
}

// GET ORG INSTITUTIONS ...
const getIns = () => {
  return {
    type: GET_INS,
    payload: {},
  }
}

const getInsSuccess = list => {
  return {
    type: GET_INS_SUCCESS,
    payload: list,
  }
}

const getInsfailure = err => {
  return {
    type: GET_INS_FAILURE,
    payload: err,
  }
}

export const getSelectedOrgInstitutions = (body) => {
  return (dispatch) => {
    dispatch(getIns());
    axios.patch("/auth/getOrgInstituions", body)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(getInsSuccess(response.data.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        dispatch(getInsfailure(JSON.stringify(error.response.data.message)));
      });
  }
}

// Rister user ...
export const registeringUser = user => {
  return {
    type: REGISTERING_USER,
    payload: user,
  }
}

const registerUser = user => {
  return {
    type: REGISTER_USER,
    payload: user,
  }
}

const registerUserSuccessful = (user, message) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
    message: message
  }
}

const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const userRegister = body => {
  return dispatch => {
    dispatch(registerUser(body));
    axios.post("/auth/register", body)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(registerUserSuccessful(response.data.data, response.data.message));
      })
      .catch(function (error) {
        console.log('error ----------------', error);
        dispatch(registerUserFailed(JSON.stringify(error.response.data)));
      });
  }
}

export const userRegister_v2 = body => {
  return dispatch => {
    dispatch(registerUser(body));
    axios.post("/auth/v2/register", body)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(registerUserSuccessful(response.data.data, response.data.message));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        dispatch(registerUserFailed(JSON.stringify(error.response.data)));
      });
  }
}


// GETTING INVITED USER DETAILS ...

const getInviteDetails = () => {
  return {
    type: INVITE_USER_DETAILS,
    payload: {},
  }
}

const getInviteDetailsSuccess = teamList => {
  return {
    type: INVITE_USER_DETAILS_SUCCESS,
    payload: teamList,
  }
}

const getInviteDetailsFailure = err => {
  return {
    type: INVITE_USER_DETAILS_FAILURE,
    payload: err,
  }
}

export const getInvitedUserDetails = (body) => {
  return (dispatch) => {
    dispatch(getInviteDetails());
    axios.patch("/auth/getInvitedUserDetails", body)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(getInviteDetailsSuccess(response.data.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        dispatch(getInviteDetailsFailure(JSON.stringify(error.response.data.message)));
      });
  }
}

// GETTING INVITED USER DETAILS ...

const getPlayerDetailsFromToken = () => {
  return {
    type: PLAYER_DETAILS_FROM_TOKEN,
    payload: {},
  }
}

const getPlayerDetailsFromTokenSuccess = teamList => {
  return {
    type: PLAYER_DETAILS_FROM_TOKEN_SUCCESS,
    payload: teamList,
  }
}

const getPlayerDetailsFromTokenFailure = err => {
  return {
    type: PLAYER_DETAILS_FROM_TOKEN_FAILURE,
    payload: err,
  }
}

export const getPlayerDetailsFromTokenData = (body) => {
  return (dispatch) => {
    dispatch(getPlayerDetailsFromToken());
    axios.patch("/auth/getPlayerDetailsFromTokenData", body)
      .then(function (response) {
        dispatch(getPlayerDetailsFromTokenSuccess(response.data.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
       dispatch(getPlayerDetailsFromTokenFailure(error.response.data.message));
      });
  }
}