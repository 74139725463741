import React, { Component, forwardRef } from "react";
import { Row, Col, Card, Alert, Spinner, Table, Button } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./purposes.scss";
import {
  getMLplatformfiles,
  getteamplayerspositions,
  getInstituteDeatilsById,
  fetchInsSTatsSummaryData,
  getInstitutionsTeams,
} from "../../store/actions";

import Positionchart from "../../components/Charts/PositionChart";
import MllearningChart from "../../components/Charts/MllearningChart";
import BrainDetails from "./component/BrainDetails";
import MaaChart from "../../components/Charts/MaaChart";
import MavChart from "../../components/Charts/MavChart";
import TopInstitutionEvents from "../../components/Charts/TopInstitutionEvents";
import { Responsive, WidthProvider } from "react-grid-layout";
import {colors} from "../../helpers/constants"

const ResponsiveGridLayout = WidthProvider(Responsive);

var layout = [
  { i: "a", x: 6, y: 0, w: 3, h: 7, static: true },
  { i: "b", x: 9, y: 0, w: 3, h: 7, static: true },
  { i: "d", x: 6, y: 7, w: 3, h: 7, static: true },
  { i: "e", x: 9, y: 7, w: 3, h: 7, static: true },
  {
    i: "c",
    x: 0,
    y: 0,
    w: 6,
    h: 9,
    minW: 6,
    maxW: 8,
    minH: 9,
    maxH: 12,
    static: true,
  },
  {
    i: "f",
    x: 0,
    y: 9,
    w: 6,
    h: 5,
    static: true,
  },
];

var layoutMD = [
  { i: "a", x: 0, y: 14, w: 4, h: 7, static: true },
  { i: "b", x: 4, y: 14, w: 4, h: 7, static: true },
  { i: "d", x: 0, y: 21, w: 4, h: 7, static: true },
  { i: "e", x: 4, y: 21, w: 4, h: 7, static: true },
  {
    i: "c",
    x: 0,
    y: 0,
    w: 8,
    h: 9,
    minW: 4,
    maxW: 8,
    minH: 9,
    maxH: 12,
    static: true,
  },
  {
    i: "f",
    x: 0,
    y: 9,
    w: 8,
    h: 5,
    static: true,
  },
];

var layoutSM = [
  { i: "a", x: 0, y: 14, w: 4, h: 7, static: true },
  { i: "b", x: 4, y: 14, w: 4, h: 7, static: true },
  { i: "d", x: 0, y: 21, w: 4, h: 7, static: true },
  { i: "e", x: 4, y: 21, w: 4, h: 7, static: true },
  {
    i: "c",
    x: 0,
    y: 0,
    w: 8,
    h: 9,
    minW: 4,
    maxW: 8,
    minH: 9,
    maxH: 12,
    static: true,
  },
  {
    i: "f",
    x: 0,
    y: 9,
    w: 8,
    h: 5,
    static: true,
  },
];

var layoutXS = [
  { i: "a", x: 6, y: 0, w: 3, h: 9, static: true },
  { i: "b", x: 9, y: 0, w: 3, h: 9, static: true },
  { i: "d", x: 6, y: 9, w: 3, h: 9, static: true },
  { i: "e", x: 9, y: 9, w: 3, h: 9, static: true },
  {
    i: "c",
    x: 0,
    y: 0,
    w: 6,
    h: 20,
    static: true,
  },
  {
    i: "f",
    x: 0,
    y: 20,
    w: 6,
    h: 8,
    static: true,
  },
];

var layoutXXS = [
  { i: "a", x: 6, y: 0, w: 3, h: 9, static: true },
  { i: "b", x: 9, y: 0, w: 3, h: 9, static: true },
  { i: "d", x: 6, y: 9, w: 3, h: 9, static: true },
  { i: "e", x: 9, y: 9, w: 3, h: 9, static: true },
  {
    i: "c",
    x: 0,
    y: 0,
    w: 6,
    h: 18,
    static: true,
  },
  {
    i: "f",
    x: 0,
    y: 18,
    w: 6,
    h: 8,
    static: true,
  },
];

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: layout,
      user: localStorage.getItem("authUser"),
      loadingpositions: true,
      MLcsvData: "",
      MLjsonData: "",
      isMldataLoaded: false,
      isPlayerPositions: false,
      player_positions: "",
      player_positions_type: "principal-max-strain",
      //Filter variables
      selectedMetric: "principal-max-strain",
      selectedRange: "greater",
      selectedgs: 0,
      isFilterMetric: false,
      filter: false,
      selected_event_id: "",
      displayLegendVel: false,
      displayLegendAcc: false,
      filtered_event_ids: [],
    };
  }

  set_filtered_event_ids = (array) =>
  {
    // console.log('filtered_event_ids+++++++++++++++++', array)
    this.setState({filtered_event_ids: array})
  }

  setDisplayLegendVel = () =>
  {
    this.setState({displayLegendVel: !this.state.displayLegendVel});
  }

  setDisplayLegendAcc = () =>
  {
    this.setState({displayLegendAcc: !this.state.displayLegendAcc});
  }

  set_selected_event_id = (value) => {
    this.setState({ selected_event_id: value });
  };

  handleHover = (e) => {
    var tooltipEl = document.getElementById("chartjs-tooltip");
    if (tooltipEl) {
      tooltipEl.style.opacity = 0;
      return;
    }
  };

  handlePostionMetric = (e) => {
    this.setState({ player_positions_type: e.target.value });
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.getInstituteDeatilsById({ id: match.params["_id"] });
    this.props.fetchInsSTatsSummaryData({ ins_id: match.params["_id"] });
    this.props.getInstitutionsTeams(match.params["_id"]);
    getMLplatformfiles(JSON.stringify({}))
      .then((res) => {
        if (res.data.message === "Sucess") {
          this.setState({
            MLcsvData: res.data.data.MLcsvData,
            MLjsonData: res.data.data.resultFile,
            isMldataLoaded: true,
          });
        } else {
          this.setState({
            isMldataLoaded: true,
          });
        }
      })
      .catch((err) => {
        // console.log('err --------------\n',err)
        this.setState({
          isMldataLoaded: true,
        });
      });
  }
  handleFilterMetric = () => {
    // const { selectedMetric, selectedRange, selectedgs } = this.state;
    this.setState({ isFilterMetric: true, filter: true });
    setTimeout(() => this.setState({ filter: false }), 2000);
    setTimeout(() => this.setState({ isFilterMetric: false }), 2500);
  };

  render() {
    const {
      match,
      insById,
      insByIdSucc,
      getInsStatsSummary,
      InsStatsSummary,
      getInsStatsSummaryErr,
      insAnylsisData,
    } = this.props;
    const { user, loadingpositions } = this.state;
    let userDetails = JSON.parse(user);
    let BRAIN_POSITIONS = null;
    if (insAnylsisData)
      BRAIN_POSITIONS = {
        "principal-max-strain": insAnylsisData.P_MAX_S_POSITIONS,
        "principal-min-strain": insAnylsisData.P_MIN_S_POSITIONS,
        "csdm-15": insAnylsisData.P_CSDM_15,
      };
    // console.log("insAnylsisData -------", insAnylsisData);
    // console.log("insByIdSucc :::::::::::::::::::::::::::::::::::::::", insByIdSucc);
    // console.log("isMldataLoaded -------", this.state.isMldataLoaded);

    const { loadingTeam, insTeams } = this.props;
    // console.log("orgInstitution 0000000", insTeams);
    // return null;
    //random colors ....
    function getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    var insColors = null;
    if (insTeams) {

      insColors = insTeams && insTeams.teams.map((item, index) => {
        // console.log("item -----", item);
        return { [item.name]: colors[index] };
      });
    }

    return (
      <React.Fragment>
        <ul className="breadcrumb">
          {userDetails.level > 100 ? (
            <li className="breadcrumb-item">
              <Link to={`/teams/${match.params._id}`}>Institution</Link>{" "}
            </li>
          ) : null}
          <li className="breadcrumb-item active">
            {insById ? "loading..." : null}
            {insByIdSucc ? insByIdSucc[0].name : ""}
          </li>
        </ul>

        <div className="purpo-hidden">
          <h3>
            {insById ? "loading..." : null}
            {insByIdSucc ? insByIdSucc[0].name : ""}
            <p>
              Note: Team members must be activated on the Team Dashboard for
              their data to show up here.
            </p>
            <p>
              {" "}
              <Link to={`/teams/${match.params._id}`}>
                Return to Institution Dashboard{" "}
              </Link>
              to activate team members.
            </p>
          </h3>
          <div></div>
        </div>

        <div className="accel-events mt-4 mb-4">
          <div className="event">
            <h3>Display all member data with</h3>
            <div className="events-filter">
              <ul>
                <li>
                  <label>
                    <select
                      onChange={(e) =>
                        this.setState({ selectedMetric: e.target.value })
                      }
                    >
                      <option value="principal-max-strain">
                        Max Principal Strain
                      </option>
                      <option value="principal-min-strain">
                        Min Principal Strain
                      </option>
                      <option value="axonal-strain-max">
                        Axonal Strain 15
                      </option>
                      <option value="masXsr-15-max">MASxSR 15</option>
                      <option value="CSDM-5">CSDM 5</option>
                      <option value="CSDM-10">CSDM 10</option>
                      <option value="csdm-max">CSDM 15</option>
                      <option value="MPS-95">MPS 95</option>
                    </select>
                  </label>
                </li>
                <li>
                  <label>
                    <select
                      onChange={(e) =>
                        this.setState({ selectedRange: e.target.value })
                      }
                    >
                      <option value="greater">Greater or Equal to</option>
                      <option value="less">Less or Equal to</option>
                    </select>
                  </label>
                </li>
                <li>
                  <label>
                    <select
                      onChange={(e) =>
                        this.setState({ selectedgs: e.target.value })
                      }
                    >
                      <option value="0">0%</option>
                      <option value="0.05">5%</option>
                      <option value="0.10">10%</option>
                      <option value="0.20">20%</option>
                      <option value="0.30">30%</option>
                      <option value="0.40">40%</option>
                      <option value="0.50">50%</option>
                      <option value="0.60">60%</option>
                      <option value="0.70">70%</option>
                      <option value="0.80">80%</option>
                      <option value="0.90">90%</option>
                      <option value="0.100">100%</option>
                      <option value="0.110">110%</option>
                      <option value="0.120">120%</option>
                      <option value="0.130">130%</option>
                      <option value="0.140">140%</option>
                      <option value="0.140">150%</option>
                    </select>
                  </label>
                </li>
                <li>
                  <button
                    style={{
                      padding: "3px 14px",
                      background: "rgb(8 65 110)",
                      fontWeight: "800",
                      color: "#fff",
                    }}
                    onClick={this.handleFilterMetric}
                  >
                    Run Report
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="purpo">
          <ResponsiveGridLayout
            onLayoutChange={(currentLayout, allLayouts) => {
              // console.log("currentLayout, allLayouts", currentLayout, allLayouts)
            }}
            verticalCompact={false}
            className="layout"
            layouts={{
              lg: this.state.layout,
              md: layoutMD,
              sm: layoutSM,
              xs: layoutXS,
              xxs: layoutXXS,
            }}
            // cols={12}
            rowHeight={
              window.innerHeight < 930 && window.innerWidth > 600 ? 30 : 35
            }
            breakpoints={{ lg: 1200, md: 996, sm: 600, xs: 450, xxs: 0 }}
            cols={{ lg: 12, md: 8, sm: 8, xs: 2, xxs: 2 }}
          >
            <div
              className="mps-chart widget-container"
              key="c"
              style={{ zIndex: 900 }}
            >
              {!getInsStatsSummary && !InsStatsSummary ? (
                <div style={{ textAlign: "center", padding: "145px" }}>
                  <p>Run a Report to Generate Brain Simulation Results..</p>
                </div>
              ) : null}
              {getInsStatsSummary || this.state.filter ? (
                <div style={{ textAlign: "center", padding: "145px" }}>
                  <Spinner color="primary" style={{ margin: "auto" }} />
                </div>
              ) : null}
              {getInsStatsSummaryErr ? (
                <Alert color="danger">{getInsStatsSummaryErr}</Alert>
              ) : null}
              {!getInsStatsSummary && InsStatsSummary && !this.props.loadingTeam && !this.state.filter ? (
                <BrainDetails
                  selectedMetric={this.state.selectedMetric}
                  selectedRange={this.state.selectedRange}
                  selectedgs={this.state.selectedgs}
                  isFilterMetric={this.state.isFilterMetric}
                  set_filtered_event_ids = {this.set_filtered_event_ids}
                />
              ) : null}
            </div>

            <div key="a">
              <Card
                className="mps-chart widget-container"
                onMouseOver={this.handleHover}
              >
                <div className="titel-sel">
                  <h4 className="card-title">
                    95 Percentile MPS vs. Max Linear Acceleration
                  </h4>
                </div>
                <div style={{ height: "calc(100% - 60px)", width: "100%" }}>
                  {getInsStatsSummary || loadingTeam ? (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                  ) : (
                    <>
                      <MavChart
                        data={""}
                        MAX_ANGULAR_EXLARATION={ insAnylsisData ? insAnylsisData.MAX_LINEAR_EXLARATION : [] }
                        MPS_95_DATA={ insAnylsisData ? insAnylsisData.MPS_95_LIN_DATA : [] }
                        dataColors={insColors}
                        //chartType={"Ang Vell"}
                        set_selected_event_id={this.set_selected_event_id}
                        selected_event_id={this.state.selected_event_id}
                        filtered_event_ids = {this.state.filtered_event_ids}
                      />
                       <div className="legend-angular-chart" style = {{display: this.state.displayLegendVel ? "block" : "none"}}>
                        {insColors &&
                          insColors.map((ins) => {
                            return Object.entries(ins).map((key) => {
                              return (
                                <label
                                  class="chart-label label-1"
                                  style={{
                                    margin: "8px 30px",
                                  }}
                                >
                                  <span
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                      display: "inline-block",
                                      background: key[1],
                                      margin: "0px 5px -5px",
                                    }}
                                  ></span>
                                  {key[0]}
                                </label>
                              );
                            });
                          })}
                      </div>
                      <div className = "legend-wrapper">
                        <Button className="legend-button" onClick = {this.setDisplayLegendVel}>Legend 
                        {this.state.displayLegendVel ? "-" : "+"}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Card>
            </div>

            <div key="b">
              <Card className="mps-chart widget-container">
                <div className="titel-sel">
                  <h4 className="card-title">
                    95 Percentile MPS Maximum Angular Acceleration
                  </h4>
                </div>
                <div style={{ height: "calc(100% - 60px)", width: "100%" }}>
                  {getInsStatsSummary || loadingTeam ? (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                  ) : (
                    <>
                      <MaaChart
                        data={""}
                        chartType={"Ang Acc"}
                        MAX_ANGULAR_EXLARATION={
                          insAnylsisData
                            ? insAnylsisData.MAX_ANGULAR_EXLARATION
                            : []
                        }
                        MPS_95_DATA={
                          insAnylsisData ? insAnylsisData.MPS_95_DATA : []
                        }
                        dataColors={insColors}
                        set_selected_event_id={this.set_selected_event_id}
                        selected_event_id={this.state.selected_event_id}
                        filtered_event_ids = {this.state.filtered_event_ids}
                      />
                      {/* <div style={{ textAlign: "center" }}> */}
                      <div className="legend-angular-chart" style = {{display: this.state.displayLegendAcc ? "block" : "none"}}>
                        {insColors &&
                          insColors.map((ins) => {
                            return Object.entries(ins).map((key) => {
                              return (
                                <label
                                  class="chart-label label-1"
                                  style={{
                                    margin: "8px 30px",
                                  }}
                                >
                                  <span
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                      display: "inline-block",
                                      background: key[1],
                                      margin: "0px 5px -5px",
                                    }}
                                  ></span>
                                  {key[0]}
                                </label>
                              );
                            });
                          })}
                      </div>
                      <div className = "legend-wrapper">
                        <Button className="legend-button" onClick = {this.setDisplayLegendAcc}>Legend 
                        {this.state.displayLegendVel ? "-" : "+"}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Card>
            </div>

            <div key="d">
              <Card className="mps-chart widget-container">
                <div className="titel-sel">
                  <h4 className="card-title">Machine Learning</h4>
                </div>
                <div
                  className="events-chart"
                  style={{
                    height: "calc(100% - 60px)",
                    width: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  {this.state.MLjsonData ? (
                    <MllearningChart
                      MLcsvData={this.state.MLcsvData}
                      MLjsonData={this.state.MLjsonData}
                    />
                  ) : this.state.isMldataLoaded ? (
                    <div className="no-mldata-message">
                      <div>
                        Institution-specific machine learning model not yet trained.
                      </div>
                      <Button className="get-started-button btn">
                        Get Started
                      </Button>
                    </div>
                  ) : (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                  )}
                </div>
              </Card>
            </div>

            <div key="f">
              <Card className="mps-chart data-card widget-container">
                <div className="titel-sel" style={{ flexWrap: "nowrap" }}>
                  <h4
                    className="card-title"
                    style={{
                      flexShrink: 0,
                    }}
                  >
                    Brain Loading by Position
                  </h4>
                  <div
                    style={{
                      marginLeft: "15px",
                      flexGrow: 1,
                      minWidth: 30,
                      flexShrink: 1,
                    }}
                  >
                    <select
                      style={{
                        padding: "8px",
                        borderRadius: "5px",
                        padding: "5px 26px",
                        width: "100%"
                      }}
                      onChange={this.handlePostionMetric}
                      // style={{ width: "100%" }}
                    >
                      <option value="principal-max-strain">
                        Avg. Maximum Principal Strain
                      </option>
                      <option value="principal-min-strain">
                        Avg. Minimum Principal Strain
                      </option>
                      <option value="csdm-15">CSDM 15</option>
                    </select>
                  </div>
                </div>

                <div
                  className="events-chart"
                  style={{ height: "calc(100% - 60px)", width: "100%" }}
                >
                  {getInsStatsSummary || this.props.loadingTeam ? (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                  ) : (
                    <>
                      <Positionchart
                        positionData={BRAIN_POSITIONS}
                        brainPosition={this.state.player_positions_type}
                      />
                    </>
                  )}
                </div>
              </Card>
            </div>

            <div key="e">
              <Card className="mps-chart widget-container">
                <div className="titel-sel">
                  <h4
                    className="card-title"
                    style={{
                      flexShrink: 0,
                    }}
                  >
                    Top 5 Events
                  </h4>
                </div>
                <div style={{ height: "calc(100% - 60px)", width: "100%" }}>
                  {!getInsStatsSummary && InsStatsSummary && insTeams ? (
                    <TopInstitutionEvents></TopInstitutionEvents>
                  ) : (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                  )}
                </div>
              </Card>
            </div>
          </ResponsiveGridLayout>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insById: state.Institutions.insById,
    insByIdSucc: state.Institutions.insByIdSucc,
    insByIdErr: state.Institutions.insByIdErr,

    loadingTeam: state.Teams.loadingTeam,
    insTeams: state.Teams.insTeams,
    insTeamErr: state.Teams.insTeamErr,

    getInsStatsSummary: state.Institutions.getInsStatsSummary,
    InsStatsSummary: state.Institutions.InsStatsSummary,
    getInsStatsSummaryErr: state.Institutions.getInsStatsSummaryErr,

    insAnylsisData: state.Institutions.insAnylsisData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInstitutionsTeams: (org_id) => dispatch(getInstitutionsTeams(org_id)),
    getInstituteDeatilsById: (body) => dispatch(getInstituteDeatilsById(body)),
    fetchInsSTatsSummaryData: (body) =>
      dispatch(fetchInsSTatsSummaryData(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(index));


// TODO: make colors defined in props, not after every render
