import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_ERROR,
  LOGOUT_LOGGEDIN_USER,

  INVITE_USER,
  INVITE_SUCCESS,
  INVITE_ERROR,

  FORGOT_PASS,
  FORGOT_PASS_SUCCESS,
  FORGOT_PASS_ERROR,

  CHANGE_PASS,
  CHANGE_PASS_SUCCESS,
  CHANGE_PASS_ERROR,
  RESEND_CONFIRMATION,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_ERROR,

  RESET_STATE
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  loggedin: false,
  islogout: false,
  user: null,

  LogOut: false,
  inviteUser: false,
  inviteUserSuccess: null,
  inviteUserErr: null,

  forgotpass: false,
  forgotpassSuc: null,
  forgotpassErr: null,

  changepass: false,
  changepassSuc: null,
  changepassErr: null,

  resendConfimation: false,
  resendConfimationSucc: null,
  resendConfimationErr: null
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case RESEND_CONFIRMATION:
      state = {
        ...state,
        resendConfimation: true,
        resendConfimationSucc: null,
        resendConfimationErr: null
      }
      break
    case RESEND_CONFIRMATION_SUCCESS:
      state = {
        ...state,
        resendConfimation: false,
        resendConfimationSucc: action.payload,
        resendConfimationErr: null,
        error: null
      }
      break
    case RESEND_CONFIRMATION_ERROR:
      state = {
        ...state,
        resendConfimation: false,
        resendConfimationSucc: null,
        resendConfimationErr: action.payload,
        error: null
      }
      break
    //..
    case LOGIN_USER:
      state = {
        ...state,
        error: '',
        islogout: false,
        loading: true,
        loggedin: false,
        user: action.payload
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        loggedin: true,
        LogOut: false
      }
      break
    case LOGIN_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload ? action.payload.message ? action.payload.message : action.payload : action.payload,
        loggedin: false

      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        islogout: action.payload,
        loggedin: false,
        loading: false,
        user: null
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case LOGOUT_LOGGEDIN_USER:
      state = { ...state, LogOut: true }
      break

    // invite user....

    case INVITE_USER:
      state = {
        ...state,
        inviteUser: true,
        inviteUserSuccess: null,
        inviteUserErr: null
      }
      break
    case INVITE_SUCCESS:
      state = {
        ...state,
        error: '',
        inviteUser: false,
        inviteUserSuccess: action.payload,
        inviteUserErr: null
      }
      break
    case INVITE_ERROR:
      // console.log('action.payload ------------', action.payload)
      state = {
        ...state,
        inviteUserErr: action.payload.message ? action.payload.message : action.payload,
        inviteUser: false,
        inviteUserSuccess: null,

      }
    // forgot pass....
    case FORGOT_PASS:
      state = {
        ...state,
        forgotpass: true,
        forgotpassSuc: null,
        forgotpassErr: null
      }
      break
    case FORGOT_PASS_SUCCESS:
      state = {
        ...state,
        forgotpass: false,
        forgotpassSuc: action.payload,
        forgotpassErr: null
      }
      break
    case FORGOT_PASS_ERROR:
      state = {
        ...state,
        forgotpass: false,
        forgotpassSuc: null,
        forgotpassErr: action.payload

      }
    // Change pass....

    case CHANGE_PASS:
      state = {
        ...state,
        changepass: true,
        changepassSuc: null,
        changepassErr: null,
      }
      break
    case CHANGE_PASS_SUCCESS:
      state = {
        ...state,
        changepass: false,
        changepassSuc: action.payload,
        changepassErr: null,
      }
      break
    case CHANGE_PASS_ERROR:
      console.log('error ', action.payload)
      state = {
        ...state,
        changepass: false,
        changepassSuc: null,
        changepassErr: action.payload
      }
      break
    // reset state ..
    case RESET_STATE:
      state = {
        ...state,
        error: "",
        loading: false,
        loggedin: false,
        islogout: false,
        LogOut: false,
        inviteUser: false,
        inviteUserSuccess: null,
        inviteUserErr: null,
        forgotpass: false,
        forgotpassSuc: null,
        forgotpassErr: null,
        resendConfimation: false,
        resendConfimationSucc: null,
        resendConfimationErr: null
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
