import React, { Component } from "react"
import { Link } from "react-router-dom"
import { addNewSubscriber } from "../../../store/actions"
import {
  Spinner,
} from "reactstrap"

class Privacy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let email = document.getElementById('subscribeEmail').value;
    if (!email) alert('Please enter your email.');
    // console.log('email', email)
    this.setState({ loading: true })
    addNewSubscriber({ email })
      .then(res => {
        // console.log('res -----------', res)
        this.setState({ success: 'You have subscribe as our newsletter sucussfully', loading: false, error: null })
        document.getElementById('subscribeEmail').value = '';
      }).catch(err => {
        console.log('err -----------', err)
        this.setState({ success: null, loading: false, error: JSON.stringify(err.message) })
      })
  }

  render() {
    const { loading, error, success } = this.state;
    return (
      <>
        <h3>Subscribe To Our Newsletter?</h3>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input type="email" className="form-control" id="subscribeEmail" placeholder="Enter Email ID" required />
            <button type="submit" className="btn btn-primary">Get Updates</button>
          </div>
        </form>
        {
          loading &&
          <Spinner color="primary" />
        }
        {
          success &&
          <>
            <h3 style={{ textAlign: 'center', fontWeight: '600' }}>Thank you!</h3><br />
            <p style={{ fontWeight: '600', textAlign: 'center', marginBottom: '10px' }}>You are now our Subscriber.</p>
          </>
        }
        {
          error &&
          <>
            <h3 style={{ textAlign: 'center', fontWeight: '600' }}>Oops!</h3><br />
            <p style={{ fontWeight: '600', textAlign: 'center', marginBottom: '10px' }}>Failed to add as subscriber! Please try later.</p>
          </>
        }
        <p><Link to="/signup">{""}Sign up{" "}</Link>{" "} to receive updates about our progress.</p>
      </>
    )
  }
}


export default Privacy
