import React, { Component } from "react"
import {
  Container,
} from "reactstrap"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./footer.scss"
import SubscirbeComponent from "../Subscribe/index"
import PropTypes from 'prop-types'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { newsletter } = this.props
    return (
      <React.Fragment>
        {/* {newsletter ? (
          <div className="newsletter">
            <Container>
              <SubscirbeComponent />
            </Container>
          </div>
        ) : null} */}
        <div className="site-footer">
          {/* <div className="top-footer color-light">
            <Container>
              <ul>
                <li className="light-clr-txt"><img src="/privacy-tip.png" /> <a href="/privacy" target="_blank" className="anchorpadding">{" "}Privacy Policy{" "}</a><br /></li>
                <li className="light-clr-txt"><img src="/privacy-tip.png" /> <a href="/terms-of-service" target="_blank" className="anchorpadding">{" "}Terms of Service{" "}</a><br /></li>
                <li className="light-clr-txt"><img src="/privacy-tip.png" /> <a href="/data-deletion" target="_blank" className="anchorpadding">{" "}Data Deletion{" "}</a><br /></li>
                <li className="light-clr-txt"><img src="/bytesize_mail.png" /><a href="mailto:support@evoshield-smg.com?cc=reuben.kraft@gmail.com">{" "}support@evoshield-smg.com{" "}</a>{" "}</li>
                <li className="light-clr-txt"><p><img src="/copyright.png" /> {new Date().getFullYear()} EvoShield Technologies </p></li>
              </ul>
            </Container>
          </div> */}
          <div className="top-footer">
            <Container>
              <div className="footer-logo">
                <Link to="/home">{" "}
                  <LazyLoadImage
                    alt={"logo"}
                    src={"/BrainSim.png"}
                  />
                  {" "}
                </Link>{" "}
              </div>
            </Container>
          </div>
          {/* <div className="bottom-footer">
            <p>*Disclaimer: Brain simulations are not approved by FDA as a medical device that can be used for diagnostics. It should not be used to diagnose brain injuries. If you would like to be part of our FDA study please <Link to="">{" "}click here.{" "}</Link>{" "}</p>
          </div> */}
        </div>
      </React.Fragment>
    )
  }
}

Footer.propTypes = {
  newsletter: PropTypes.bool
}

Footer.defaultProps = {
  newsletter: true
}

export default Footer
