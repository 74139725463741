import React, { useState, useEffect} from "react"
import { Row, Col } from "reactstrap";
import { Card } from "react-bootstrap";
import * as THREE from "three"
//import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

var renderer, scene, camera, subcamera_isometric, subcamera_front, container, model;

const ColorCustomizer = ({
    onSelectColor = (v) => console.log('color selected : ', v),
    baseC,
    baseCname
}) => {

    const [size, setSize] = useState([0, 0]);

    // const [loaded, setLoaded] = useState(false);
    const [baseColor, setBaseColor] = useState("#6fb7dd")
    const [baseColorName, setBaseColorName] = useState("light blue")
    const [displayBaseColorDropdown, setDisplayBaseColorDropdown] = useState(true)



    useEffect(() => {
        init();
        window.addEventListener("resize", onWindowResize, false);
        return () => {
            window.removeEventListener("resize", onWindowResize)
        }
    }, [])

    useEffect(() => {

        console.log("useEffect", baseC, "baseCname", baseCname)
        if (baseC) {
            setBaseColor(baseC)
            setModelColor(baseC);
        }
        if (baseCname) {
            setBaseColorName(baseCname)
        }

        animate()
    }, [baseC, baseCname])

    // useEffect(() => {
    //     animate(baseColor)
    // }, [baseColor])
    
    const init = () => {
        container = document.getElementById("three-container");
        scene = new THREE.Scene();
        

        const WIDTH = ( container.offsetWidth ) * window.devicePixelRatio;
        const HEIGHT = ( container.offsetHeight/ 2 ) * window.devicePixelRatio;



        subcamera_isometric= new THREE.PerspectiveCamera( 60, container.offsetWidth / (container.offsetHeight/2), 0.1, 1000 );
        subcamera_isometric.viewport = new THREE.Vector4( 0, 0, Math.ceil( WIDTH ), Math.ceil( HEIGHT ) );


        if(container.offsetWidth < 358)
        {
            subcamera_isometric.position.set( 43, 43, 43 );
        }
        else {
            subcamera_isometric.position.set( 33, 33, 33 );
        }

        subcamera_isometric.rotation.order = 'YXZ';
        subcamera_isometric.rotation.y = - Math.PI / 4;
        subcamera_isometric.rotation.x = Math.atan( - 1 / Math.sqrt( 2 ) );

        subcamera_isometric.lookAt(0,0,0);
        subcamera_isometric.updateMatrixWorld();


        subcamera_front = new THREE.PerspectiveCamera( 60, container.offsetWidth / (container.offsetHeight/2), 0.1, 1000 );
        subcamera_front.viewport = new THREE.Vector4( 0, Math.floor( HEIGHT ), Math.ceil( WIDTH ), Math.ceil( HEIGHT ) );

        if(container.offsetWidth < 358)
        {
            subcamera_front.position.z = 67;
        }
        else {
            subcamera_front.position.z = 57;
        }
        subcamera_front.position.y = 5;
        subcamera_front.position.x = 0;
        //subcamera_front.lookAt(0,0,0)
        subcamera_front.updateMatrixWorld();


        var cameras = [subcamera_front, subcamera_isometric];
        camera = new THREE.ArrayCamera(cameras);
        camera.position.z = 3;


        
        
        renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
        
        renderer.setSize( container.offsetWidth, container.offsetHeight );
        renderer.setPixelRatio( window.devicePixelRatio );

        container.appendChild( renderer.domElement );

        

        const light = new THREE.AmbientLight( 0x404040 ); // soft white light
        //scene.add( light );

        // Main Light + Shadows
        const lightMain = new THREE.DirectionalLight(0xFFFFFF, 0.7);
        lightMain.position.set(0, 0, -100);
        lightMain.castShadow = true;
        lightMain.shadow.mapSize = new THREE.Vector2(4096, 4096);
        scene.add(lightMain);

        // const helper1 = new THREE.DirectionalLightHelper( lightMain, 5 );
        // scene.add( helper1 );

        // Secondary Light - no shadows
        const lightSec = new THREE.DirectionalLight(0xFFFFFF, 0.7);
        lightSec.position.set(0, 0, 100);
        lightSec.castShadow = true;
        lightSec.shadow.mapSize = new THREE.Vector2(4096, 4096);
        scene.add(lightSec);

        // const helper2 = new THREE.DirectionalLightHelper( lightSec, 5 );
        // scene.add( helper2 );

        const lightThird = new THREE.DirectionalLight(0xFFFFFF, 0.7);
        lightThird.position.set(0, 200, 0);
        lightThird.castShadow = true;
        lightThird.shadow.mapSize = new THREE.Vector2(4096, 4096);
        scene.add(lightThird);

        // const helper3 = new THREE.DirectionalLightHelper( lightThird, 5 );
        // scene.add( helper3);



        renderer.setAnimationLoop( animate );
        //window.addEventListener("resize", this.onWindowResize, false);



        loadModel();

    }

    const loadModel = () => {
        const stlLoader = new STLLoader();
        stlLoader.load(
            "/assets/models/mouthguard.stl",

            (stl) => {

                console.log("stl", stl)

                const material = new THREE.MeshPhongMaterial();
                //material.shininess = 0.8;
                material.roughness = 1;
                const geometry = stl;



                model = new THREE.Mesh(geometry, material);
                model.receiveShadow = true;
                scene.add(model);
                  
                model.rotateX(-Math.PI/2)
                model.position.set(0, 10, 19);


                if(baseC)
                {
                    setModelColor(baseC);
                }

                // setLoaded(true)

            }, undefined, undefined);
    }

    const onWindowResize = () => {
        var container = document.getElementById("three-container")

        setSize([container.offsetWidth, container.offsetHeight]);
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();


        const WIDTH = ( container.offsetWidth ) * window.devicePixelRatio;
        const HEIGHT = ( container.offsetHeight/ 2 ) * window.devicePixelRatio;

        subcamera_front.viewport.set(0, Math.floor( HEIGHT ), Math.ceil( WIDTH ), Math.ceil( HEIGHT ));

        subcamera_isometric.viewport.set(0, 0, Math.ceil( WIDTH ), Math.ceil( HEIGHT));

        subcamera_front.aspect = container.offsetWidth / (container.offsetHeight/2);
        subcamera_isometric.aspect = container.offsetWidth / (container.offsetHeight/2);

        subcamera_front.updateProjectionMatrix();
        subcamera_front.updateMatrixWorld();
        subcamera_isometric.updateProjectionMatrix();
        subcamera_isometric.updateMatrixWorld();

        if(container.offsetWidth < 358)
        {
            subcamera_isometric.position.set( 43, 43, 43 );
            subcamera_front.position.z = 67;
        }
        else {
            subcamera_isometric.position.set( 33, 33, 33 );
            subcamera_front.position.z = 57;
        }

        renderer.setSize(container.offsetWidth, container.offsetHeight);
    }


    const animate = () => {
        renderer.render(scene, camera);
    }

    const setModelTexture = (path) => {
        if (model) {
            const texture = new THREE.TextureLoader().load(path);
            model.material.map = texture;
            model.material.needsUpdate = true;
        }
    }

    const generateColors = (dropdown_type) => {
        var output_array = [];
        const colors = [
            { color_name: "black", value: "#000000" },
            { color_name: "blue", value: "#001c96" },
            { color_name: "purple", value: "#582c83" },
            { color_name: "red", value: "#ee0000" },
            { color_name: "pink", value: "#e72675" },
            { color_name: "orange", value: "#fa4617" },
            { color_name: "dark blue", value: "#001c43" },
            { color_name: "dark green", value: "#164734" },
            { color_name: "light blue", value: "#6fb7dd" },
            { color_name: "sun", value: "#ffd200" },
        ];

        return colors.map((color) => {
            return <div className="color-value" key={color.color_name} style={{ backgroundColor: color.value }} onClick={() => {
                if (dropdown_type == "BASE") {
                    setBaseColorName(color.color_name)
                    setBaseColor(color.value)
                    onSelectColor({ baseColor: color.value, baseColorName: color.color_name });
                }
            }
            }></div>
        })
    }

    // const generateTexture = () => {
    //     const textures = [
    //         { texture_name: "charcoal", value: "/assets/textures/texture_for_mesh1.jpg" },
    //         { texture_name: "charcoal", value: "/assets/textures//texture_for_mesh2.jpg" },
    //         { texture_name: "charcoal", value: "/assets/textures//texture_for_mesh3.jpg" },
    //     ];

    //     return textures.map((texture) => {
    //         return <div className="color-value" key={textures.value} style={{ backgroundImage: `url(${texture.value})` }} onClick={() => {
    //             setModelTexture(texture.value)
    //         }
    //         }></div>
    //     })


    // }

    const setModelColor = (c) => {
        console.log("setModelColor!", c)
        if (model) {
            model.material.color.setStyle(c);
            model.material.needsUpdate = true;
        }
    }

    const renderDropDowns = () => {
        const dropdown_types = ["BASE"];

        return dropdown_types.map((dropdown_type) => {
            var color_name, color, displayDropdown;
            if (dropdown_type == "BASE") {
                color_name = baseColorName;
                color = baseColor;
                displayDropdown = displayBaseColorDropdown;
            }



            var displayDropDownClick = () => {
                if (dropdown_type == "BASE") {
                    setDisplayBaseColorDropdown(!displayDropdown)
                }
            }


            return (
                <React.Fragment>

                    <div className="dropdown-picker">
                        <div className="dropdown-name" style={displayDropdown ? { color: "#ba8707" } : {}}>{dropdown_type}</div>
                        <div className="color-part">
                            <div className="color-name">{color_name}</div>
                            <div className="selected-color-box" style={{ backgroundColor: color }}></div>
                            <button className="display-dropdown" onClick={(e) => {
                                //this.setState({displayBaseColorDropdown: !displayDropdown})
                                displayDropDownClick();
                            }
                            }>
                                <svg style={displayDropdown ? { transform: "rotate(180deg)", color: "#ba8707" } : {}} width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0" fill="none" width="24" height="24" />
                                    <g>
                                        <path d="M7 10l5 5 5-5" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="dropdown-color-list" style={displayDropdown ? {} : { display: "none" }}>
                        {
                            // dropdown_type == "BASE" ? generateColors(dropdown_type).concat(generateTexture()) : generateColors(dropdown_type)
                            generateColors(dropdown_type)
                        }

                    </div>
                </React.Fragment>
            )
        })
    }

    return (
        <Row className="step_one">
            <Col id="three-container"></Col>
            <Col>
                <Card>
                    <Card.Header>Color</Card.Header>
                    <Card.Body>
                        {renderDropDowns()}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ColorCustomizer