import { Button, Row, Col } from "reactstrap";
import './index.scss';

const StepFormItems = (props) => {
    const {
        onNextClick = () => console.log('next clicked'),
        onPreviousClick = () => console.log('previous clicked'),
        nextActive = true,
        previousActive = true,
        isFinish = false,
        finishText = 'Finish' 
    } = props
    return (
        <div className="step_form_items">
            {props.children}
            <Row className="form-steps-actions my-5">
                <Col className="text-center text-sm-left">
                    <Button onClick={() => onPreviousClick()} disabled={!previousActive}>Previous</Button>
                </Col>
                <Col className="text-center text-sm-right">
                    <Button onClick={() => onNextClick()} disabled={!nextActive}>{isFinish ? finishText : 'Next'}</Button>
                </Col>
            </Row>
        </div>
    )
}

export default StepFormItems