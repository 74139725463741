import { Col } from "reactstrap";
import { Card } from "react-bootstrap";
import './index.scss';

/**
 * DetailsCards Component
 * 
 * A functional component that renders a card with a title and description.
 * The card can be customized to be hidden, selected, or have a custom display title.
 * It supports a click event that passes a value to a handler function.
 * 
 * Props:
 * - title (string): The main title of the card.
 * - description (string): The content or description displayed inside the card.
 * - card_value (any, optional): A custom value representing the card, used in click event.
 * - hidden (boolean, optional): If true, the card is not rendered.
 * - displayTitle (string, optional): A custom title for display instead of the main title.
 * - isSelected (boolean, optional): If true, applies a 'selected' style to the card.
 * - onCardClick (function, optional): A handler function for card click events.
 */

const DetailsCards = ({
    title,
    description,
    card_value = null,
    hidden = false,
    displayTitle = null,
    isSelected = false,
    onCardClick = () => true
}) => {
    if (hidden) return null
    const card_title = displayTitle ? displayTitle : title
    const selection_value = card_value ? card_value : card_title
    const classValue = isSelected  ? "d-flex mb-4 mb-md-1 selected-card" : "d-flex mb-4 mb-md-1"
    return (
        <Col className={classValue} xs="12" sm="4" md="4">
            <Card className="details-cards" onClick={() => onCardClick(selection_value)}>
                <Card.Header>{card_title}</Card.Header>
                <Card.Body>{description}</Card.Body>
            </Card>
        </Col>
    )
}

export default DetailsCards