import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  CHANGE_ITEMS_VIEW,
  ACTIVE_SIDEBAR,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_READ_START,
  NOTIFICATIONS_READ_SUCCESS,
  SIMULATIONS_LOADING,
  SIMULATIONS_SUCCESS,
  SIMULATIONS_ERROR
} from "./actionTypes"
import axios from "axios";
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER
})
export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
})

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
})

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})

export const changeSidebarType = (sidebarType) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType },
  }
}

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
})

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
})

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
})

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
})


export const changeItemsView = (view) => ({
  type: CHANGE_ITEMS_VIEW,
  payload: view,
})

export const activeSidebarMenu = () => ({
  type: ACTIVE_SIDEBAR
})
const userReadNotificationsSucc = () => {
  return {
    type: NOTIFICATIONS_READ_SUCCESS,
  }
}
export const userReadNotificationsStart = () => ({
  type: NOTIFICATIONS_READ_START,
})
export const setSingleNotifications = (body) => {
  return (dispatch) => {
    dispatch(userReadNotificationsStart());
    axios.patch(`/auth/setSingleNotifications`, body)
      .then(function (response) {
        
        if(response.data.message == "success"){
          dispatch(userReadNotificationsSucc(response.data.data));
                   
          }
      })
      .catch(function (error) {

        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          //dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          //dispatch(userNotificationsError(error.response.data.message));
        }
        dispatch(userReadNotificationsStart());

      });
  }
}
/** adding the player functionality on team dashboard for organisation role */
const userNotificationsFetching = user => {
  return {
    type: NOTIFICATIONS_LOADING
  }
}

const userNotificationsSuccess = (msg) => {
  return {
    type: NOTIFICATIONS_SUCCESS,
    payload: msg,
  }
}

const userNotificationsError = error => {
  return {
    type: NOTIFICATIONS_ERROR,
    payload: error,
  }
}

export const getAllnotification = (body) => {
  return (dispatch) => {
    dispatch(userNotificationsFetching());
    axios.patch(`/auth/getAllNotifications`, body)
      .then(function (response) {
        
        if(response.data.message == "success"){
          dispatch(userNotificationsSuccess(response.data.data));
          dispatch(userReadNotificationsStart());

                   
          }
      })
      .catch(function (error) {

        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          dispatch(userNotificationsError(error.response.data.message));
        }
        dispatch(userReadNotificationsStart());

      });
  }
}
export const readAllNotifications = (body) => {
  return (dispatch) => {
    axios.patch(`/auth/readAllNotifications`, body)
      .then(function (response) {
        
        if(response.data.message == "success"){
          dispatch(userReadNotificationsSucc(response.data.data));

                   
          }
      })
      .catch(function (error) {

        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          dispatch(userNotificationsError(error.response.data.message));
        }
        dispatch(userReadNotificationsStart());

      });
  }
}

//get pending siulations left
/** adding the player functionality on team dashboard for organisation role */
const userSimulationsloading = user => {
  return {
    type: SIMULATIONS_LOADING
  }
}

const userSimulationsSuccess = (msg) => {
  return {
    type: SIMULATIONS_SUCCESS,
    payload: msg,
  }
}

const userSimulationsError = error => {
  return {
    type: SIMULATIONS_ERROR,
    payload: error,
  }
}

export const getOrgUserSimulationsLeft = (body) => {
  return (dispatch) => {
    dispatch(userSimulationsloading());
    axios.patch(`/auth/getorgSimulations`, body)
      .then(function (response) {
        
        if(response.data.message == "success"){
          if(response.data.data && response.data.data.quote){
            
          dispatch(userSimulationsSuccess(response.data.data.quote));
          }

                   
          }
      })
      .catch(function (error) {

        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          dispatch(userSimulationsError(error.response.data.message));
        }

      });
  }
}